import Stack from "@vapor/react-material/Stack";
import Table from "@vapor/react-material/Table";
import TableBody from "@vapor/react-material/TableBody";
import TableCell from "@vapor/react-material/TableCell";
import TableContainer from "@vapor/react-material/TableContainer";
import TableHead from "@vapor/react-material/TableHead";
import TableRow from "@vapor/react-material/TableRow";
import Paper from "@vapor/react-material/Paper";
import TextField from "@vapor/react-material/TextField";
import Autocomplete from "@vapor/react-material/Autocomplete";
import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";
import Checkbox from "@vapor/react-material/Checkbox";
import { Button, FormControlLabel, Radio, RadioGroup } from "@vapor/react-material";
import { useState,useEffect } from "react";

import {
    useMutation,
    useQuery,    
    useSnackbar,
    useTranslation
} from "@onefront/react-sdk";
import { mutation_add_autovalutazioneAvv, mutation_update_autovalutazioneAvv } from "../../businessLogic/mutation";
import { Print as PrintIcon, Save as SaveIcon } from "@mui/icons-material";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import React from "react";
import { bsaAutovalutazione } from "../../businessLogic/constants";
import { userPermissionModel,autovalutazioneAvvocatoModel } from "../../businessLogic/models";
import sharedDatas from "../../businessLogic/sharedDatas";
import { query_autoValutazioneAvvocato_byId } from "../../businessLogic/query";
import { v4 as uuidv4 } from "uuid";
import { getAnalisiNMesiById } from "../../businessLogic/bl"

export const AutovalutazioneAvvocatoForm = ({selAutovalutazioneId,providedById,providedDateFromParent,profList}) => {
    
    const addAutoValutazioneAvv = useMutation(mutation_add_autovalutazioneAvv,{
        graphqlEndpoint: "aml:hasura:api://v1/graphql",
    });
    const updateAutoValutazioneAvv = useMutation(mutation_update_autovalutazioneAvv,{
        graphqlEndpoint: "aml:hasura:api://v1/graphql",
    });
    const getAutovalutazioneAvv = useQuery (
        query_autoValutazioneAvvocato_byId,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    )    
    const aziendaId = sharedDatas.getPropertyByName("aziendaId");
    const tenantId = sharedDatas.getPropertyByName("tenantId");
    const loggedUserId = sharedDatas.getPropertyByName("userId");
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const months = ["6 mesi","12 mesi","24 mesi","36 mesi"]
    const problems = ["criminalità organizzata di stampo mafioso","gruppi terroristici","soggetti coinvolte in operazioni di riciclaggio nazionale/internazionale"]
    const idents = ["persone fisiche","società di capitali","società di persone","enti pubblici"]
    const relativesTo = ["consulenza in materia lavoristica","di contrattualistica commerciale","recupero crediti","diritto di famiglia"]
    const whos = ["lavoratori","persone fisiche","società che operano nel settore"]
    const provenience = ["da aree geografiche potenzialmente sensibili","che operavano in settori diversi da quelli in cui lo studio è abitualmente attivo","che presentavano un profilo di rischio sensibile"]
    const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api    
    const [userPermissions, setUserPermissions] = useState(
        new userPermissionModel(
        0,
        aziendaId,
        bsaAutovalutazione,
        false,
        false,
        false,
        false
        )
    );

    //Autovalutazione
    const [selAutovalutazione, setSelAutovalutazione] = useState(
    new autovalutazioneAvvocatoModel(
        selAutovalutazioneId,
        tenantId,
        aziendaId,
        6,
        "",
        true,
        0,
        0,
        true,
        false,
        false,
        false,
        false,
        false,
        0,
        false,
        0,
        "",
        true,
        true,
        0,
        "",
        0,
        0,
        "",
        false,
        false,
        false,
        false,
        false,
        "",
        providedDateFromParent,
        loggedUserId,
        "",
        providedById
    )
    );
    

    useEffect(() => {
        loadDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDataLoaded]);
    //#region Handler

    const handleRadioIt = (event,value) => {
        if(event.target.name === "radioIsAreaItalia"){
            selAutovalutazione.isAreaItalia = value === "italy"
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                isAreaItalia : selAutovalutazione.isAreaItalia
            }))       
        }
    }

    const handleIs = (event,value) => {
        if(event.target.name === "radioIsMotivoIdent"){            
            selAutovalutazione.isMotivoIdent = value === "is"
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                isMotivoIdent : selAutovalutazione.isMotivoIdent
            })) 
        }
    }  
    
    const handlePartOf = (event,value) => {
        if(event.target.name === "radioIsClientiAreaSensibile"){            
            selAutovalutazione.isClientiAreaSensibile = value === "isPartOf"
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                isClientiAreaSensibile : selAutovalutazione.isClientiAreaSensibile
            })) 
        }
    } 

    const handleVer = (event,value) => {
        if(event.target.name === "radioIsClientiProvenienzaVaria"){            
            selAutovalutazione.isClientiProvenienzaVaria = value === "limit"
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                isClientiProvenienzaVaria : selAutovalutazione.isClientiProvenienzaVaria
            })) 
        }
    }

    const handleRes = (event,value) => {
        if(event.target.name === "radioRisultatiId"){            
            selAutovalutazione.risultatiId = value === "ass" ? 0 : 1
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                risultatiId : selAutovalutazione.risultatiId
            })) 
        }
    }

    const handleChoice = (event,value) => {
        if(event.target.name === "radioRischioProvvedimentoId"){
            selAutovalutazione.rischioProvvedimentoId = value === "geog" ? 0 : value === "newCli" ? 1 : 2 
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                rischioProvvedimentoId : selAutovalutazione.rischioProvvedimentoId
            })) 
        }
    }

    const handleCheckBox = (event,value) => {
        const {id} = event.target
        if(id === "chkProcedure1"){
            selAutovalutazione.chkProcedure1 = value
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                chkProcedure1 : selAutovalutazione.chkProcedure1
            })) 
        } else if (id === "chkProcedure2"){
            selAutovalutazione.chkProcedure2 = value
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                chkProcedure2 : selAutovalutazione.chkProcedure2
            })) 
        } else if (id === "chkProcedure3"){
            selAutovalutazione.chkProcedure3 = value
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                chkProcedure3 : selAutovalutazione.chkProcedure3
            }))    
        } else if (id === "chkProcedure4"){
            selAutovalutazione.chkProcedure4 = value
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                chkProcedure4 : selAutovalutazione.chkProcedure4
            })) 
        } else if (id === "chkProcedure5"){
            selAutovalutazione.chkProcedure5 = value
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                chkProcedure5 : selAutovalutazione.chkProcedure5
            })) 
        } else if (id === "chkMotivoIdent1"){
            selAutovalutazione.chkMotivoIdent1 = value
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                chkMotivoIdent1 : selAutovalutazione.chkMotivoIdent1
            }))
        } else if (id === "chkMotivoIdent2"){
            selAutovalutazione.chkMotivoIdent2 = value
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                chkMotivoIdent2 : selAutovalutazione.chkMotivoIdent2
            }))
        } else if (id === "chkMotivoIdent3"){
            selAutovalutazione.chkMotivoIdent3 = value
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                chkMotivoIdent3 : selAutovalutazione.chkMotivoIdent3
            }))
        } else if (id === "chkMotivoIdent4"){
            selAutovalutazione.chkMotivoIdent4 = value
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                chkMotivoIdent4 : selAutovalutazione.chkMotivoIdent4
            }))
        }  else if (id === "contenzioso"){
            selAutovalutazione.isAttivitaContenziosa = value
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                isAttivitaContenziosa : selAutovalutazione.isAttivitaContenziosa
            }))
        } 
    }

    const handleChangeText = (event,value) => {
        const {id} = event.target
        if(id === "txtAreaGeografica"){
            selAutovalutazione.areaGeografica = event.target.value
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                areaGeografica : selAutovalutazione.areaGeografica
            })) 
        } else if (id === "txtClientiProvenienzaNote"){
            selAutovalutazione.clientiProvenienzaNote = event.target.value
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                clientiProvenienzaNote : selAutovalutazione.clientiProvenienzaNote
            })) 
        } else if (id === "txtClientiSettore"){
            selAutovalutazione.clientiSettore = event.target.value
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                clientiSettore : selAutovalutazione.clientiSettore
            })) 
        } else if (id === "txtRischioProvvedimentoNote"){
            selAutovalutazione.rischioProvvedimentoNote = event.target.value
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                rischioProvvedimentoNote : selAutovalutazione.rischioProvvedimentoNote
            })) 
        } 
    }

    const handleChangeDropDown = (event,value) => {
        const {id} = event.target        
        if(id.includes("ddlanalisiNMesi-option")){
            selAutovalutazione.analisiNMesi = getAnalisiNMesiById(Number(id.split("-")[2]))
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                analisiNMesi : selAutovalutazione.analisiNMesi
            })) 
        } else if(id.includes("ddlareaRischioId-option")){
            selAutovalutazione.areaRischioId = Number(id.split("-")[2])
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                areaRischioId : selAutovalutazione.areaRischioId
            })) 
        } else if (id.includes("ddlAreaClientiTipoId-option")){
            selAutovalutazione.areaClientiTipoId = Number(id.split("-")[2])
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                areaClientiTipoId : selAutovalutazione.areaClientiTipoId
            })) 
        } else if (id.includes("ddlAttivitaTipicaId-option")){
            selAutovalutazione.attivitaTipicaId = Number(id.split("-")[2])
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                attivitaTipicaId : selAutovalutazione.attivitaTipicaId
            })) 
        } else if (id.includes("ddlClientiTipoId-option")){
            selAutovalutazione.clientiTipoId = Number(id.split("-")[2])
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                clientiTipoId : selAutovalutazione.clientiTipoId
            })) 
        } else if (id.includes("ddlClientiProvenienzaId-option")){
            selAutovalutazione.clientiProvenienzaId = Number(id.split("-")[2])
            setSelAutovalutazione((prevState) => ({
                ...prevState,
                clientiProvenienzaId : selAutovalutazione.clientiProvenienzaId
            })) 
        } else if (id.indexOf("ddlUtenti-option") !== -1){
            selAutovalutazione.responsabileId = value.userId;     
            setSelAutovalutazione((prevState) => ({ ...prevState, responsabileId: value.userId }));
        }
    }

    const handlePrintReportClick = () => {}

    const handleSaveAutovalutazione = async () => {
        try {
            let respObj = null;
            if(selAutovalutazione.id > 0 ){
                const updateResp = await updateAutoValutazioneAvv.fetch({
                    id : selAutovalutazione.id,
                    tenantId : selAutovalutazione.tenantId,
                    aziendaId : selAutovalutazione.aziendaId,
                    analisiNMesi : selAutovalutazione.analisiNMesi,
                    areaGeografica : selAutovalutazione.areaGeografica,
                    isAreaItalia : selAutovalutazione.isAreaItalia,
                    areaRischioId : selAutovalutazione.areaRischioId,
                    areaClientiTipoId : selAutovalutazione.areaClientiTipoId,
                    isMotivoIdent : selAutovalutazione.isMotivoIdent,
                    chkMotivoIdent1 : selAutovalutazione.chkMotivoIdent1,
                    chkMotivoIdent2 : selAutovalutazione.chkMotivoIdent2,
                    chkMotivoIdent3 : selAutovalutazione.chkMotivoIdent3,
                    chkMotivoIdent4 : selAutovalutazione.chkMotivoIdent4,
                    chkMotivoIdent5 : selAutovalutazione.chkMotivoIdent5,
                    attivitaTipicaId : selAutovalutazione.attivitaTipicaId,
                    isAttivitaContenziosa : selAutovalutazione.isAttivitaContenziosa,
                    clientiTipoId : selAutovalutazione.clientiTipoId,
                    clientiSettore : selAutovalutazione.clientiSettore,
                    isClientiAreaSensibile : selAutovalutazione.isClientiAreaSensibile,
                    isClientiProvenienzaVaria : selAutovalutazione.isClientiProvenienzaVaria,
                    clientiProvenienzaId : selAutovalutazione.clientiProvenienzaId,
                    clientiProvenienzaNote : selAutovalutazione.clientiProvenienzaNote,
                    risultatiId : selAutovalutazione.risultatiId,
                    rischioProvvedimentoId : selAutovalutazione.rischioProvvedimentoId,
                    rischioProvvedimentoNote : selAutovalutazione.rischioProvvedimentoNote,
                    chkProcedure1 : selAutovalutazione.chkProcedure1,
                    chkProcedure2 : selAutovalutazione.chkProcedure2,
                    chkProcedure3 : selAutovalutazione.chkProcedure3,
                    chkProcedure4 : selAutovalutazione.chkProcedure4,
                    chkProcedure5 : selAutovalutazione.chkProcedure5,
                    responsabileId : selAutovalutazione.responsabileId,
                    date : providedDateFromParent ? providedDateFromParent : selAutovalutazione.date,                    
                    provdedBy : providedById ? providedById : selAutovalutazione.provdedBy,
                    createdBy : selAutovalutazione.createdBy,
                    modifiedBy : loggedUserId
                })
                respObj = updateResp.data.data.update_autovalutazione_avvocati.returning[0]
            } else {
                const saveResp = await addAutoValutazioneAvv.fetch({
                    tenantId : selAutovalutazione.tenantId,
                    aziendaId : selAutovalutazione.aziendaId,
                    analisiNMesi : selAutovalutazione.analisiNMesi,
                    areaGeografica : selAutovalutazione.areaGeografica,
                    isAreaItalia : selAutovalutazione.isAreaItalia,
                    areaRischioId : selAutovalutazione.areaRischioId,
                    areaClientiTipoId : selAutovalutazione.areaClientiTipoId,
                    isMotivoIdent : selAutovalutazione.isMotivoIdent,
                    chkMotivoIdent1 : selAutovalutazione.chkMotivoIdent1,
                    chkMotivoIdent2 : selAutovalutazione.chkMotivoIdent2,
                    chkMotivoIdent3 : selAutovalutazione.chkMotivoIdent3,
                    chkMotivoIdent4 : selAutovalutazione.chkMotivoIdent4,
                    chkMotivoIdent5 : selAutovalutazione.chkMotivoIdent5,
                    attivitaTipicaId : selAutovalutazione.attivitaTipicaId,
                    isAttivitaContenziosa : selAutovalutazione.isAttivitaContenziosa,
                    clientiTipoId : selAutovalutazione.clientiTipoId,
                    clientiSettore : selAutovalutazione.clientiSettore,
                    isClientiAreaSensibile : selAutovalutazione.isClientiAreaSensibile,
                    isClientiProvenienzaVaria : selAutovalutazione.isClientiProvenienzaVaria,
                    clientiProvenienzaId : selAutovalutazione.clientiProvenienzaId,
                    clientiProvenienzaNote : selAutovalutazione.clientiProvenienzaNote,
                    risultatiId : selAutovalutazione.risultatiId,
                    rischioProvvedimentoId : selAutovalutazione.rischioProvvedimentoId,
                    rischioProvvedimentoNote : selAutovalutazione.rischioProvvedimentoNote,
                    chkProcedure1 : selAutovalutazione.chkProcedure1,
                    chkProcedure2 : selAutovalutazione.chkProcedure2,
                    chkProcedure3 : selAutovalutazione.chkProcedure3,
                    chkProcedure4 : selAutovalutazione.chkProcedure4,
                    chkProcedure5 : selAutovalutazione.chkProcedure5,
                    responsabileId : selAutovalutazione.responsabileId,
                    date : providedDateFromParent ? providedDateFromParent : selAutovalutazione.date,                    
                    provdedBy : providedById ? providedById : selAutovalutazione.provdedBy,
                    createdBy : loggedUserId,
                    modifiedBy: ""
                })
                respObj = saveResp.data.data.insert_autovalutazione_avvocati_one                
            }            
            
            if(respObj !== null){
                setSelAutovalutazione(new autovalutazioneAvvocatoModel(
                    respObj.Id,
                    respObj.TenantId,
                    respObj.AziendaId,
                    respObj.AnalisiNMesi,
                    respObj.AreaGeografica,
                    respObj.IsAreaItalia,
                    respObj.AreaRischioId,
                    respObj.AreaClientiTipoId,
                    respObj.IsMotivoIdent,
                    respObj.ChkMotivoIdent1,
                    respObj.ChkMotivoIdent2,
                    respObj.ChkMotivoIdent3,
                    respObj.ChkMotivoIdent4,
                    respObj.ChkMotivoIdent5,
                    respObj.AttivitaTipicaId,
                    respObj.IsAttivitaContenziosa,
                    respObj.ClientiTipoId,
                    respObj.ClientiSettore,
                    respObj.IsClientiAreaSensibile,
                    respObj.IsClientiProvenienzaVaria,
                    respObj.ClientiProvenienzaId,
                    respObj.ClientiProvenienzaNote,
                    respObj.RisultatiId,
                    respObj.RischioProvvedimentoId,
                    respObj.RischioProvvedimentoNote,
                    respObj.ChkProcedure1,
                    respObj.ChkProcedure2,
                    respObj.ChkProcedure3,
                    respObj.ChkProcedure4,
                    respObj.ChkProcedure5,
                    respObj.ResponsabileId,
                    respObj.Date,
                    respObj.CreatedBy,
                    respObj.ModifiedBy,
                    respObj.ProvdedBy                
                ))
            }
            enqueueSnackbar(t("message.success"), { variant: "success" });
        } catch (error) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
        }
    }
    //#endregion

    async function loadDatas() {
        try {
            if(!isDataLoaded){
                setUserPermissions(
                    sharedDatas
                        .getPropertyByName("userPermissions")
                        .filter((x) => x.area === bsaAutovalutazione)[0]
                );            
    
                if(selAutovalutazione.id > 0){
                    let autoValResp = await getAutovalutazioneAvv.fetch({
                        id: selAutovalutazione.id
                    })
                    let item = autoValResp.data.data.autovalutazione_avvocati_by_pk
                    setSelAutovalutazione(new autovalutazioneAvvocatoModel(
                        item.Id,
                        item.TenantId,
                        item.AziendaId,
                        item.AnalisiNMesi,
                        item.AreaGeografica,
                        item.IsAreaItalia,
                        item.AreaRischioId,
                        item.AreaClientiTipoId,
                        item.IsMotivoIdent,
                        item.ChkMotivoIdent1,
                        item.ChkMotivoIdent2,
                        item.ChkMotivoIdent3,
                        item.ChkMotivoIdent4,
                        item.ChkMotivoIdent5,
                        item.AttivitaTipicaId,
                        item.IsAttivitaContenziosa,
                        item.ClientiTipoId,
                        item.ClientiSettore,
                        item.IsClientiAreaSensibile,
                        item.IsClientiProvenienzaVaria,
                        item.ClientiProvenienzaId,
                        item.ClientiProvenienzaNote,
                        item.RisultatiId,
                        item.RischioProvvedimentoId,
                        item.RischioProvvedimentoNote,
                        item.ChkProcedure1,
                        item.ChkProcedure2,
                        item.ChkProcedure3,
                        item.ChkProcedure4,
                        item.ChkProcedure5,
                        item.ResponsabileId,
                        item.Date,
                        item.CreatedBy,
                        item.ModifiedBy,
                        item.ProvdedBy 
                    ))
                }
            }   
        } catch (error) {
            
        } finally {
            setIsDataLoaded(true)
        }        
    }

    return (
        <>
            <Stack>
                <TableContainer component={Paper} sx={{mt:2}}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <ExtendedTypography 
                                    variant="subtitle1"
                                    fontStyle={{fontWeight : "bold"}}
                                >
                                    Analisi e valutazione del rischio: identificazione dell' attività 
                                        svolta dallo studio e delle eventuali prestazioni a rischio     
                                </ExtendedTypography>
                            </TableRow>
                            <TableRow>                            
                                <TableCell
                                    align="left"
                                    width="78%"
                                    sx={{ pl: "0 !important"}}
                                >
                                    <ExtendedTypography 
                                            variant="subtitle2"                                
                                    >
                                        In coerenza con l'approccio basato sul rischio, si è in primo luogo proceduto
                                        ad effettuare un'analisi della tipologia di clientela che è servita dallo studio
                                        negli ultimi     
                                    </ExtendedTypography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width="22%"
                                >
                                    <Autocomplete
                                        id="ddlanalisiNMesi"
                                        options={months}
                                        size="small"
                                        defaultValue={months.filter(x=> Number(x.split(' ')[0]) === selAutovalutazione.analisiNMesi)}
                                        key={uuidv4()}
                                        onChange={handleChangeDropDown}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required                                        
                                                sx={{ lineHeight: 1 }}
                                            />
                                            )}
                                        />
                                </TableCell>                                                                                                                          
                            </TableRow>
                            <TableRow>
                                <ExtendedTypography
                                    variant="subtitle2"
                                    sx={{mt:1}}                                
                                >
                                    sotto il profilo della : 
                                    <li> area geografica di provenienza</li>
                                    <li> tipologia di assistenza</li>

                                    Questa analisi consente di valutare in concreto il rischio di riciclaggio, basando quindi le
                                    necessarie valutazioni al profilo di rischio attuale e concreto.                                
                                </ExtendedTypography>
                            </TableRow>
                            <TableRow>
                                <ExtendedTypography variant="subtitle2" sx={{mt:1}} width="100%">
                                    Dall’analisi svolta si evincono i seguenti elementi:
                                </ExtendedTypography>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    width="70%"
                                    sx={{ pl: "0 !important"}}
                                >
                                    <ExtendedTypography variant="subtitle2">
                                        la maggioranza assoluta delle clientela è basata in
                                    </ExtendedTypography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width="30%"
                                >
                                    <TextField id="txtAreaGeografica" placeholder="regione italiana o estera..." value={selAutovalutazione.areaGeografica} onChange={handleChangeText}></TextField>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    width="70%"
                                    sx={{ pl: "0 !important"}}
                                >
                                    <ExtendedTypography variant="subtitle2">
                                        ed opera in
                                    </ExtendedTypography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width="30%"
                                >
                                    <RadioGroup name="radioIsAreaItalia" onChange={handleRadioIt}>
                                        <FormControlLabel
                                            value="italy"
                                            label="Italia"
                                            checked={selAutovalutazione.isAreaItalia}
                                            control={<Radio/>}
                                        />
                                        <FormControlLabel
                                            value="europe"
                                            label="Europa"
                                            checked={!selAutovalutazione.isAreaItalia}
                                            control={<Radio/>}
                                        />
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>                            
                            <TableRow>
                                <TableCell
                                    align="left"
                                    width="70%"
                                    sx={{ pl: "0 !important"}}
                                >
                                    <ExtendedTypography variant="subtitle2">
                                        ove potrebbe profilarsi un rischio di connessione con 
                                    </ExtendedTypography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width="30%"
                                >
                                    <Autocomplete
                                        id="ddlareaRischioId"
                                        options={problems}
                                        size="small"
                                        key={uuidv4()}
                                        defaultValue={problems[selAutovalutazione.areaRischioId]}
                                        onChange={handleChangeDropDown}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required                                        
                                                sx={{ lineHeight: 1 }}
                                            />
                                            )}
                                        />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    width="70%"
                                    sx={{ pl: "0 !important"}}
                                >
                                    <ExtendedTypography variant="subtitle2">
                                        i clienti di cui sopra sono tipicamente  
                                    </ExtendedTypography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width="30%"
                                >
                                    <Autocomplete
                                        id="ddlAreaClientiTipoId"
                                        options={idents}
                                        size="small"
                                        key={uuidv4()}
                                        defaultValue={idents[selAutovalutazione.areaClientiTipoId]}
                                        onChange={handleChangeDropDown}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required                                        
                                                sx={{ lineHeight: 1 }}
                                            />
                                            )}
                                        />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    width="70%"
                                    sx={{ pl: "0 !important"}}
                                >
                                    <ExtendedTypography variant="subtitle2">
                                        la cui precisa identificazione del titolare effettivo 
                                    </ExtendedTypography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width="30%"
                                >
                                    <RadioGroup name="radioIsMotivoIdent" onChange={handleIs}>
                                        <FormControlLabel
                                            value="is"
                                            label="è"
                                            checked={selAutovalutazione.isMotivoIdent}
                                            control={<Radio/>}
                                        />
                                        <FormControlLabel
                                            value="isn't"
                                            label="non è"
                                            checked={!selAutovalutazione.isMotivoIdent}
                                            control={<Radio/>}
                                        />
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <ExtendedTypography variant="subtitle2" sx={{mt:1}} width="100%">
                                    agevolata e trasparente da alcuni dati specifici ad esempio :
                                </ExtendedTypography>                                                            
                            </TableRow>                                                                                            
                            <TableRow>
                                <TableCell align="left" width="5%">
                                    <Checkbox
                                        id="chkMotivoIdent1"
                                        checked={selAutovalutazione.chkMotivoIdent1}
                                        onChange={handleCheckBox}                                    
                                    />
                                </TableCell>
                                <TableCell align="left" width="95%">
                                    la riconducibilità della catena proprietaria di controllo a Società quotate in borsa
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" width="5%">
                                    <Checkbox
                                        id="chkMotivoIdent2"
                                        checked={selAutovalutazione.chkMotivoIdent2}
                                        onChange={handleCheckBox}                                   
                                    />
                                </TableCell>
                                <TableCell align="left" width="95%">
                                    il fatto che i clienti siano quasi esclusivamente persone fisiche
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" width="5%">
                                    <Checkbox
                                        id="chkMotivoIdent3"
                                        checked={selAutovalutazione.chkMotivoIdent3}
                                        onChange={handleCheckBox}                                   
                                    />
                                </TableCell>
                                <TableCell align="left" width="95%">
                                    il fatto che i clienti siano pubbliche amministrazioni
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" width="5%">
                                    <Checkbox
                                        id="chkMotivoIdent4"
                                        checked={selAutovalutazione.chkMotivoIdent4}
                                        onChange={handleCheckBox}                                  
                                    />
                                </TableCell>
                                <TableCell align="left" width="95%">
                                    inserire circostanze confacenti al caso di specie
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    width="60%"
                                    sx={{ pl: "0 !important"}}
                                >
                                    <ExtendedTypography variant="subtitle2">
                                        una certa ricorrente tipicità delle attività che lo Studio è chiamato a svolgere a favore della
                                        clientela, che è con grande prevalenza relativa alla
                                    </ExtendedTypography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width="40%"
                                >
                                    <Autocomplete
                                        id="ddlAttivitaTipicaId"
                                        options={relativesTo}
                                        size="small"
                                        key={uuidv4()}
                                        defaultValue={relativesTo[selAutovalutazione.attivitaTipicaId]}
                                        onChange={handleChangeDropDown}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required                                        
                                                sx={{ lineHeight: 1 }}
                                            />
                                            )}
                                        />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" width="60%" sx={{ pl: "0 !important"}}>
                                    e contenziosa
                                </TableCell>
                                <TableCell align="left" width="40%" sx={{ pl: "0 !important"}}>
                                    <Checkbox
                                        id="contenzioso"
                                        checked={selAutovalutazione.isAttivitaContenziosa}
                                        onChange={handleCheckBox}                                   
                                    />
                                </TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    width="60%"
                                    sx={{ pl: "0 !important"}}
                                >
                                    <ExtendedTypography variant="subtitle2">
                                        di
                                    </ExtendedTypography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width="40%"
                                >
                                    <Autocomplete
                                        id="ddlClientiTipoId"
                                        options={whos}
                                        size="small"
                                        key={uuidv4()}
                                        defaultValue={whos[selAutovalutazione.clientiTipoId]}
                                        onChange={handleChangeDropDown}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required                                        
                                                sx={{ lineHeight: 1 }}
                                            />
                                            )}
                                        />
                                </TableCell>
                            </TableRow>
                            {selAutovalutazione.clientiTipoId === 2 && 
                                <TableRow align="left" width="10%">
                                    <TableCell
                                        align="left"
                                        width="60%"
                                        sx={{ pl: "0 !important"}}
                                    >
                                        <ExtendedTypography variant="subtitle2">
                                            indicare il settore
                                        </ExtendedTypography>
                                    </TableCell>
                                    
                                    <TableCell 
                                        align="left"
                                        width="40%"                                    
                                    >
                                        <TextField id="txtClientiSettore" value={selAutovalutazione.clientiSettore} onChange={handleChangeText}/>
                                    </TableCell>
                                </TableRow>
                            }
                            <TableRow>
                                <TableCell
                                    align="left"
                                    width="60%"
                                    sx={{ pl: "0 !important"}}
                                    style={{borderBottom:'0'}}
                                >
                                    <ExtendedTypography variant="subtitle2">
                                        e quindi in un settore che
                                    </ExtendedTypography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width="40%"
                                    style={{borderBottom:'0'}}
                                >
                                    <RadioGroup name="radioIsClientiAreaSensibile" onChange={handlePartOf}>
                                        <FormControlLabel
                                            value="isPartOf"
                                            label="rientra"
                                            checked={selAutovalutazione.isClientiAreaSensibile}
                                            control={<Radio/>}
                                        />
                                        <FormControlLabel
                                            value="isn'tPartOf"
                                            label="non rientra"
                                            checked={!selAutovalutazione.isClientiAreaSensibile}
                                            control={<Radio/>}
                                        />
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>                                              
                            <TableRow sx={{width:'100%'}}>
                                <TableCell
                                    align="left"                                    
                                    sx={{ pl: "0 !important",columnSpan:'all'}}
                                    style={{borderTop:'0'}}
                                >
                                    <ExtendedTypography  variant="subtitle2" sx={{mt:1}}>
                                        in un’area qualificata come sensibile dagli schemi di anomalia
                                        pubblicati dall’UIF sino ad oggi
                                    </ExtendedTypography>                                                                                                                                                                             
                                </TableCell>           
                                <TableCell/>                                          
                            </TableRow>  
                            <TableRow>
                                <TableCell
                                    align="left"
                                    width="60%"
                                    sx={{ pl: "0 !important"}}
                                >
                                    <ExtendedTypography variant="subtitle2">
                                        nel corso del precedente anno si sono verificati
                                    </ExtendedTypography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width="40%"
                                >
                                    <RadioGroup name="radioIsClientiProvenienzaVaria" onChange={handleVer}>
                                        <FormControlLabel
                                            value="limit"
                                            label="solo limitati"
                                            checked={selAutovalutazione.isClientiProvenienzaVaria}
                                            control={<Radio/>}
                                        />
                                        <FormControlLabel
                                            value="some"
                                            label="vari"
                                            checked={!selAutovalutazione.isClientiProvenienzaVaria}
                                            control={<Radio/>}
                                        />
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>       
                            <TableRow>
                                <TableCell
                                    align="left"
                                    width="60%"
                                    sx={{ pl: "0 !important"}}
                                >
                                    <ExtendedTypography variant="subtitle2">
                                        casi di clienti che provenivano
                                    </ExtendedTypography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width="40%"
                                >
                                    <Autocomplete
                                        id="ddlClientiProvenienzaId"
                                        options={provenience}
                                        size="small"
                                        key={uuidv4()}
                                        defaultValue={provenience[selAutovalutazione.clientiProvenienzaId]}
                                        onChange={handleChangeDropDown}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required                                        
                                                sx={{ lineHeight: 1 }}
                                            />
                                            )}
                                        />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    width="60%"
                                    sx={{ pl: "0 !important"}}
                                >
                                    <ExtendedTypography variant="subtitle2">
                                        vale a dire
                                    </ExtendedTypography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width="40%"
                                >
                                    <TextField id="txtClientiProvenienzaNote" value={selAutovalutazione.clientiProvenienzaNote} onChange={handleChangeText}/>
                                </TableCell>
                            </TableRow>                   
                        </TableBody>
                    </Table>
                    <Table size="small" sx={{mt:2}}>
                        <TableHead>
                            <TableRow>
                                <ExtendedTypography 
                                    variant="subtitle1"
                                    fontStyle={{fontWeight : "bold"}}
                                >
                                    Risultanze dell'analisi svolta     
                                </ExtendedTypography>
                            </TableRow>
                            <TableRow>
                                <ExtendedTypography 
                                    variant="subtitle2"                                
                                >
                                    Il risultato valutativo complessivo di sintesi è qui di seguito riportato:     
                                </ExtendedTypography>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    width="100%"
                                    sx={{ pl: "0 !important"}}
                                >
                                    <RadioGroup name="radioRisultatiId" onChange={handleRes} sx={{ml:1}}>
                                        <FormControlLabel
                                            value="ass"
                                            label="Lo Studio ha con assoluta e nettissima prevalenza una clientela a basso rischio di riciclaggio
                                                    sia per tipologia di attività richiesta, sia per origine geografica dei clienti."
                                            checked={selAutovalutazione.risultatiId === 0}
                                            control={<Radio/>}
                                        />
                                        <FormControlLabel
                                            value="notAss"
                                            label="Lo Studio presenta alcune aree di attività che, in linea teorica, presentano specifici rischi di
                                                    riciclaggio, rispetto ai quali i soci sono soliti porre in essere le seguenti attività di
                                                    adeguata verifica al fine di identificare la natura e lo scopo della singola operazione e compiere
                                                    una valutazione volta alla decisione di effettuare la segnalazione all’UIF"
                                            checked={selAutovalutazione.risultatiId === 1}
                                            control={<Radio/>}
                                        />                               
                                    </RadioGroup>                       

                                    <ExtendedTypography 
                                        variant="subtitle2"
                                        sx={{mt:1}}                                
                                    >
                                        Questa valutazione effettuata in buona fede è il punto di partenza per evidenziare i presidi che
                                        sono stati implementati con riferimento al rischio.     
                                    </ExtendedTypography>

                                    <ExtendedTypography 
                                        variant="subtitle2"
                                        sx={{mt:1}}                                
                                    >
                                        In sostanza, in coerenza con il dato di cui sopra e con l’approccio basato sul rischio si è deciso
                                        internamente in Studio
                                    </ExtendedTypography>

                                    <RadioGroup name="radioRischioProvvedimentoId" onChange={handleChoice} sx={{ml:1}}>
                                        <FormControlLabel
                                            value="geog"
                                            label="di alzare il livello di attenzione e rafforzare i presidi solo allorquando i
                                                    clienti provengano da ..."
                                            checked={selAutovalutazione.rischioProvvedimentoId === 0}
                                            control={<Radio/>}
                                        />
                                        <FormControlLabel
                                            value="newCli"
                                            label="tenere un adeguato livello di attenzione in caso di richiesta di assistenza
                                                    nelle materie ... ovvero provenienti da nuovi clienti situati in aree geografiche a rischio ovvero"
                                            checked={selAutovalutazione.rischioProvvedimentoId === 1}
                                            control={<Radio/>}
                                        />
                                        <FormControlLabel
                                            value="other"
                                            label="adattare al caso in specie"
                                            checked={selAutovalutazione.rischioProvvedimentoId === 2}
                                            control={<Radio/>}
                                        />                               
                                    </RadioGroup>
                                    
                                    <TextField id="txtRischioProvvedimentoNote" value={selAutovalutazione.rischioProvvedimentoNote} onChange={handleChangeText}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <ExtendedTypography 
                        variant="subtitle1"
                        fontStyle={{fontWeight : "bold"}}
                    >
                        Procedure e Presidi Antiriciclaggio adottate dallo Studio    
                    </ExtendedTypography>
                    <ExtendedTypography 
                        variant="subtitle2"                                
                    >
                        Fermo quanto sopra, lo Studio ha adottato sin dall’inizio recepimento delle Direttive
                        antiriciclaggio procedure e presidi che si ritengono essere perfettamente rispondenti al dettato
                        normativo e soprattutto coerenti con la valutazione del rischio di cui al punto 1    
                    </ExtendedTypography>
                    <ExtendedTypography 
                        variant="subtitle2"                                
                    >
                        Si elencano di seguito le procedure e presidi:   
                    </ExtendedTypography>
                    <Table size="small" sx={{mt:2}}>                    
                        <TableBody>
                            <TableRow>
                                <TableCell align="left" width="5%">
                                    <Checkbox
                                        id="chkProcedure1"
                                        checked={selAutovalutazione.chkProcedure1}
                                        onChange={handleCheckBox}                                    
                                    />
                                </TableCell>
                                <TableCell align="left" width="95%">
                                    lo Studio non si interpone ai clienti: mai, in nessun caso, lo Studio opera “per conto
                                    del cliente” nell’acquisto di beni e servizi, con l’obbligo poi di retrocessione al Cliente
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" width="5%">
                                    <Checkbox
                                        id="chkProcedure2"
                                        checked={selAutovalutazione.chkProcedure2}
                                        onChange={handleCheckBox}                                   
                                    />
                                </TableCell>
                                <TableCell align="left" width="95%">
                                    lo Studio non opera in nome e per conto del Cliente quale procuratore di quest’ultimo
                                    con spendita del nome. Se il Cliente deve perfezionare un’operazione di vendita di beni
                                    immobili o di attività economiche, interverrà lui direttamente all’atto, e non per nostro
                                    tramite
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" width="5%">
                                    <Checkbox
                                        id="chkProcedure3"
                                        checked={selAutovalutazione.chkProcedure3}
                                        onChange={handleCheckBox}                                   
                                    />
                                </TableCell>
                                <TableCell align="left" width="95%">
                                    lo Studio non gestisce somme, danari, azioni o titoli di Clienti
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" width="5%">
                                    <Checkbox
                                        id="chkProcedure4"
                                        checked={selAutovalutazione.chkProcedure4}
                                        onChange={handleCheckBox}                                  
                                    />
                                </TableCell>
                                <TableCell align="left" width="95%">
                                    la maggior parte dell’attività dello Studio riguarda i profili contenziosi relativi a
                                    persone fisiche nei rari casi in cui lo Studio ha svolto attività stragiudiziale, quale
                                    consulenza in materia contrattualistica, essa ha riguardato la negoziazione di accordi
                                    transattivi
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" width="5%">
                                    <Checkbox
                                        id="chkProcedure5"
                                        checked={selAutovalutazione.chkProcedure5}
                                        onChange={handleCheckBox}                                    
                                    />
                                </TableCell>
                                <TableCell align="left" width="95%">
                                    lo Studio presta consulenza da diversi anni a favore di pochi clienti di rilevanti
                                    dimensioni, i quali sono società quotate che rispettano in modo pedissequo, la
                                    normativa in materia di comunicazioni al pubblico e di compliance rispetto agli
                                    obblighi informativi previsti dalle autorità di vigilanza e controllo
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    width="60%"
                                    sx={{pl:'o !important'}}
                                >
                                    Indicare il responsabile antiriciclaggio
                                </TableCell>
                                <TableCell
                                    align="left"
                                    width="40%"
                                >
                                    <Autocomplete
                                        id='ddlUtenti'
                                        key={uuidv4()}
                                        size='small'
                                        options={profList}
                                        sx={{ width: "100%" }}
                                        fullWidth
                                        onChange={handleChangeDropDown}
                                        isOptionEqualToValue={(option, value) =>
                                        option.userId === value.responsabileId
                                        }
                                        getOptionLabel={(option) => option.completeName}
                                        defaultValue={
                                        selAutovalutazione.responsabileId !== undefined
                                            ? profList.filter(
                                                (item) =>
                                                item.userId === selAutovalutazione.responsabileId
                                            )[0]
                                            : null
                                        }
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            sx={{ lineHeight: 1 }}
                                        />
                                        )}
                                    />                            
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>

            <VaporToolbar
            variant='regular'
            size='medium'            
            withoutAppBar={false}
            contentRight={
                <Stack direction='row' spacing={2}>
                    <Button
                        variant='outlined'
                        size='medium'
                        endIcon={<PrintIcon />}
                        onClick={handlePrintReportClick}
                        disabled={
                        !userPermissions.canRead || selAutovalutazione.id <= 0
                        }
                    >
                        {t("autovalutazione.autovalutazioneAdd.pntRep")}
                    </Button>
                    <Button
                        variant='contained'
                        size='medium'
                        endIcon={<SaveIcon />}
                        onClick={handleSaveAutovalutazione}
                        disabled={!userPermissions.canUpdate}
                    >
                        {selAutovalutazione.id === 0
                        ? t("actions.salva")
                        : t("actions.aggiorna")}
                    </Button>
                </Stack>
            }
            ></VaporToolbar>
        </>

    )
}   