//#region Imports
import React, { useState, useEffect, Fragment } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  useMutation,
  useGet,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import VaporTag from "@vapor/react-custom/VaporTag";

//Vapor Components
import Divider from "@vapor/react-material/Divider";
import Stack from "@vapor/react-material/Stack";
import Slider from "@vapor/react-material/Slider";
import Table from "@vapor/react-material/Table";
import TableBody from "@vapor/react-material/TableBody";
import TableCell from "@vapor/react-material/TableCell";
import TableContainer from "@vapor/react-material/TableContainer";
import TableHead from "@vapor/react-material/TableHead";
import TableRow from "@vapor/react-material/TableRow";
import Paper from "@vapor/react-material/Paper";
import TextField from "@vapor/react-material/TextField";
import Button from "@vapor/react-material/Button";
import Box from "@vapor/react-material/Box";
import Autocomplete from "@vapor/react-material/Autocomplete";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";

//Vapor Date Pickers
import { AdapterDateFns } from "@vapor/react-x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@vapor/react-x-date-pickers/LocalizationProvider";
import { DatePicker } from "@vapor/react-x-date-pickers/DatePicker";
import { it } from "date-fns/locale";

//Custom styles
import {
  chipGreen,
  chipYellow,
  chipOrange,
  chipRed,
} from "../../businessLogic/styles";

//Icons
import {
  Save as SaveIcon,
  Print as PrintIcon,
} from "@mui/icons-material";

//Custom components
import { Loading } from "../../components/Loading";
import { NotAuth } from "../../components/NotAuth";

//Constants, Api and Data Models
import {
  bsaAutovalutazione,
  pathAutoValutazioneAdd,
} from "../../businessLogic/constants";
import {
  getLocalStorageSelectedAutovalutazione,
  setLocalStorageSelectedAutovalutazione,
  isDateValid,
} from "../../businessLogic/bl";
import {
  tenantModel,
  userPermissionModel,
  autoValutazioneModel,
  userModel,
  autovalutazioneAvvocatoModel,
} from "../../businessLogic/models";
import {
  query_autoValutazione_byId,
  query_autoValutazioneAvvocato_byId,
  query_users_byCompanyId,
} from "../../businessLogic/query";
import {
  mutation_add_log,
  mutation_add_audit,
  mutation_add_autovalutazione,
  mutation_update_autovalutazione,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
  mutation_get_document,
} from "../../businessLogic/mutation";

//Other components
import { v4 as uuidv4 } from "uuid";
import download from "downloadjs";
import { AutovalutazioneAvvocatoForm } from "./AutovalutazioneAvvocatoForm";
//#endregion

export const AutovalutazioneAdd = ({ basePath }) => {
  
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const tenantTipoId = sharedDatas.getPropertyByName("userModel").tenantTipoId;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const selectedAutovalutazione = getLocalStorageSelectedAutovalutazione();
  const tsApiAntiRic = useGet("aml:ts-anti-ric:api://", { lazy: true });
  const sliderLivelli = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
  ];

  //#region Query
  const apiAutovalutazioneById = useQuery(
    query_autoValutazione_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAutovalutazioneAvvById = useQuery(
    query_autoValutazioneAvvocato_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUsersByCompanyId = useQuery(
    query_users_byCompanyId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
//#endregion

  //#region Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAutovalutazione = useMutation(mutation_add_autovalutazione, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateAutovalutazione = useMutation(
    mutation_update_autovalutazione,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiGetJsReportDocument = useMutation(mutation_get_document, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  //#endregion

  //#region UseStates
  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaAutovalutazione,
      false,
      false,
      false,
      false
    )
  );
  const [, setTenant] = useState(new tenantModel());
  const [tenantTypeId, setTenantTypeId] = useState(0);

  //Professionisti
  const [utentiList, setUtentiList] = useState([]);

  //Autovalutazione
  const [selAutovalutazione, setSelAutovalutazione] = useState(
    new autoValutazioneModel(
      0,
      tenantId,
      aziendaId,
      tenantTypeId,
      new Date(),
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      4,
      4,
      1,
      1,
      null,
      "",
      null,
      null,
      loggedUserId
    )
  );
  const [selAutovalutazioneOriginal, setSelAutovalutazioneOriginal] = useState(
    new autoValutazioneModel(
      0,
      tenantId,
      aziendaId,
      tenantTypeId,
      new Date(),
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      4,
      4,
      1,
      1,
      null,
      "",
      null,
      null,
      loggedUserId
    )
  ); //Used for Audit
  const [sliderVal, setSliderVal] = useState({
    sliderRi1: 1,
    sliderRi2: 1,
    sliderRi3: 1,
    sliderRi4: 1,
    sliderLv1: 1,
    sliderLv2: 1,
    sliderLv3: 1,
    sliderLv4: 1,
  });

  //Rischio inerente la prestazione
  const [, setRischioResiduoLivello] = useState(1);
  const [rischioResiduoLivelloS, setRischioResiduoLivelloS] = useState(
    t("autovalutazione.form.livelloRischio1")
  );
  const [rischioResiduoLivelloC, setRischioResiduoLivelloC] =
    useState(chipGreen);

  //Totali
  const [totRischioInerente, setTotRischioInerente] = useState(0);
  const [totLivelloVulnerabilita, setTotLivelloVulnerabilita] = useState(0);
  const [totRischioResiduo, setTotRischioResiduo] = useState(0);
  //#endregion
  
  //#region Effetcs
  useEffect(() => {
    loadDatas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);
  useEffect(() => {
    calcoloRischio();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderVal]);
  //#endregion

  //#region Load Functions
  async function loadDatas() {
    try {
      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded) {
          /**************************************/
          //Carico le informazioni relative al Tenant
          setTenant(sharedDatas.getPropertyByName("tenantModel"));

          /**************************************/
          //Carico i permessi relativi all'utente
          setUserPermissions(
            sharedDatas
              .getPropertyByName("userPermissions")
              .filter((x) => x.area === bsaAutovalutazione)[0]
          );

          /**************************************/
          //Carico l'utente
          setTenantTypeId(sharedDatas.getPropertyByName("userModel").tenantTipoId);

          /**************************************/
          //Carico gli utenti
          const usersResponse = await apiUsersByCompanyId.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
          });
          const arrUsers = [];
          usersResponse.data.data.user_tenant.map((item) =>
            arrUsers.push(
              new userModel(
                item.UserId,
                item.TenantId,
                item.user.Username,
                item.user.Email,
                item.user.Name,
                item.user.Surname,
                item.user.Name + " " + item.user.Surname,
                item.Type,
                item.user.Locale,
                item.TenantTipoId,
                item.user.IsRespAntiRic,
                item.base_tipo_tenant.Value,
                item.user.IsActive,
                item.user.IsDeleted,
                item.user.IsBanned,
                item.user.CreatedOn,
                item.user.Cf
              )
            )
          );
          setUtentiList(arrUsers);

          /**************************************/
          //Carico l'autovalutazione
          const selectedAutovalutazioneResponse = await selectedAutovalutazione;
          const itemId = parseInt(selectedAutovalutazioneResponse);
          let autovalutazione = new autoValutazioneModel();

          //Se non è valorizzato ne sto creando uno nuovo
          if (itemId === 0) {
            autovalutazione = new autoValutazioneModel(
              0,
              tenantId,
              aziendaId,
              tenantTypeId,
              new Date(),
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              4,
              4,
              1,
              1,
              null,
              "",
              null,
              null,
              loggedUserId
            );
            setSelAutovalutazione(autovalutazione);
          }

          //altrimenti vuol dire che ne voglio visualizzare il dettaglio
          else {
            //Recupero l'autovalutazione dal db
            if(tenantTipoId !== 3){
              const autoValResponse = await apiAutovalutazioneById.fetch({
                id: itemId,
              });
              if (autoValResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                const item = autoValResponse.data.data.autovalutazione_by_pk;
                autovalutazione = new autoValutazioneModel(
                  item.Id,
                  item.TenantId,
                  item.AziendaId,
                  item.TenantTipoId,
                  item.Date,
                  item.Ri1,
                  item.Ri2,
                  item.Ri3,
                  item.Ri4,
                  item.Lv1,
                  item.Lv2,
                  item.Lv3,
                  item.Lv4,
                  item.RiTotale,
                  item.LvTotale,
                  item.RrTotale,
                  item.LivelloRischio,
                  item.ProvidedBy,
                  item.Note,
                  item.CreatedOn,
                  item.ModifiedOn,
                  item.CreatedBy,
                  item.ModifiedBy
                );

                setSelAutovalutazione(autovalutazione);
                setSelAutovalutazioneOriginal(autovalutazione);
                setTenantTypeId(item.TenantTipoId);

                //Last Activity
                await apiDeleteLastActivity.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  userId: loggedUserId,
                });
                await apiAddLastActivity.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  path: pathAutoValutazioneAdd,
                  entityId: autovalutazione.id.toString(),
                  entityName: new Date(item.Date).toLocaleDateString(),
                });

                setSliderVal({
                  sliderRi1: autovalutazione.ri1,
                  sliderRi2: autovalutazione.ri2,
                  sliderRi3: autovalutazione.ri3,
                  sliderRi4: autovalutazione.ri4,
                  sliderLv1: autovalutazione.lv1,
                  sliderLv2: autovalutazione.lv2,
                  sliderLv3: autovalutazione.lv3,
                  sliderLv4: autovalutazione.lv4,
                });
              }
            } else if (tenantTipoId === 3){
              const autovalAvvResp = await apiAutovalutazioneAvvById.fetch({
                id:itemId
              })

              if(autovalAvvResp.data.errors !== undefined){
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                const item = autovalAvvResp.data.data.autovalutazione_avvocati_by_pk;
                let autovalutazioneAvv = new autovalutazioneAvvocatoModel(
                  item.Id,
                  item.TenantId,
                  item.AziendaId,
                  item.AnalisiNMesi,
                  item.AreaGeografica,
                  item.IsAreaItalia,
                  item.AreaRischioId,
                  item.AreaClientiTipoId,
                  item.IsMotivoIdent,
                  item.ChkMotivoIdent1,
                  item.ChkMotivoIdent2,
                  item.ChkMotivoIdent3,
                  item.ChkMotivoIdent4,
                  item.ChkMotivoIdent5,
                  item.AttivitaTipicaId,
                  item.IsAttivitaContenziosa,
                  item.ClientiTipoId,
                  item.ClientiSettore,
                  item.IsClientiAreaSensibile,
                  item.IsClientiProvenienzaVaria,
                  item.ClientiProvenienzaId,
                  item.ClientiProvenienzaNote,
                  item.RisultatiId,
                  item.RischioProvvedimentoId,
                  item.RischioProvvedimentoNote,
                  item.ChkProcedure1,
                  item.ChkProcedure2,
                  item.ChkProcedure3,
                  item.ChkProcedure4,
                  item.ChkProcedure5,
                  item.ResponsabileId,
                  item.Date,
                  item.CreatedBy,
                  item.ModifiedBy,
                  item.ProvdedBy,
                  item.ModifiedOn,
                  item.CreatedOn
                )

                setSelAutovalutazione(autovalutazioneAvv);
                setSelAutovalutazioneOriginal(autovalutazioneAvv);

                //Last Activity
                await apiDeleteLastActivity.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  userId: loggedUserId,
                });
                await apiAddLastActivity.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  path: pathAutoValutazioneAdd,
                  entityId: autovalutazioneAvv.id.toString(),
                  entityName: new Date(item.CreatedOn).toLocaleDateString(),
                });
              }
            }
          }
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AutovalutazioneAdd",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }
  const calcoloRischio = () => {
    let autoValutazioneItem = selAutovalutazione;

    //Se l'id è uguale a -1 vuol dire che arrivo qui prima ancora che venga inizializzato il model
    if (autoValutazioneItem.id === -1) {
      autoValutazioneItem = new autoValutazioneModel(
        0,
        tenantId,
        aziendaId,
        tenantTypeId,
        new Date(),
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        4,
        4,
        1,
        1,
        loggedUserId,
        "",
        null,
        null,
        loggedUserId
      );
    }

    //Specifico
    var rischioInerente =
      (autoValutazioneItem.ri1 +
        autoValutazioneItem.ri2 +
        autoValutazioneItem.ri3 +
        autoValutazioneItem.ri4) /
      4;
    var livelloVulnerabilita =
      (autoValutazioneItem.lv1 +
        autoValutazioneItem.lv2 +
        autoValutazioneItem.lv3 +
        autoValutazioneItem.lv4) /
      4;

    //Residuo
    var riTotale = (rischioInerente * 40) / 100;
    var lvTotale = (livelloVulnerabilita * 60) / 100;
    var rrTotale = riTotale + lvTotale;

    //Se sono un Consulente del Lavoro applico le regole tecniche specifiche
    if (tenantTypeId === 2) {
      riTotale =
        (autoValutazioneItem.ri1 +
          autoValutazioneItem.ri2 +
          autoValutazioneItem.ri3) /
        3;
      rrTotale = riTotale;
    }

    autoValutazioneItem.riTotale = riTotale.toFixed(2);
    autoValutazioneItem.lvTotale = lvTotale.toFixed(2);
    autoValutazioneItem.rrTotale = rrTotale.toFixed(2);

    setTotRischioInerente(riTotale);
    setTotLivelloVulnerabilita(lvTotale);
    setTotRischioResiduo(rrTotale);

    //Livello di rischio residuo
    var livello = 1;
    var livelloS = t("autovalutazione.form.livelloRischio1");
    var livelloC = chipGreen;

    if (rrTotale >= 1.0 && rrTotale <= 1.5) {
      livello = 1;
      livelloS = t("autovalutazione.form.livelloRischio1");
      livelloC = chipGreen;
    } else if (rrTotale > 1.5 && rrTotale <= 2.5) {
      livello = 2;
      livelloS = t("autovalutazione.form.livelloRischio2");
      livelloC = chipYellow;
    } else if (rrTotale > 2.5 && rrTotale <= 3.5) {
      livello = 3;
      livelloS = t("autovalutazione.form.livelloRischio3");
      livelloC = chipOrange;
    } else if (rrTotale > 3.5) {
      livello = 4;
      livelloS = t("autovalutazione.form.livelloRischio4");
      livelloC = chipRed;
    }
    autoValutazioneItem.livelloRischio = livello;
    setRischioResiduoLivello(livello);
    setRischioResiduoLivelloS(livelloS);
    setRischioResiduoLivelloC(livelloC);
    setSelAutovalutazione(autoValutazioneItem);
  };
  //#endregion

  //#region Handler
  //Home Button
  const handleGoHome = () => {
    setLocalStorageSelectedAutovalutazione(0);
    navigate(basePath);
  };
  //Autovalutazione
  const handleChange = (event, value) => {
    const id =
      event.target.id !== undefined ? event.target.id : event.target.name;

    //Ri
    if (id === "sliderRi1") {
      selAutovalutazione.ri1 = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderRi1: event.target.value,
      }));
    } else if (id === "sliderRi2") {
      selAutovalutazione.ri2 = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderRi2: event.target.value,
      }));
    } else if (id === "sliderRi3") {
      selAutovalutazione.ri3 = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderRi3: event.target.value,
      }));
    } else if (id === "sliderRi4") {
      selAutovalutazione.ri4 = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderRi4: event.target.value,
      }));
    }

    //Lv
    else if (id === "sliderLv1") {
      selAutovalutazione.lv1 = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderLv1: event.target.value,
      }));
    } else if (id === "sliderLv2") {
      selAutovalutazione.lv2 = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderLv2: event.target.value,
      }));
    } else if (id === "sliderLv3") {
      selAutovalutazione.lv3 = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderLv3: event.target.value,
      }));
    } else if (id === "sliderLv4") {
      selAutovalutazione.lv4 = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderLv4: event.target.value,
      }));
    } else if (id.indexOf("ddlUtenti-option") !== -1) {
      selAutovalutazione.providedBy = value.userId;     
      setSelAutovalutazione((prevState) => ({ ...prevState, providedBy: value.userId }));
    } else if (id === "txtNote") {
      selAutovalutazione.note = event.target.value;
      setSelAutovalutazione((prevState) => ({ ...prevState, note: event.target.value }));
    }

    //setSelAutovalutazione(selAutovalutazione);
  };
  const handleChangeDate = (date) => {
    if (date !== null && isDateValid(date)) {
      const newDate = new Date(date);
      selAutovalutazione.date = newDate;
      setSelAutovalutazione((prevState) => ({ ...prevState, date: newDate }));
    }
  };
  
  const handleSaveAutovalutazione = async () => {
    try {
      setBackDropOpen(true);

      if (
        selAutovalutazione.date !== undefined &&
        selAutovalutazione.date !== null
      ) {
        //Se sto creando l'autovalutazione
        if (selAutovalutazione.id === 0) {
          const addAutoValResponse = await apiAddAutovalutazione.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            tenantTipoId: tenantTypeId,
            date: selAutovalutazione.date,
            ri1: selAutovalutazione.ri1,
            ri2: selAutovalutazione.ri2,
            ri3: selAutovalutazione.ri3,
            ri4: selAutovalutazione.ri4,
            lv1: selAutovalutazione.lv1,
            lv2: selAutovalutazione.lv2,
            lv3: selAutovalutazione.lv3,
            lv4: selAutovalutazione.lv4,
            riTotale: selAutovalutazione.riTotale,
            lvTotale: selAutovalutazione.lvTotale,
            rrTotale: selAutovalutazione.rrTotale,
            livelloRischio: selAutovalutazione.livelloRischio,
            providedBy: selAutovalutazione.providedBy,
            note: selAutovalutazione.note,
          });
          if (addAutoValResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result =
              addAutoValResponse.data.data.insert_autovalutazione_one;
            const autovalutazioneResultModel = new autoValutazioneModel(
              result.Id,
              result.TenantId,
              result.AziendaId,
              result.TenantTipoId,
              result.Date,
              result.Ri1,
              result.Ri2,
              result.Ri3,
              result.Ri4,
              result.Lv1,
              result.Lv2,
              result.Lv3,
              result.Lv4,
              result.RiTotale,
              result.LvTotale,
              result.RrTotale,
              result.LivelloRischio,
              result.ProvidedBy,
              result.Note,
              result.CreatedOn,
              result.ModifiedOn,
              result.CreatedBy,
              result.ModifiedBy
            );
            setSelAutovalutazione(autovalutazioneResultModel);

            //Aggiungo la Audit per tracciare l'azione
            const newValues = autovalutazioneResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaAutovalutazione,
              operation: "Create",
              entityType: "autovalutazione",
              entityId: result.Id.toString(),
              oldValues: "",
              newValues: JSON.stringify(newValues),
            });

            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        }

        //Se la sto modificando
        else {
          const updateAutoValResponse = await apiUpdateAutovalutazione.fetch({
            id: selAutovalutazione.id,
            tenantId: tenantId,
            aziendaId: aziendaId,
            tenantTipoId: selAutovalutazione.tenantTipoId,
            date: selAutovalutazione.date,
            ri1: selAutovalutazione.ri1,
            ri2: selAutovalutazione.ri2,
            ri3: selAutovalutazione.ri3,
            ri4: selAutovalutazione.ri4,
            lv1: selAutovalutazione.lv1,
            lv2: selAutovalutazione.lv2,
            lv3: selAutovalutazione.lv3,
            lv4: selAutovalutazione.lv4,
            riTotale: selAutovalutazione.riTotale,
            lvTotale: selAutovalutazione.lvTotale,
            rrTotale: selAutovalutazione.rrTotale,
            livelloRischio: selAutovalutazione.livelloRischio,
            providedBy: selAutovalutazione.providedBy,
            note: selAutovalutazione.note,
          });
          if (updateAutoValResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result =
              updateAutoValResponse.data.data.update_autovalutazione
                .returning[0];
            let autovalutazioneResultModel = new autoValutazioneModel(
              result.Id,
              result.TenantId,
              result.AziendaId,
              result.TenantTipoId,
              result.Date,
              result.Ri1,
              result.Ri2,
              result.Ri3,
              result.Ri4,
              result.lv1,
              result.lv2,
              result.lv3,
              result.lv4,
              result.RiTotale,
              result.LvTotale,
              result.RrTotale,
              result.LivelloRischio,
              result.ProvidedBy,
              result.Note,
              result.CreatedOn,
              result.ModifiedOn,
              result.CreatedBy,
              result.ModifiedBy
            );
            setSelAutovalutazione(autovalutazioneResultModel);

            //Aggiungo la Audit per tracciare l'azione
            const newValues = autovalutazioneResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaAutovalutazione,
              operation: "Update",
              entityType: "autovalutazione",
              entityId: selAutovalutazione.id.toString(),
              oldValues: JSON.stringify(selAutovalutazioneOriginal),
              newValues: JSON.stringify(newValues),
            });

            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        }
      } else {
        enqueueSnackbar(t("error.missingFields"), { variant: "error" });
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AutovalutazioneAdd",
        method: "handleSaveAutovalutazione",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };

  //Print
  const handlePrintReportClick = async () => {
    setBackDropOpen(true);
    var reportTypeName = "comm_autovalutazione";
    if (tenantTypeId === 1) {
      reportTypeName = "comm_autovalutazione";
    } else if (tenantTypeId === 2) {
      reportTypeName = "cons_autovalutazione";
    }

    const reportFileName = "reportAutovalutazioneRischio";

    const aziendaInfos = sharedDatas
      .getPropertyByName("aziendaModelList")
      .filter((x) => x.id === sharedDatas.getPropertyByName("aziendaId"))[0];
    const tenant = {
      tenantId: aziendaInfos.id,
      name: aziendaInfos.denominazione,
      cf: aziendaInfos.cf,
      pIva: aziendaInfos.pIva,
      address:
        aziendaInfos.addrIndirizzo +
        ", " +
        aziendaInfos.addrN +
        " - " +
        aziendaInfos.addrCap +
        " " +
        aziendaInfos.addrComune +
        " (" +
        aziendaInfos.addrProvincia +
        ")",
      city: aziendaInfos.addrComune,
    };

    //Recupero le info dell'utente
    const userById = utentiList.filter(
      (x) => x.userId === selAutovalutazione.providedBy
    )[0];
    var professionista = {
      name: userById.name + " " + userById.surname,
    };

    const body = {
      template: {
        name: reportTypeName,
      },
      data: {
        tenant,
        autovalutazione: {
          date: new Date(selAutovalutazione.date).toLocaleDateString(),
          ri1: selAutovalutazione.ri1,
          ri2: selAutovalutazione.ri2,
          ri3: selAutovalutazione.ri3,
          ri4: selAutovalutazione.ri4,
          lv1: selAutovalutazione.lv1,
          lv2: selAutovalutazione.lv2,
          lv3: selAutovalutazione.lv3,
          lv4: selAutovalutazione.lv4,
          riTotal: selAutovalutazione.riTotale,
          lvTotal: selAutovalutazione.lvTotale,
          rrTotal: selAutovalutazione.rrTotale,
          livelloRischio: rischioResiduoLivelloS,
          note: selAutovalutazione.note,
          professionista,
        },
      },
    };

    const docResponse = await apiGetJsReportDocument.fetch({
      data: JSON.stringify(body),
    });
    download(
      atob(docResponse.data.data.getJsReportDocument.value),
      reportFileName + ".pdf"
    );

    setBackDropOpen(false);
  };

  //EuroConference
  const handleEuroConfClick = async (itemId) => {
    try {
      setBackDropOpen(true);

      const jsonToEnc = {
        id: itemId,
        search: "",
        userfullname: sharedDatas.getPropertyByName("userModel").username,
        context: "TS420",
        from: "TS Antiriciclaggio",
        userid: loggedUserId,
        teamsystemid: sharedDatas.getPropertyByName("userModel").email,
      };

      const cryptedContentResp = await tsApiAntiRic.fetch({
        url:
          "aml:ts-anti-ric:api://euroconf/getEncParam?valueToEnc=" +
          JSON.stringify(jsonToEnc),
      });

      window.open(
        "https://portale.ecevolution.it/ssoEvolution?encParam=" +
          cryptedContentResp.data,
        "_blank",
        "noopener,noreferrer"
      );
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AutovalutazioneAdd",
        method: "handleEuroConfClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  //#endregion

  //#region Return Graphics
  return (
    <Fragment>
      <VaporPage
        title={
          selAutovalutazione.id === 0
            ? t("autovalutazione.titoloAdd")
            : t("autovalutazione.titoloManage")
        }
        headerLeft={
          <Button
            variant='text'
            size='medium'
            color='primary'
            onClick={handleGoHome}
            sx={{ ml: 2 }}
          >
            {t("autovalutazione.autovalutazioneAdd.back")}
          </Button>
        }
      >
        <Loading open={backDropOpen} />

        {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

        {isDataLoaded && userPermissions.canRead ? (
          <VaporPage.Section>
            <Stack
              direction='row'
              spacing={2}
              divider={<Divider orientation='vertical' flexItem />}
            >
              {tenantTypeId === 1 ? (
                <Stack direction='column' spacing={1} sx={{ width: "80%" }}>
                  <ExtendedTypography variant='titleSmall' color='primary.main'>
                    {t("autovalutazione.autovalutazioneAdd.riscIn")}
                  </ExtendedTypography>

                  <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align='left'
                            width='25%'
                            sx={{ pl: "0 !important" }}
                          >
                            <ExtendedTypography
                              variant='subtitle2'
                              fontStyle={{ fontWeight: "bold" }}
                            >
                              {t("autovalutazione.form.fattoreRischio")}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='center' width='55%'>
                            <ExtendedTypography
                              variant='subtitle2'
                              fontStyle={{ fontWeight: "bold" }}
                            >
                              {t("autovalutazione.form.criteriValutazione")}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell
                            align='right'
                            width='15%'
                            sx={{ pr: "0 !important" }}
                          >
                            <ExtendedTypography
                              variant='subtitle2'
                              fontStyle={{ fontWeight: "bold" }}
                            >
                              {t("autovalutazione.form.indiceRischiosita")}
                            </ExtendedTypography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow hover>
                          <TableCell align='left' width='25%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.form.tabellaRischioInerente.ri1"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='left' width='55%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t("autovalutazione.autovalutazioneAdd.valInfo")}
                              <br />
                              <br />
                              {t("autovalutazione.autovalutazioneAdd.valList")}
                              <br />
                              <ul>
                                <li>
                                  {t(
                                    "autovalutazione.autovalutazioneAdd.valList1"
                                  )}
                                </li>
                                <li>
                                  {t(
                                    "autovalutazione.autovalutazioneAdd.valList2"
                                  )}
                                </li>
                                <li>
                                  {t(
                                    "autovalutazione.autovalutazioneAdd.valList3"
                                  )}
                                </li>
                                <li>
                                  {t(
                                    "autovalutazione.autovalutazioneAdd.valList4"
                                  )}
                                </li>
                              </ul>
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='right' width='15%'>
                            <Slider
                              id='sliderRi1'
                              name='sliderRi1'
                              step={1}
                              marks={sliderLivelli}
                              min={1}
                              max={4}
                              valueLabelDisplay='auto'
                              value={sliderVal.sliderRi1}
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align='left' width='25%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.form.tabellaRischioInerente.ri2"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='left' width='55%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t("autovalutazione.autovalutazioneAdd.geoList")}
                              <br />
                              <br />
                              {t(
                                "autovalutazione.autovalutazioneAdd.geoListDesc"
                              )}
                              <br />
                              <br />
                              {t("autovalutazione.autovalutazioneAdd.valList")}
                              <br />
                              <ul>
                                <li>
                                  {t(
                                    "autovalutazione.autovalutazioneAdd.geoList1"
                                  )}
                                </li>
                                <li>
                                  {t(
                                    "autovalutazione.autovalutazioneAdd.geoList2"
                                  )}
                                </li>
                                <li>
                                  {t(
                                    "autovalutazione.autovalutazioneAdd.geoList3"
                                  )}
                                </li>
                                <li>
                                  {t(
                                    "autovalutazione.autovalutazioneAdd.geoList4"
                                  )}
                                </li>
                              </ul>
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='right' width='15%'>
                            <Slider
                              id='sliderRi2'
                              name='sliderRi2'
                              step={1}
                              marks={sliderLivelli}
                              min={1}
                              max={4}
                              valueLabelDisplay='auto'
                              value={sliderVal.sliderRi2}
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align='left' width='25%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.form.tabellaRischioInerente.ri3"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='left' width='55%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.autovalutazioneAdd.appendice"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='right' width='15%'>
                            <Slider
                              id='sliderRi3'
                              name='sliderRi3'
                              step={1}
                              marks={sliderLivelli}
                              min={1}
                              max={4}
                              valueLabelDisplay='auto'
                              value={sliderVal.sliderRi3}
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align='left' width='25%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.form.tabellaRischioInerente.ri4"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='left' width='55%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t("autovalutazione.autovalutazioneAdd.riskList")}
                              <br />
                              <br />
                              {t("autovalutazione.autovalutazioneAdd.valList")}
                              <br />
                              <ul>
                                <li>
                                  {t(
                                    "autovalutazione.autovalutazioneAdd.riskList1"
                                  )}
                                </li>
                                <li>
                                  {t(
                                    "autovalutazione.autovalutazioneAdd.riskList2"
                                  )}
                                </li>
                                <li>
                                  {t(
                                    "autovalutazione.autovalutazioneAdd.riskList3"
                                  )}
                                </li>
                                <li>
                                  {t(
                                    "autovalutazione.autovalutazioneAdd.riskList4"
                                  )}
                                </li>
                              </ul>
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='right' width='15%'>
                            <Slider
                              id='sliderRi4'
                              name='sliderRi4'
                              step={1}
                              marks={sliderLivelli}
                              min={1}
                              max={4}
                              valueLabelDisplay='auto'
                              value={sliderVal.sliderRi4}
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <ExtendedTypography
                    variant='titleSmall'
                    color='primary.main'
                    sx={{ pt: 4 }}
                  >
                    {t("autovalutazione.autovalutazioneAdd.lvlVul")}
                  </ExtendedTypography>

                  <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align='left'
                            width='25%'
                            sx={{ pl: "0 !important" }}
                          >
                            <ExtendedTypography
                              variant='subtitle2'
                              fontStyle={{ fontWeight: "bold" }}
                            >
                              {t("autovalutazione.form.fattoreRischio")}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='center' width='55%'>
                            <ExtendedTypography
                              variant='subtitle2'
                              fontStyle={{ fontWeight: "bold" }}
                            >
                              {t("autovalutazione.form.criteriValutazione")}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell
                            align='right'
                            width='15%'
                            sx={{ pr: "0 !important" }}
                          >
                            <ExtendedTypography
                              variant='subtitle2'
                              fontStyle={{ fontWeight: "bold" }}
                            >
                              {t("autovalutazione.form.indiceRischiosita")}
                            </ExtendedTypography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow hover>
                          <TableCell align='left' width='25%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.form.tabellaLivelloVulnerabilita.lv1"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='left' width='55%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t("autovalutazione.autovalutazioneAdd.vulList1")}
                              <br />
                              <br />
                              {t("autovalutazione.autovalutazioneAdd.vulList2")}
                              <br />
                              <br />
                              {t("autovalutazione.autovalutazioneAdd.vulList3")}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='right' width='15%'>
                            <Slider
                              id='sliderLv1'
                              name='sliderLv1'
                              step={1}
                              marks={sliderLivelli}
                              min={1}
                              max={4}
                              valueLabelDisplay='auto'
                              value={sliderVal.sliderLv1}
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align='left' width='25%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.form.tabellaLivelloVulnerabilita.lv2"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='left' width='55%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.autovalutazioneAdd.idoneity1"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='right' width='15%'>
                            <Slider
                              id='sliderLv2'
                              name='sliderLv2'
                              step={1}
                              marks={sliderLivelli}
                              min={1}
                              max={4}
                              valueLabelDisplay='auto'
                              value={sliderVal.sliderLv2}
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align='left' width='25%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.form.tabellaLivelloVulnerabilita.lv3"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='left' width='55%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.autovalutazioneAdd.idoneity2"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='right' width='15%'>
                            <Slider
                              id='sliderLv3'
                              name='sliderLv3'
                              step={1}
                              marks={sliderLivelli}
                              min={1}
                              max={4}
                              valueLabelDisplay='auto'
                              value={sliderVal.sliderLv3}
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align='left' width='25%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.form.tabellaLivelloVulnerabilita.lv4"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='left' width='55%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.autovalutazioneAdd.idoneity3"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='right' width='15%'>
                            <Slider
                              id='sliderLv4'
                              name='sliderLv4'
                              step={1}
                              marks={sliderLivelli}
                              min={1}
                              max={4}
                              valueLabelDisplay='auto'
                              value={sliderVal.sliderLv4}
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <ExtendedTypography
                    variant='titleSmall'
                    color='primary.main'
                    sx={{ pt: 4 }}
                  >
                    {t("autovalutazione.autovalutazioneAdd.notes")}
                  </ExtendedTypography>
                  <TextField
                    id='txtNote'
                    label=''
                    size='small'
                    defaultValue={selAutovalutazione.note}
                    fullWidth
                    multiline
                    rows={3}
                    onChange={handleChange}
                    inputProps={{ autocomplete: "nope" }}
                  />
                </Stack>
              ) : null}

              {tenantTypeId === 2 ? (
                <Stack direction='column' spacing={1} sx={{ width: "80%" }}>
                  <ExtendedTypography variant='titleSmall' color='primary.main'>
                    {t("valutazione.form.rischioInerente")}
                  </ExtendedTypography>

                  <TableContainer>
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align='left'
                            width='85%'
                            sx={{ pl: "0 !important" }}
                          >
                            <ExtendedTypography
                              variant='subtitle2'
                              fontStyle={{ fontWeight: "bold" }}
                            >
                              {t("autovalutazione.form.fattoreRischio")}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell
                            align='right'
                            width='15%'
                            sx={{ pr: "0 !important" }}
                          >
                            <ExtendedTypography
                              variant='subtitle2'
                              fontStyle={{ fontWeight: "bold" }}
                            >
                              {t("autovalutazione.form.indiceRischiosita")}
                            </ExtendedTypography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow hover>
                          <TableCell
                            align='left'
                            width='85%'
                            sx={{ pl: "0 !important" }}
                          >
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.form.tabellaRischioInerente.ri1_1"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='right' width='15%'>
                            <Slider
                              id='sliderRi1'
                              name='sliderRi1'
                              step={1}
                              marks={sliderLivelli}
                              min={1}
                              max={4}
                              valueLabelDisplay='auto'
                              value={sliderVal.sliderRi1}
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell
                            align='left'
                            width='85%'
                            sx={{ pl: "0 !important" }}
                          >
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.form.tabellaRischioInerente.ri2_1"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='right' width='15%'>
                            <Slider
                              id='sliderRi2'
                              name='sliderRi2'
                              step={1}
                              marks={sliderLivelli}
                              min={1}
                              max={4}
                              valueLabelDisplay='auto'
                              value={sliderVal.sliderRi2}
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell
                            align='left'
                            width='85%'
                            sx={{ pl: "0 !important" }}
                          >
                            <ExtendedTypography variant='subtitle2'>
                              {t(
                                "autovalutazione.form.tabellaRischioInerente.ri3_1"
                              )}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='right' width='15%'>
                            <Slider
                              id='sliderRi3'
                              name='sliderRi3'
                              step={1}
                              marks={sliderLivelli}
                              min={1}
                              max={4}
                              valueLabelDisplay='auto'
                              value={sliderVal.sliderRi3}
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <ExtendedTypography
                    variant='titleSmall'
                    color='primary.main'
                    sx={{ pt: 4 }}
                  >
                    {t("autovalutazione.autovalutazioneAdd.notes")}
                  </ExtendedTypography>
                  <TextField
                    id='txtNote'
                    label=''
                    size='small'
                    defaultValue={selAutovalutazione.note}
                    fullWidth
                    multiline
                    rows={3}
                    onChange={handleChange}
                    inputProps={{ autocomplete: "nope" }}
                  />
                </Stack>
              ) : null}                                                                  

              {tenantTypeId === 3 ? (
                <Stack direction='column' spacing={1} sx={{width: "80%"}}>
                  <AutovalutazioneAvvocatoForm selAutovalutazioneId={selAutovalutazione.id} providedById={selAutovalutazione.providedBy} providedDateFromParent={selAutovalutazione.date} profList={utentiList}/>
                </Stack>
              ) : null }

              <Stack direction='column' spacing={2} sx={{ width: "20%" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={it}
                >
                  <DatePicker
                    label={t("autovalutazione.form.data")}
                    closeOnSelect
                    format='dd/MM/yyyy'
                    mask='__/__/____'
                    value={
                      isDateValid(selAutovalutazione.date)
                        ? new Date(selAutovalutazione.date)
                        : null
                    }
                    onChange={handleChangeDate}
                    slotProps={{
                      textField: {
                        placeholder: "dd/MM/yyyy",
                        size: "small",
                        fullWidth : true
                      },
                    }}
                  />
                </LocalizationProvider>

                <Divider variant='standard' />

                <Autocomplete
                  id='ddlUtenti'
                  key={uuidv4()}
                  size='small'
                  options={utentiList}
                  sx={{ width: "100%" }}
                  fullWidth
                  onChange={handleChange}
                  isOptionEqualToValue={(option, value) =>
                    option.userId === value.providedBy
                  }
                  getOptionLabel={(option) => option.completeName}
                  defaultValue={
                    selAutovalutazione.providedBy !== undefined
                      ? utentiList.filter(
                          (item) =>
                            item.userId === selAutovalutazione.providedBy
                        )[0]
                      : null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={t(
                        "autovalutazione.autovalutazioneAdd.elaborataDa"
                      )}
                      sx={{ lineHeight: 1 }}
                    />
                  )}
                />

                <Divider variant='standard' />

                {tenantTypeId !== 3 ? (
                <>
                  <Stack
                    direction='row'
                    spacing={1}
                    justifyContent='center'
                    alignItems='center'
                  >
                    <ExtendedTypography
                      variant='subtitle2'
                      fontStyle={{ fontWeight: "bold" }}
                    >
                      {t("autovalutazione.form.rischioInerente")}
                      <Button
                        variant='text'
                        size='small'
                        onClick={() => handleEuroConfClick(17257)}
                        sx={{
                          minWidth: "32px !important",
                          pl: "0px !important",
                          pr: "0px !important",
                          ml: 1,
                        }}
                      >
                        <img src='/img/EcLogo.png' width={20} alt="EcLogo"/>
                      </Button>
                    </ExtendedTypography>
                    <Box flexGrow={1} />
                    <ExtendedTypography variant='subtitle2'>
                      {totRischioInerente}
                    </ExtendedTypography>
                  </Stack>
                  <Divider variant='standard' />

                  {tenantTypeId !== 2 ? (
                    <Fragment>
                      <Stack direction='row' spacing={1}>
                        <ExtendedTypography
                          variant='subtitle2'
                          fontStyle={{ fontWeight: "bold" }}
                        >
                          {t("autovalutazione.form.livelloVulnerabilita")}
                          <Button
                            variant='text'
                            size='small'
                            onClick={() => handleEuroConfClick(17258)}
                            sx={{
                              minWidth: "32px !important",
                              pl: "0px !important",
                              pr: "0px !important",
                              ml: 1,
                            }}
                          >
                            <img src='/img/EcLogo.png' width={20} alt="EcLogo"/>
                          </Button>
                        </ExtendedTypography>
                        <Box flexGrow={1} />
                        <ExtendedTypography variant='subtitle2'>
                          {totLivelloVulnerabilita}
                        </ExtendedTypography>
                      </Stack>
                      <Divider variant='standard' />

                      <Stack direction='row' spacing={1}>
                        <ExtendedTypography
                          variant='subtitle2'
                          fontStyle={{ fontWeight: "bold" }}
                        >
                          {t("autovalutazione.form.rischioResiduo")}
                          <Button
                            variant='text'
                            size='small'
                            onClick={() => handleEuroConfClick(17261)}
                            sx={{
                              minWidth: "32px !important",
                              pl: "0px !important",
                              pr: "0px !important",
                              ml: 1,
                            }}
                          >
                            <img src='/img/EcLogo.png' width={20} alt="EcLogo"/>
                          </Button>
                        </ExtendedTypography>
                        <Box flexGrow={1} />
                        <ExtendedTypography variant='subtitle2'>
                          {totRischioResiduo.toFixed(2)}
                        </ExtendedTypography>
                      </Stack>
                    </Fragment>
                  ) : null}

                  <VaporTag
                    variant='standard'
                    label={rischioResiduoLivelloS}
                    sx={rischioResiduoLivelloC}
                    />
                </> ) : null}
              </Stack>  
            </Stack>
          </VaporPage.Section>
        ) : null}
      </VaporPage>

      {isDataLoaded && userPermissions.canCreate && tenantTypeId !== 3 ? (
        <VaporToolbar
          variant='regular'
          size='medium'
          withoutAppBar={false}
          contentRight={
            <Stack direction='row' spacing={2}>
              <Button
                variant='outlined'
                size='medium'
                endIcon={<PrintIcon />}
                onClick={handlePrintReportClick}
                disabled={
                  !userPermissions.canRead || selAutovalutazione.id <= 0
                }
              >
                {t("autovalutazione.autovalutazioneAdd.pntRep")}
              </Button>
              <Button
                variant='contained'
                size='medium'
                endIcon={<SaveIcon />}
                onClick={handleSaveAutovalutazione}
                disabled={!userPermissions.canUpdate}
              >
                {selAutovalutazione.id === 0
                  ? t("actions.salva")
                  : t("actions.aggiorna")}
              </Button>
            </Stack>
          }
        ></VaporToolbar>
      ) : null}
    </Fragment>
  );
  //#endregion
};
