//#region Imports
import React, { useState, useEffect, Fragment } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";

//oneFront SDK
import {
  useSnackbar,
  useAuth,
  useQuery,
  useMutation,
  useGet,
  usePost,
  useRequest,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";

//Vapor Components
import Stack from "@vapor/react-material/Stack";
import Divider from "@vapor/react-material/Divider";
import Button from "@vapor/react-material/Button";
import Box from "@vapor/react-material/Box";
import TextField from "@vapor/react-material/TextField";
import Autocomplete from "@vapor/react-material/Autocomplete";
import FormGroup from "@vapor/react-material/FormGroup";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import Switch from "@vapor/react-material/Switch";
import Grid from "@vapor/react-material/Grid";
import Modal from "@vapor/react-material/Modal";
import Checkbox from "@vapor/react-material/Checkbox";
import IconButton from "@vapor/react-material/IconButton";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import RadioGroup from "@vapor/react-material/RadioGroup";
import Radio from "@vapor/react-material/Radio";
import FormControl from "@vapor/react-material/FormControl";
import TableContainer from "@vapor/react-material/TableContainer";
import Table from "@vapor/react-material/Table";
import TableBody from "@vapor/react-material/TableBody";
import TableRow from "@vapor/react-material/TableRow";
import TableCell from "@vapor/react-material/TableCell";
import Paper from "@vapor/react-material/Paper";
import Menu from "@vapor/react-material/Menu";
import MenuItem from "@vapor/react-material/MenuItem";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";
import ExtendedAccordion from "@vapor/react-extended/ExtendedAccordion";

//Vapor Date Pickers
import { AdapterDateFns } from "@vapor/react-x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@vapor/react-x-date-pickers/LocalizationProvider";
import { DatePicker } from "@vapor/react-x-date-pickers/DatePicker";
import { it } from "date-fns/locale";

//Icons
import {
  Add as AddIcon,
  SaveOutlined as SaveIcon,
  CloseOutlined as CancelIcon,
  ModeEdit as ModeEditIcon,
  Delete as DeleteIcon,
  Person as PersonIcon,
  Business as BusinessIcon,
  ExpandMore as ExpandMoreIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Search as SearchIcon,
} from "@mui/icons-material";

//Custom components
import { Loading } from "../Loading";
import { NotAuth } from "../NotAuth";
import { NoData } from "../NoData";
import { ModalDelete } from "../ModalDelete";
import { ModalConfirmSave } from "../ModalConfirmSave";

//Constants, Api and Data Models
import { bsaAnagrafica } from "../../businessLogic/constants";
import {
  getDataFromFiscalCode,
  isPIvaValid,
  isFiscalCodeValid,
  isDateValid,
  isGuid,
  getLocalStorageSelectedEntity,
  removeLocalStorageSelectedEntity,
  isAFiscalCode,
} from "../../businessLogic/bl";
import {
  userPermissionModel,
  userModel,
  anagraficaModel,
  anagraficaFascicoloModel,
  anagraficaIdentificazioneModel,
  baseGenereModel,
  baseTipoAnagraficaModel,
  baseTipoDocumentoModel,
  baseTipoIdentificazioneModel,
  basePaeseModel,
  baseProvinciaModel,
  baseComuneModel,
  anagraficaBancaDatiModel,
  baseTipoPartecipazioneModel,
} from "../../businessLogic/models";

import {
  query_users_byTenantId,
  query_users_byCompanyId,
  query_base_all,
  query_base_comune,
  query_base_comune_byCodBelfiore,
  query_base_paese_byCodAt,
  query_anagrafiche_byFascicolo,
  query_anagrafiche_bancaDati_byAnag,
  query_anagrafiche_byCf,
  query_anagrafiche_byCf_PG,
  query_prestazioni_byFascicolo,
  query_rischi_byPrestazione_count,
  query_anagrafiche_rel_fascicolo,
  query_anagrafiche_byId,
  query_anagrafiche_byFiscalCode,
} from "../../businessLogic/query";

import {
  mutation_add_log,
  mutation_add_audit,
  mutation_add_anagrafica,
  mutation_update_anagrafica,
  mutation_delete_anagrafica_version,
  mutation_add_anagrafica_fascicolo,
  mutation_update_anagrafica_fascicolo,
  mutation_delete_anagrafica_fascicolo,
  mutation_add_anagrafica_ident,
  mutation_update_anagrafica_ident,
  mutation_add_anagrafica_bancadati,
  mutation_update_fascicolo,
  mutation_delete_fascicolo,
  mutation_update_anagrafica_wsId,
} from "../../businessLogic/mutation";

//Other components
import { v4 as uuidv4 } from "uuid";
import postal from "postal";
import { useNavigate } from "react-router-dom";
import { ModalSearchResultList } from "../ModalSearchResultList";
//#endregion

export const AnagraficaForm = ({ fascicolo, reloadStatusFascicolo }) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const version = sharedDatas.getPropertyByName("version");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const anagFromNavigation = getLocalStorageSelectedEntity();
  const navigate = useNavigate();

  //#region Query
  const apiUsersByTenantId = useQuery(
    query_users_byTenantId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUsersByCompanyId = useQuery(
    query_users_byCompanyId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseAll = useQuery(
    query_base_all,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseComune = useQuery(
    query_base_comune,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseComuneByCodBelfiore = useQuery(
    query_base_comune_byCodBelfiore,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBasePaeseByCodAt = useQuery(
    query_base_paese_byCodAt,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagraficaByFascicolo = useQuery(
    query_anagrafiche_byFascicolo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagraficheById = useQuery(
    query_anagrafiche_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagraficaRelFascicolo = useQuery(
    query_anagrafiche_rel_fascicolo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagraficaBancaDati = useQuery(
    query_anagrafiche_bancaDati_byAnag,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagraficheByCf = useQuery(
    query_anagrafiche_byCf,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagByFiscalCode = useQuery(
    query_anagrafiche_byFiscalCode,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagraficheByCfPG = useQuery(
    query_anagrafiche_byCf_PG,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiPrestazioniByFascicolo = useQuery(
    query_prestazioni_byFascicolo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiCountRischiByPrestazione = useQuery(
    query_rischi_byPrestazione_count,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  //#endregion

  //#region Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAnagrafica = useMutation(mutation_add_anagrafica, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAnagraficaIdent = useMutation(mutation_add_anagrafica_ident, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateAnagrafica = useMutation(mutation_update_anagrafica, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateAnagraficaIdent = useMutation(
    mutation_update_anagrafica_ident,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiDeleteAnagraficaVersion = useMutation(
    mutation_delete_anagrafica_version,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAddAnagraficaFascicolo = useMutation(
    mutation_add_anagrafica_fascicolo,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdateAnagraficaFascicolo = useMutation(
    mutation_update_anagrafica_fascicolo,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiDeleteAnagraficaFascicolo = useMutation(
    mutation_delete_anagrafica_fascicolo,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiDeleteFascicolo = useMutation(mutation_delete_fascicolo, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAnagraficaBancaDati = useMutation(
    mutation_add_anagrafica_bancadati,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdateFascicolo = useMutation(mutation_update_fascicolo, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateWsId = useMutation(mutation_update_anagrafica_wsId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  //#endregion

  //#region useState

  //TSDIGITAL
  const [accessToken] = useState(useAuth().token);
  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaAnagrafica,
      false,
      false,
      false,
      false
    )
  );

  //Professionisti
  const [allUtentiList, setAllUtentiList] = useState([]);
  const [utentiList, setUtentiList] = useState([]);

  //Anagrafica
  const [currentAnagraficheList, setCurrentAnagraficheList] = useState([]); //Contiene le anagrafiche con l'ultima versione
  const [currentAnagraficheHistoryList, setCurrentAnagraficheHistoryList] =
    useState([]); //Contiene tutte le anagrafiche con tutte le versioni
  const [
    currentAnagraficheHistoryFilteredList,
    setCurrentAnagraficheHistoryFilteredList,
  ] = useState([]); //Contiene tutte le anagrafiche filtrate
  const [currentAnagraficheClientiList, setCurrentAnagraficheClientiList] =
    useState([]); //Contiene le anagrafiche clienti correnti
  const [currentAnagraficheCollegateList, setCurrentAnagraficheCollegateList] =
    useState([]); //Contiene le anagrafiche clienti correnti
  const [currentAnagraficheTitEffList, setCurrentAnagraficheTitEffList] =
    useState([]); //Contiene le anagrafiche clienti correnti
  const [currentAnagrafica, setCurrentAnagrafica] = useState(
    new anagraficaModel(
      0,
      0,
      false,
      "",
      "",
      "",
      0,
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      undefined,
      undefined,
      "",
      undefined,
      "",
      "",
      "",
      "",
      undefined,
      undefined,
      "",
      undefined,
      "",
      "",
      "",
      "",
      false,
      false,
      1,
      new Date(),
      false,
      [],
      [],
      "",
      loggedUserId
    )
  ); //Contiene l'anagrafica corrente
  const [currentAnagraficaOriginal, setCurrentAnagraficaOriginal] = useState(
    new anagraficaModel(
      0,
      0,
      false,
      "",
      "",
      "",
      0,
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      undefined,
      undefined,
      "",
      undefined,
      "",
      "",
      "",
      "",
      undefined,
      undefined,
      "",
      undefined,
      "",
      "",
      "",
      "",
      false,
      false,
      1,
      new Date(),
      false,
      [],
      [],
      "",
      loggedUserId
    )
  ); //Used for Audit
  const [currentAnagraficaIdent, setCurrentAnagraficaIdent] = useState(
    new anagraficaIdentificazioneModel(
      0,
      0,
      0,
      "",
      "",
      null,
      null,
      "",
      "",
      "",
      new Date(),
      null,
      null,
      loggedUserId
    )
  );
  const [anagraficheBancaDatiList, setAnagraficheBancaDatiList] = useState([]); //Contiene i risultati delle banche dati di tutte le anagrafiche
  const [currentAnagraficaNewVer, setCurrentAnagraficaNewVer] = useState(
    new anagraficaModel(
      0,
      0,
      false,
      "",
      "",
      "",
      0,
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      undefined,
      undefined,
      "",
      undefined,
      "",
      "",
      "",
      "",
      undefined,
      undefined,
      "",
      undefined,
      "",
      "",
      "",
      "",
      false,
      false,
      1,
      new Date(),
      false,
      [],
      [],
      "",
      loggedUserId
    )
  ); //Contiene l'anagrafica corrente
  const [currentAnagraficaFascicolo, setCurrentAnagraficaFascicolo] = useState(
    new anagraficaFascicoloModel(0, 0, 0, 0, 0, "", null, 0, "")
  );

  //Tabelle di base
  const [baseTipoAnagList, setBaseTipoAnagList] = useState([]); //base_tipo_anagrafica
  const [baseGenereList, setBaseGenereList] = useState([]); //base_genere
  const [baseTipoDocList, setBaseTipoDocList] = useState([]); //base_tipo_documento
  const [baseTipoIdentList, setBaseTipoIdentList] = useState([]); //base_tipo_identificazione
  const [basePaeseList, setBasePaeseList] = useState([]); //base_paese
  const [baseProvinciaList, setBaseProvinciaList] = useState([]); //base_provincia
  const [baseComuneList, setBaseComuneList] = useState([]); //base_comune
  const [baseComuneList2, setBaseComuneList2] = useState([]); //base_comune
  const [baseTipoPartList, setBaseTipoPartList] = useState([]); //base_tipo_partecipazione
  const [baseTipoRappList, setBaseTipoRappList] = useState([]); //base_tipo_rappresentanza
  const [basePoliticalRole, setBasePoliticalRole] = useState([]); //base ruoli politici (ppe)

  //Valori form
  const [tipoAnag, setTipoAnag] = useState("cliente");
  const [isPG, setIsPG] = useState(false);
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [isAddrPaeseIta, setIsAddrPaeseIta] = useState(true);
  const [isAddr2PaeseIta, setIsAddr2PaeseIta] = useState(true);
  const [, setIsModIdentDiretta] = useState(false);
  const [anagPep, setAnagPep] = useState({
    IsNotPep: true,
    IsPep: false,
    IsNotPepMoreThanYear: false,
  });
  const [showDocTipoAp, setShowDoctipoAp] = useState(false);
  const [showDocTipoDetails, setShowDocTipoDetails] = useState(false);
  const [enableFormControls, setEnableFormControls] = useState(true);
  const [isUpdateEnable, setIsUpdateEnable] = useState(true);
  const [showTipoPartDesc, setShowTipoPartDesc] = useState(false);
  const [enableTipoRappDesc, setEnableTipoRappDesc] = useState(
    currentAnagraficaFascicolo.tipoRappId === 2
  );
  const [, setIsDataFascicoloEnabled] = useState(true);
  const [isUserAllowedToPrivate, setIsUserAllowedToPrivate] = useState(false);
  const [isCessato, setIsCessato] = useState(false); //Identifica se l'anagrafica è cessata
  const [showDeleteModal, setShowDeleteModal] = useState(false); //Modal per la conferma di eliminazione di un record
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false); //Modal per la conferma di salvataggio standard nel caso i dati anagrafici siano sballati con quelli ricavati dal codice fiscale
  const [showSaveConfirmModalVer, setShowSaveConfirmModalVer] = useState(false); //Modal per la conferma di salvataggio versione nel caso i dati anagrafici siano sballati con quelli ricavati dal codice fiscale
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showIdentModal, setShowIdentModal] = useState(false);
  const [showResultListModal, setShowResultListModal] = useState(false);
  const [resultList, setResultList] = useState([]);
  const [idToPerform, setIdToPerform] = useState(0); //Contiene l'id del record da visualizzare
  const [typeToPerform, setTypeToPerform] = useState("P"); //Contiene il tipo di record da eliminare. A: Anagrafica, ALL: Allegato
  const [anagFascToPerform, setAnagFascToPerform] = useState(null);
  const [lastSelectedAnag, setLastSelectedAnag] = useState(null);
  const [showAnagForm, setShowAnagForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  //#endregion

  //#region EndPoints
  const tsApiWorkspace = usePost(
    "aml:workspace-write:api://",
    {},
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "X-App-Name": "TS420",
        "X-App-Version": "1.0.0",
        "X-Request-ID": uuidv4(),
        "X-Correlation-ID": uuidv4(),
        "Content-Type": "application/json",
        "X-Item-ID": tenantId,
        "X-User-ID": loggedUserId,
      },
    }
  );
  const tsApiWorkspaceUpdate = useRequest("aml:workspace-write:api://");
  const tsApiWorkspaceRead = usePost("aml:workspace-read:api://search", {
    lazy: true,
  });
  const tsApiAntiRic = useGet("aml:ts-anti-ric:api://", { lazy: true });
  //#endregion

  useEffect(() => {
    loadDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);

  //#region Logs
  const AddLog = (message, stack, method) => {
    apiAddLog.fetch({
      tenantId: tenantId,
      aziendaId: aziendaId,
      level: "ERROR",
      message: message,
      stack: stack,
      area: "AnagraficaForm",
      method: method,
      version: version,
    });
  };
  //#endregion

  //#region Load Dati
  async function loadDatas() {
    try {
      setBackDropOpen(true);

      if (!isDataLoaded) {
        /**************************************/
        //Carico i permessi relativi all'utente
        setUserPermissions(
          sharedDatas
            .getPropertyByName("userPermissions")
            .filter((x) => x.area === bsaAnagrafica)[0]
        );

        /**************************************/
        //Carico gli utenti
        const allUsersResponse = await apiUsersByTenantId.fetch({
          tenantId: tenantId,
        });
        const arrAllUsers = [];
        allUsersResponse.data.data.user_tenant.map((item) =>
          arrAllUsers.push(
            new userModel(
              item.UserId,
              item.TenantId,
              item.user.Username,
              item.user.Email,
              item.user.Name,
              item.user.Surname,
              item.user.Name + " " + item.user.Surname,
              item.Type,
              item.user.Locale,
              item.TenantTipoId,
              item.user.IsRespAntiRic,
              item.base_tipo_tenant.Value,
              item.user.IsActive,
              item.user.IsDeleted,
              item.user.IsBanned,
              item.user.CreatedOn,
              item.user.Cf
            )
          )
        );
        setAllUtentiList(arrAllUsers);

        const usersResponse = await apiUsersByCompanyId.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
        });
        const arrUsers = [];
        usersResponse.data.data.user_tenant.map((item) =>
          arrUsers.push(
            new userModel(
              item.UserId,
              item.TenantId,
              item.user.Username,
              item.user.Email,
              item.user.Name,
              item.user.Surname,
              item.user.Name + " " + item.user.Surname,
              item.Type,
              item.user.Locale,
              item.TenantTipoId,
              item.user.IsRespAntiRic,
              item.base_tipo_tenant.Value,
              item.user.IsActive,
              item.user.IsDeleted,
              item.user.IsBanned,
              item.user.CreatedOn,
              item.user.Cf
            )
          )
        );

        setUtentiList(arrUsers);
        /**************************************/
        //Carico le tabelle di base
        const tbBaseAllResponse = await apiBaseAll.fetch({
          tenantId: tenantId,
        });

        /*base_tipo_anagrafica*/
        const arrTipoAnagItems = [];
        tbBaseAllResponse.data.data.base_tipo_anagrafica.map((item) =>
          arrTipoAnagItems.push(
            new baseTipoAnagraficaModel(
              item.Id,
              item.Key,
              item.Value,
              item.Order,
              item.IsSystem
            )
          )
        );
        setBaseTipoAnagList(arrTipoAnagItems);

        /*base_genere*/
        const arrGenereItems = [];
        tbBaseAllResponse.data.data.base_genere.map((item) =>
          arrGenereItems.push(
            new baseGenereModel(
              item.Id,
              item.Key,
              item.Value,
              item.Order,
              item.IsSystem
            )
          )
        );
        setBaseGenereList(arrGenereItems);

        /*base_tipo_identificazione*/
        const arrtipoIdenItems = [];
        tbBaseAllResponse.data.data.base_tipo_identificazione.map((item) =>
          arrtipoIdenItems.push(
            new baseTipoIdentificazioneModel(
              item.Id,
              item.Key,
              item.Value,
              item.Order,
              item.IsSystem
            )
          )
        );
        setBaseTipoIdentList(arrtipoIdenItems);

        /*base_tipo_documento*/
        const arrTipoDocItems = [];
        tbBaseAllResponse.data.data.base_tipo_documento.map((item) =>
          arrTipoDocItems.push(
            new baseTipoDocumentoModel(
              item.Id,
              item.Key,
              item.Value,
              item.Order,
              item.IsSystem,
              item.PersonType,
              item.DocumentTypeId,
              item.ValidityMonths,
              item.ValidityOffsetDays,
              item.NextBirthDay
            )
          )
        );
        setBaseTipoDocList(arrTipoDocItems);

        /*base_paese*/
        const arrPaeseItems = [];
        tbBaseAllResponse.data.data.base_paese.map((item) =>
          arrPaeseItems.push(
            new basePaeseModel(
              item.Id,
              item.Nome,
              item.Identificativo,
              item.IsRischio
            )
          )
        );
        setBasePaeseList(arrPaeseItems);

        /*base_provincia*/
        const arrProvItems = [];
        tbBaseAllResponse.data.data.base_provincia.map((item) =>
          arrProvItems.push(
            new baseProvinciaModel(item.Id, item.Nome, item.Sigla, item.Rischio)
          )
        );
        setBaseProvinciaList(arrProvItems);

        /*base_tipo_partecipazione*/
        const arrTipoPartItems = [];
        tbBaseAllResponse.data.data.base_tipo_partecipazione.map((item) =>
          arrTipoPartItems.push(
            new baseTipoPartecipazioneModel(
              item.Id,
              item.Key,
              item.Nome,
              item.Descrizione
            )
          )
        );
        setBaseTipoPartList(arrTipoPartItems);

        /*base_tipo_rappresentanza*/
        let arrRapps = [
          { id: 0, name: "Mandato con rappresentanza" },
          { id: 1, name: "Mandato senza rappresentanza" },
          { id: 2, name: "Altro" },
        ];
        setBaseTipoRappList(arrRapps);

        /*base_tipo_ruolo_politico_ricoperto*/
        let arrPoliticalRole = [
          "PRESIDENTE DELLA REPUBBLICA",
          "PRIMO MINISTRO",
          "MINISTRO",
          "VICE MINISTRO",
          "SOTTOSEGRETARIO",
          "PRESIDENTE DELLA REGIONE",
          "CONSIGLIERE REGIONALE",
          "PRESIDENTE DELLA PROVINCIA",
          "SINDACO",
          "DEPUTATO",
          "SENATORE",
          "PARLAMENTARE",
          "PARLAMENTARE EUROPEO",
          "CONSIGLIERE REGIONALE",
          "UFFICIO DEGLI ESTERI",
        ];
        setBasePoliticalRole(arrPoliticalRole);
        /**************************************/
        //Carico le anagrafiche
        if (fascicolo.id > 0) {
          const anagResponse = await apiAnagraficaByFascicolo.fetch({
            fascicoloId: fascicolo.id,
          });
          if (anagResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const arrItems = [];
            const arrItemsHistory = [];
            const arrItemsClienti = [];
            const arrItemsCollegate = [];
            const arrItemsTitEff = [];

            //Questa chiamata restituisce l'elenco di tutte le anagrafiche incluse nel fascicolo
            //Ogni anagrafica è versionata, ed ogni anagrafica può contenere più versioni
            //Dato che dobbiamo visualizzare solo l'ultima versione per ogni anagrafica dobbiamo fare un pò di filtri
            const cfArr = [];
            const anagFascArr = [];
            var bancheDati = [];
            let countIdent = 0;

            for (let i = 0; i < anagResponse.data.data.anagrafica.length; i++) {
              const item = anagResponse.data.data.anagrafica[i];
              let itemModel = new anagraficaModel(
                item.Id,
                item.ParentId,
                item.IsPg,
                item.Cf,
                item.Nome,
                item.Cognome,
                item.GenereId,
                item.NascitaLuogo,
                item.NascitaData,
                item.Professione,
                item.Denominazione,
                item.PIva,
                item.ReaComune,
                item.ReaN,
                item.AddrPaeseId,
                item.AddrProvinciaId,
                item.AddrProvincia,
                item.AddrComuneId,
                item.AddrComune,
                item.AddrIndirizzo,
                item.AddrN,
                item.AddrCap,
                item.Addr2PaeseId,
                item.Addr2ProvinciaId,
                item.Addr2Provincia,
                item.Addr2ComuneId,
                item.Addr2Comune,
                item.Addr2Indirizzo,
                item.Addr2N,
                item.Addr2Cap,
                item.IsPep,
                item.IsNotPepMoreThanYear,
                item.VersionN,
                item.VersionDate,
                item.IsDeleted,
                [],
                [],
                item.PepCarica,
                item.PepRuolo,
                item.CreatedBy,
                item.ModifiedBy,
                item.anagrafica_fascicolos,
                item.WorkspaceId,
                item.AmlId,
                item.Office1Id,
                item.Office2Id
              );

              //Fascicoli
              const arrItemsFasc = [];
              for (let m = 0; m < item.anagrafica_fascicolos.length; m++) {
                const itemFasc = item.anagrafica_fascicolos[m];
                arrItemsFasc.push(
                  new anagraficaFascicoloModel(
                    itemFasc.Id,
                    item.Id,
                    fascicolo.id,
                    itemFasc.base_tipo_anagrafica.Id,
                    itemFasc.TipoPartId,
                    itemFasc.TipoPartDesc,
                    itemFasc.DataFine,
                    itemFasc.TipoRappId,
                    itemFasc.TipoRappDesc
                  )
                );
              }
              itemModel.anagFascicoli = arrItemsFasc;

              //Identificazioni
              const arrItemsIdent = [];
              for (
                let i = 0;
                i < item.anagrafica_identificaziones.length;
                i++
              ) {
                const itemI = item.anagrafica_identificaziones[i];
                const anagIdentModel = new anagraficaIdentificazioneModel(
                  itemI.Id,
                  itemI.base_tipo_identificazione.Id,
                  itemI.DocTipoId,
                  itemI.DocN,
                  itemI.DocRilDa,
                  itemI.DocRilData,
                  itemI.DocScad,
                  itemI.ApNome,
                  itemI.ApCf,
                  itemI.IdentifiedByUser,
                  itemI.IdentifiedOn,
                  itemI.CreatedOn,
                  itemI.ModifiedOn,
                  itemI.CreatedBy,
                  itemI.ModifiedBy
                );
                arrItemsIdent.push(anagIdentModel);
                countIdent = countIdent + 1;

                //Se l'utente corrente è presente può rendere il fascicolo privato
                if (
                  !isUserAllowedToPrivate &&
                  itemI.IdentifiedByUser === loggedUserId
                ) {
                  setIsUserAllowedToPrivate(true);
                }
              }

              itemModel.identificazioni = arrItemsIdent;
              arrItemsHistory.push(itemModel);
              arrItems.push(itemModel);

              //Banche dati
              //Recupero i risultati delle bancahe dati consultati
              const bancaDatiAnagResponse = await apiAnagraficaBancaDati.fetch({
                anagraficaId: itemModel.id,
              });
              bancaDatiAnagResponse.data.data.anagrafica_bancadati.forEach(
                (bancaDati) => {
                  bancheDati.push(
                    new anagraficaBancaDatiModel(
                      bancaDati.Id,
                      itemModel.id,
                      bancaDati.Repository,
                      bancaDati.Infos,
                      bancaDati.CreatedOn,
                      bancaDati.CreatedBy
                    )
                  );
                }
              );

              for (let n = 0; n < item.anagrafica_fascicolos.length; n++) {
                const anagFasc = item.anagrafica_fascicolos[n];

                if (
                  cfArr.filter((x) => x.cf === item.Cf).length <= 0 ||
                  (cfArr.filter((x) => x.cf === item.Cf).length > 0 &&
                    anagFascArr.filter(
                      (x) =>
                        x.TipoAnagId === anagFasc.TipoAnagId &&
                        x.AnagraficaId === anagFasc.AnagraficaId
                    ).length <= 0)
                ) {
                  const tipoAnagKey = arrTipoAnagItems.filter(
                    (bta) => bta.id === anagFasc.base_tipo_anagrafica.Id
                  )[0].key;
                  if (
                    tipoAnagKey === "tipo_anagrafica_1" ||
                    tipoAnagKey === "tipo_anagrafica_2"
                  ) {
                    const newItemModel = Object.assign({}, itemModel);
                    const newAnagFasc = [];
                    newAnagFasc.push(
                      new anagraficaFascicoloModel(
                        anagFasc.Id,
                        item.Id,
                        fascicolo.id,
                        anagFasc.base_tipo_anagrafica.Id,
                        anagFasc.TipoPartId,
                        anagFasc.TipoPartDesc,
                        anagFasc.DataFine,
                        anagFasc.TipoRappId,
                        anagFasc.TipoRappDesc
                      )
                    );
                    newItemModel.anagFascicoli = newAnagFasc;
                    if (
                      arrItemsClienti.length === 0 ||
                      arrItemsClienti.filter((x) => x.cf === newItemModel.cf)
                        .length <= 0
                    ) {
                      arrItemsClienti.push(newItemModel);
                    }
                  }

                  //Legali rappresentanti e Procuratori delegati
                  else if (
                    tipoAnagKey === "tipo_anagrafica_3" ||
                    tipoAnagKey === "tipo_anagrafica_4"
                  ) {
                    const newItemModel = Object.assign({}, itemModel);
                    const newAnagFasc = [];
                    newAnagFasc.push(
                      new anagraficaFascicoloModel(
                        anagFasc.Id,
                        item.Id,
                        fascicolo.id,
                        anagFasc.base_tipo_anagrafica.Id,
                        anagFasc.TipoPartId,
                        anagFasc.TipoPartDesc,
                        anagFasc.DataFine,
                        anagFasc.TipoRappId,
                        anagFasc.TipoRappDesc
                      )
                    );
                    newItemModel.anagFascicoli = newAnagFasc;
                    if (
                      arrItemsCollegate.length === 0 ||
                      arrItemsCollegate.filter((x) => x.cf === newItemModel.cf)
                        .length <= 0
                    ) {
                      arrItemsCollegate.push(newItemModel);
                    }
                  }

                  //Titolari effettivi
                  else if (tipoAnagKey === "tipo_anagrafica_5") {
                    const newItemModel = Object.assign({}, itemModel);
                    const newAnagFasc = [];
                    newAnagFasc.push(
                      new anagraficaFascicoloModel(
                        anagFasc.Id,
                        item.Id,
                        fascicolo.id,
                        anagFasc.base_tipo_anagrafica.Id,
                        anagFasc.TipoPartId,
                        anagFasc.TipoPartDesc,
                        anagFasc.DataFine,
                        anagFasc.TipoRappId,
                        anagFasc.TipoRappDesc
                      )
                    );
                    newItemModel.anagFascicoli = newAnagFasc;
                    if (
                      arrItemsTitEff.length === 0 ||
                      arrItemsTitEff.filter((x) => x.cf === newItemModel.cf)
                        .length <= 0
                    ) {
                      arrItemsTitEff.push(newItemModel);
                    }
                  }

                  cfArr.push(itemModel);
                  anagFascArr.push(anagFasc);
                }
              }
            }

            setCurrentAnagraficheList(arrItems);
            setCurrentAnagraficheHistoryList(arrItemsHistory);
            setCurrentAnagraficheClientiList(arrItemsClienti);
            setCurrentAnagraficheCollegateList(arrItemsCollegate);
            setCurrentAnagraficheTitEffList(arrItemsTitEff);
            setAnagraficheBancaDatiList(bancheDati);

            const anagFromNavigationResult = await anagFromNavigation;
            let anagFromNavigationResultJson = null;
            if (anagFromNavigationResult !== null) {
              anagFromNavigationResultJson = JSON.parse(
                anagFromNavigationResult
              );
            }

            if (lastSelectedAnag === null) {
              if (
                anagFromNavigationResultJson !== null &&
                anagFromNavigationResultJson.entityType === "anagrafica"
              ) {
                const itemToSelect =
                  arrItems.filter(
                    (x) => x.id === anagFromNavigationResultJson.entityId
                  ).length > 0
                    ? arrItems.filter(
                        (x) => x.id === anagFromNavigationResultJson.entityId
                      )[0]
                    : null;

                if (itemToSelect !== null) {
                  var historyItems = arrItemsHistory
                    .filter(
                      (x) =>
                        x.id === itemToSelect.id ||
                        x.id === itemToSelect.parentId ||
                        x.parentId === itemToSelect.parentId
                    )
                    .sort((a, b) => b.versionN - a.versionN);
                  if (itemToSelect.parentId === 0) {
                    historyItems = arrItemsHistory
                      .filter((x) => x.id === itemToSelect.id)
                      .sort((a, b) => b.versionN - a.versionN);
                  }

                  const anagFascArr = [];
                  anagFascArr.push(itemToSelect.anagFascicoli[0]);
                  itemToSelect.anagFascicoli = anagFascArr;

                  await removeLocalStorageSelectedEntity();
                  setLastSelectedAnag(itemToSelect);
                  await selectAnagrafica(
                    itemToSelect,
                    arrPaeseItems,
                    arrProvItems,
                    arrTipoAnagItems,
                    arrtipoIdenItems,
                    arrTipoDocItems,
                    arrTipoPartItems,
                    historyItems,
                    false
                  );
                }
              } else {
                //Seleziono di default la prima anagrafica cliente
                if (arrItemsClienti.length > 0) {
                  setLastSelectedAnag(arrItemsClienti[0]);
                  historyItems = arrItemsHistory
                    .filter(
                      (x) =>
                        x.id === arrItemsClienti[0].id ||
                        x.id === arrItemsClienti[0].parentId ||
                        x.parentId === arrItemsClienti[0].parentId
                    )
                    .sort((a, b) => b.versionN - a.versionN);
                  if (arrItemsClienti[0].parentId === 0) {
                    historyItems = arrItemsHistory
                      .filter((x) => x.id === arrItemsClienti[0].id)
                      .sort((a, b) => b.versionN - a.versionN);
                  }
                  historyItems = arrItemsHistory.filter(
                    (x) => x.cf === arrItemsClienti[0].cf
                  );

                  await selectAnagrafica(
                    arrItemsClienti[0],
                    arrPaeseItems,
                    arrProvItems,
                    arrTipoAnagItems,
                    arrtipoIdenItems,
                    arrTipoDocItems,
                    arrTipoPartItems,
                    historyItems,
                    false
                  );
                } else {
                  const newItem = await emptyAnagraficaModel(arrPaeseItems);
                  setCurrentAnagrafica(newItem);
                  setCurrentAnagraficaOriginal(newItem);
                  setCurrentAnagraficaFascicolo(
                    emptyAnagraficaFascicoloModel()
                  );
                }
              }
            } else {
              historyItems = arrItemsHistory
                .filter(
                  (x) =>
                    x.id === lastSelectedAnag.id ||
                    x.id === lastSelectedAnag.parentId ||
                    x.parentId === lastSelectedAnag.parentId
                )
                .sort((a, b) => b.versionN - a.versionN);
              if (lastSelectedAnag.parentId === 0) {
                historyItems = arrItemsHistory
                  .filter((x) => x.id === lastSelectedAnag.id)
                  .sort((a, b) => b.versionN - a.versionN);
              }

              await selectAnagrafica(
                lastSelectedAnag,
                arrPaeseItems,
                arrProvItems,
                arrTipoAnagItems,
                arrtipoIdenItems,
                arrTipoDocItems,
                arrTipoPartItems,
                historyItems,
                false
              );
            }

            //Abilito o meno la selezione della data relativa al fascicolo
            const prestByFascicolo = await apiPrestazioniByFascicolo.fetch({
              fascicoloId: fascicolo.id,
            });
            const countPrest = prestByFascicolo.data.data.prestazione.length;
            let countRischi = 0;
            if (countPrest > 0) {
              for (
                let i = 0;
                i < prestByFascicolo.data.data.prestazione.length;
                i++
              ) {
                const itemP = prestByFascicolo.data.data.prestazione[i];
                const countRischiByPrest =
                  await apiCountRischiByPrestazione.fetch({
                    tenantId: tenantId,
                    aziendaId: aziendaId,
                    prestazioneId: itemP.Id,
                  });
                countRischi =
                  countRischi +
                  countRischiByPrest.data.data.rischio_aggregate.aggregate
                    .count;

                //Se l'utente corrente è presente può rendere il fascicolo privato
                if (
                  !isUserAllowedToPrivate &&
                  itemP.prestazione_users.filter(
                    (x) => x.UserId === loggedUserId
                  ).length > 0
                ) {
                  setIsUserAllowedToPrivate(true);
                }
              }
            }

            setIsDataFascicoloEnabled(
              countIdent > 0 || countPrest > 0 || countRischi > 0
            );

            //Invio una notifica tramite pub/sub interno
            let distinctAnagList = [];
            for (let i = 0; i < arrItems.length; i++) {
              if (
                distinctAnagList.filter((x) => x.cf === arrItems[i].cf)
                  .length <= 0
              ) {
                distinctAnagList.push(arrItems[i]);
              }
            }

            postal.publish({
              channel: "fascicolo",
              topic: "anagrafica.list",
              data: {
                anagrafiche: distinctAnagList,
                bancheDati: bancheDati,
              },
            });

            if (anagResponse.data.data.anagrafica.length <= 0) {
              enqueueSnackbar(
                "Ops... questo fascicolo non ha un' anagrafica cliente, procedi all'eliminazione",
                { variant: "warning" }
              );
              setTypeToPerform("F");
              setIdToPerform(fascicolo.id);
              setShowDeleteModal(true);
            } else {
              for (
                let i = 0;
                i < anagResponse.data.data.anagrafica.length;
                i++
              ) {
                const anag = anagResponse.data.data.anagrafica[i];
                if (!isGuid(anag.WorkspaceId)) {
                  try {
                    const searchApiWorkSpaceResp =
                      await tsApiWorkspaceRead.fetch({
                        headers: {
                          Authorization: "Bearer " + accessToken,
                          "X-App-Name": "TS420",
                          "X-App-Version": "1.0.0",
                          "X-Request-ID": uuidv4(),
                          "X-Correlation-ID": uuidv4(),
                          "Content-Type": "application/json",
                          "X-Item-ID": tenantId,
                          "X-User-ID": loggedUserId,
                        },
                        data: {
                          ownerId: tenantId,
                          identifier: anag.Cf,
                          status: "ACTIVE",
                        },
                      });

                    if (searchApiWorkSpaceResp.data.content.length === 1) {
                      anag.WorkspaceId =
                        searchApiWorkSpaceResp.data.content[0].id;
                    } else if (
                      searchApiWorkSpaceResp.data.content.length === 0
                    ) {
                      let anagToSave = new anagraficaModel(
                        anag.Id,
                        anag.ParentId,
                        anag.IsPg,
                        anag.Cf,
                        anag.Nome,
                        anag.Cognome,
                        anag.GenereId,
                        anag.NascitaLuogo,
                        anag.NascitaData,
                        anag.Professione,
                        anag.Denominazione,
                        anag.PIva,
                        anag.ReaComune,
                        anag.ReaN,
                        anag.AddrPaeseId,
                        anag.AddrProvinciaId,
                        anag.AddrProvincia,
                        anag.AddrComuneId,
                        anag.AddrComune,
                        anag.AddrIndirizzo,
                        anag.AddrN,
                        anag.AddrCap,
                        anag.Addr2PaeseId,
                        anag.Addr2ProvinciaId,
                        anag.Addr2Provincia,
                        anag.Addr2ComuneId,
                        anag.Addr2Comune,
                        anag.Addr2Indirizzo,
                        anag.Addr2N,
                        anag.Addr2Cap,
                        anag.IsPep,
                        anag.IsNotPepMoreThanYear,
                        anag.VersionN,
                        anag.VersionDate,
                        anag.IsDeleted,
                        [],
                        [],
                        anag.PepCarica,
                        anag.PepRuolo,
                        anag.CreatedBy,
                        anag.ModifiedBy,
                        anag.anagrafica_fascicolos,
                        anag.WorkspaceId,
                        anag.AmlId,
                        anag.Office1Id,
                        anag.Office2Id
                      );
                      anag.WorkspaceId = await savePersonalDataOnWorkspace(
                        anagToSave
                      );
                    }

                    if (anag.Id > 0) {
                      let updateWsIdResp = await apiUpdateWsId.fetch({
                        id: anag.Id,
                        wsId: anag.WorkspaceId,
                      });
                      console.log(updateWsIdResp);
                    }
                  } catch (e) {
                    AddLog(
                      e.message + " " + anag.Id,
                      e.stack,
                      "SyncWorkspaceId"
                    );
                  }
                }
              }
            }
          }
        }
      }
    } catch (e) {
      AddLog(e.message, e.stack, "LoadDatas");
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }
  const loadBaseComune = async (provincia) => {
    try {
      const comuneResponse = await apiBaseComune.fetch({
        provincia: provincia,
      });
      const arrItems = [];
      comuneResponse.data.data.base_comune.map((item) =>
        arrItems.push(
          new baseComuneModel(item.Id, item.Codice, item.Provincia, item.Nome)
        )
      );
      setBaseComuneList(arrItems);
    } catch (e) {
      AddLog(e.message, e.stack, "LoadBaseComune");
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  const loadBaseComune2 = async (provincia) => {
    try {
      const comuneResponse = await apiBaseComune.fetch({
        provincia: provincia,
      });
      const arrItems = [];
      comuneResponse.data.data.base_comune.map((item) =>
        arrItems.push(
          new baseComuneModel(item.Id, item.Codice, item.Provincia, item.Nome)
        )
      );
      setBaseComuneList2(arrItems);
    } catch (e) {
      AddLog(e.message, e.stack, "LoadBaseComune2");
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  //Rimango in ascolto per reperire le anagrafiche di cui visualizzare le informazioni
  postal.subscribe({
    channel: "bancaDati",
    topic: "bancaDati.result",
    callback: function (data, envelope) {
      const items = data.result;

      const bancaDatiResultArr = Object.assign([], anagraficheBancaDatiList);
      for (var i = 0; i < items.length; i++) {
        bancaDatiResultArr.push(items[i]);
      }
      setAnagraficheBancaDatiList(bancaDatiResultArr);
    },
  });
  //#endregion

  //#region Handler
  const handleChangeFascicolo = async (event, value) => {
    const { id } = event.target;

    if (id === "chkPrivate") {
      fascicolo.isPrivate = event.target.checked;
      const updateFascicoloRespone = await apiUpdateFascicolo.fetch({
        id: fascicolo.id,
        name: fascicolo.name,
        date: fascicolo.date,
        isPrivate: event.target.checked,
      });
      if (updateFascicoloRespone.data.errors !== undefined) {
        enqueueSnackbar(t("error.generale"), { variant: "error" });
      } else {
        enqueueSnackbar(t("message.success"), { variant: "success" });
      }
    }
  };
  const handleChange = (event, value) => {
    const { id } = event.target;
    var passed = false;
    if (id === "chbIsPg") {
      setIsPG(event.target.checked);
      currentAnagrafica.isPg = event.target.checked;

      if (!event.target.checked) {
        currentAnagrafica.tipoAnagId = baseTipoAnagList.filter(
          (item) => item.key === "tipo_anagrafica_1"
        )[0].id;
      } else {
        currentAnagrafica.tipoAnagId = baseTipoAnagList.filter(
          (item) => item.key === "tipo_anagrafica_2"
        )[0].id;
      }
    } else if (id.indexOf("ddlTipoAnag-option") !== -1) {
      currentAnagraficaFascicolo.tipoAnagId = value.id;
    } else if (id === "txtCf") {
      currentAnagrafica.cf = event.target.value;
    } else if (id === "txtNome") {
      currentAnagrafica.nome = event.target.value;
      currentAnagrafica.denominazione =
        event.target.value + " " + currentAnagrafica.cognome;
    } else if (id === "txtCognome") {
      currentAnagrafica.cognome = event.target.value;
      currentAnagrafica.denominazione =
        currentAnagrafica.nome + " " + event.target.value;
    } else if (id.indexOf("ddlGenere-option") !== -1) {
      currentAnagrafica.genereId = value.id;
    } else if (id === "txtDenominazione") {
      currentAnagrafica.denominazione = event.target.value;
    } else if (id === "txtProfessione") {
      currentAnagrafica.professione = event.target.value;
    } else if (id === "txtNatoA") {
      currentAnagrafica.nascitaLuogo = event.target.value;
    } else if (id === "txtPIva") {
      currentAnagrafica.pIva = event.target.value;
    } else if (id === "txtReaComune") {
      currentAnagrafica.reaComune = event.target.value;
    } else if (id === "txtReaN") {
      currentAnagrafica.reaN = event.target.value;
    } else if (
      id.indexOf("ddlAddrPaese-option") !== -1 ||
      id === "ddlAddrPaese"
    ) {
      currentAnagrafica.addrPaeseId = value.id;

      currentAnagrafica.addrProvincia = "";
      currentAnagrafica.addrComune = "";
      currentAnagrafica.addrProvinciaId = null;
      currentAnagrafica.addrComuneId = null;

      var country = basePaeseList.filter(
        (item) => item.id === currentAnagrafica.addrPaeseId
      )[0];

      setIsAddrPaeseIta(country.identificativo === "ITA");

      //Visualizzo il rischio
      if (country.isRischio) {
        enqueueSnackbar(t("anagrafica.message.paeseRischio"), {
          variant: "error",
        });
      }
    } else if (
      id.indexOf("ddlAddrProvincia-option") !== -1 ||
      id === "ddlAddrProvincia"
    ) {
      currentAnagrafica.addrProvinciaId = value.id;
      currentAnagrafica.addrComuneId = 0;

      //Recupero le città
      var provincia = baseProvinciaList.filter(
        (item) => item.id === currentAnagrafica.addrProvinciaId
      )[0];
      loadBaseComune(provincia.sigla);

      //Visualizzo il rischio
      switch (provincia.rischio) {
        case 1: {
          enqueueSnackbar(t("anagrafica.message.provinciaRischio1"), {
            variant: "success",
          });
          break;
        }
        case 2: {
          enqueueSnackbar(t("anagrafica.message.provinciaRischio2"), {
            variant: "warning",
          });
          break;
        }
        case 3: {
          enqueueSnackbar(t("anagrafica.message.provinciaRischio3"), {
            variant: "warning",
          });
          break;
        }
        case 4: {
          enqueueSnackbar(t("anagrafica.message.provinciaRischio4"), {
            variant: "error",
          });
          break;
        }
        default: {
          break;
        }
      }
    } else if (
      id.indexOf("ddlAddrComune-option") !== -1 ||
      id === "ddlAddrComune"
    ) {
      currentAnagrafica.addrComuneId = value.id;
    } else if (id === "txtAddrProvincia") {
      currentAnagrafica.addrProvincia = event.target.value;
    } else if (id === "txtAddrComune") {
      currentAnagrafica.addrComune = event.target.value;
    } else if (id === "txtAddrIndirizzo") {
      currentAnagrafica.addrIndirizzo = event.target.value;
    } else if (id === "txtAddrN") {
      currentAnagrafica.addrN = event.target.value;
    } else if (id === "txtAddrCap") {
      currentAnagrafica.addrCap = event.target.value;
    } else if (id === "chbSameAddress") {
      setIsSameAddress(event.target.checked);
      if (event.target.checked) {
        currentAnagrafica.addr2PaeseId = currentAnagrafica.addrPaeseId;

        //Recupero le province
        country = basePaeseList.filter(
          (item) => item.id === currentAnagrafica.addr2PaeseId
        )[0];
        if (country.identificativo === "ITA") {
          currentAnagrafica.addr2Provincia = "";
          currentAnagrafica.addr2Comune = "";

          currentAnagrafica.addr2ProvinciaId =
            currentAnagrafica.addrProvinciaId;

          //Recupero le città
          provincia = baseProvinciaList.filter(
            (item) => item.id === currentAnagrafica.addr2ProvinciaId
          )[0];
          loadBaseComune2(provincia.sigla);

          currentAnagrafica.addr2ComuneId = currentAnagrafica.addrComuneId;
        } else {
          currentAnagrafica.addr2ProvinciaId = null;
          currentAnagrafica.addr2ComuneId = null;

          currentAnagrafica.addr2Provincia = currentAnagrafica.addrProvincia;
          currentAnagrafica.addr2Comune = currentAnagrafica.addrComune;
        }

        setIsAddr2PaeseIta(country.identificativo === "ITA");

        currentAnagrafica.addr2Indirizzo = currentAnagrafica.addrIndirizzo;
        currentAnagrafica.addr2N = currentAnagrafica.addrN;
        currentAnagrafica.addr2Cap = currentAnagrafica.addrCap;
      } else {
      }
    } else if (
      id.indexOf("ddlAddr2Paese-option") !== -1 ||
      id === "ddlAddr2Paese"
    ) {
      currentAnagrafica.addr2PaeseId = value.id;
      setIsSameAddress(false);

      //Recupero le province
      country = basePaeseList.filter(
        (item) => item.id === currentAnagrafica.addr2PaeseId
      )[0];
      if (country.identificativo === "ITA") {
        currentAnagrafica.addr2Provincia = "";
        currentAnagrafica.addr2Comune = "";
      } else {
        currentAnagrafica.addr2ProvinciaId = null;
        currentAnagrafica.addr2ComuneId = null;
      }

      setIsAddr2PaeseIta(country.identificativo === "ITA");

      //Visualizzo il rischio
      if (country.isRischio) {
        enqueueSnackbar(t("anagrafica.message.paeseRischio"), {
          variant: "error",
        });
      }
    } else if (
      id.indexOf("ddlAddr2Provincia-option") !== -1 ||
      id === "ddlAddr2Provincia"
    ) {
      currentAnagrafica.addr2ProvinciaId = value.id;
      currentAnagrafica.addr2ComuneId = 0;
      setIsSameAddress(false);

      //Recupero le città
      provincia = baseProvinciaList.filter(
        (item) => item.id === currentAnagrafica.addr2ProvinciaId
      )[0];
      loadBaseComune2(provincia.sigla);

      //Visualizzo il rischio
      switch (provincia.rischio) {
        case 1: {
          enqueueSnackbar(t("anagrafica.message.provinciaRischio1"), {
            variant: "success",
          });
          break;
        }
        case 2: {
          enqueueSnackbar(t("anagrafica.message.provinciaRischio2"), {
            variant: "warning",
          });
          break;
        }
        case 3: {
          enqueueSnackbar(t("anagrafica.message.provinciaRischio3"), {
            variant: "warning",
          });
          break;
        }
        case 4: {
          enqueueSnackbar(t("anagrafica.message.provinciaRischio4"), {
            variant: "error",
          });
          break;
        }
        default: {
          break;
        }
      }
    } else if (
      id.indexOf("ddlAddr2Comune-option") !== -1 ||
      id === "ddlAddr2Comune"
    ) {
      currentAnagrafica.addr2ComuneId = value.id;
      setIsSameAddress(false);
    } else if (id === "txtAddr2Provincia") {
      currentAnagrafica.addr2Provincia = event.target.value;
      setIsSameAddress(false);
    } else if (id === "txtAddr2Comune") {
      currentAnagrafica.addr2Comune = event.target.value;
      setIsSameAddress(false);
    } else if (id === "txtAddr2Indirizzo") {
      currentAnagrafica.addr2Indirizzo = event.target.value;
      setIsSameAddress(false);
    } else if (id === "txtAddr2N") {
      currentAnagrafica.addr2N = event.target.value;
      setIsSameAddress(false);
    } else if (id === "txtAddr2Cap") {
      currentAnagrafica.addr2Cap = event.target.value;
      setIsSameAddress(false);
    } else if (event.target.name === "radioBtnIsPep") {
      if (value === "isNotPep") {
        currentAnagrafica.isPep = false;
        currentAnagrafica.isNotPepMoreThanYear = false;
        setAnagPep((prevState) => ({
          ...prevState,
          IsNotPep: true,
          IsPep: false,
          IsNotPepMoreThanYear: false,
        }));
      } else if (value === "isPep") {
        currentAnagrafica.isPep = true;
        currentAnagrafica.isNotPepMoreThanYear = false;
        setAnagPep((prevState) => ({
          ...prevState,
          IsNotPep: false,
          IsPep: true,
          IsNotPepMoreThanYear: false,
        }));
      } else if (value === "isNotPepMoreThanYear") {
        currentAnagrafica.isPep = false;
        currentAnagrafica.isNotPepMoreThanYear = true;
        setAnagPep((prevState) => ({
          ...prevState,
          IsNotPep: false,
          IsPep: false,
          IsNotPepMoreThanYear: true,
        }));
      }
    } else if (id === "txtPepCarica") {
      currentAnagrafica.pepCarica = event.target.value;
    } else if (
      id.indexOf("ddlPepCarica-option") !== -1 ||
      id === "ddlPepCarica"
    ) {
      currentAnagrafica.pepRuolo = value;
    } else if (
      id.indexOf("ddlTipoPart-option") !== -1 ||
      id === "ddlTipoPart"
    ) {
      currentAnagraficaFascicolo.tipoPartId = value.id;
      var tipoPartKey = baseTipoPartList.filter(
        (item) => item.id === value.id
      )[0].key;
      setShowTipoPartDesc(tipoPartKey === "tipo_partecipazione_10");

      if (tipoPartKey !== "tipo_partecipazione_10") {
        currentAnagraficaFascicolo.tipoPartDesc = "";
      }
    } else if (id === "txtTipoPartDesc") {
      currentAnagraficaFascicolo.tipoPartDesc = event.target.value;
    } else if (
      id.indexOf("ddlTipoRapp-option") !== -1 ||
      id === "ddlTipoRapp"
    ) {
      currentAnagraficaFascicolo.tipoRappId = value.id;
      setEnableTipoRappDesc(value.id === 2);

      if (value.id !== 2) {
        currentAnagraficaFascicolo.tipoRappDesc = "";
        setEnableTipoRappDesc(false);
      }
    } else if (id === "txtTipoRappDesc") {
      currentAnagraficaFascicolo.tipoRappDesc = event.target.value;
    }

    //Identificazione
    else if (id.indexOf("ddlModIdent-option") !== -1 || id === "ddlModIdent") {
      currentAnagraficaIdent.modalitaIdentId = value.id;
      const modalitaIdentKey = baseTipoIdentList.filter(
        (item) => item.id === currentAnagraficaIdent.modalitaIdentId
      )[0].key;
      setIsModIdentDiretta(modalitaIdentKey === "tipo_identificazione_1");
      setShowDoctipoAp(modalitaIdentKey === "tipo_identificazione_5");

      if (currentAnagraficaIdent.identifiedByUser === "") {
        currentAnagraficaIdent.identifiedByUser =
          sharedDatas.getPropertyByName("userId");
      }
    } else if (id.indexOf("ddlIdentBy-option") !== -1 || id === "ddlIdentBy") {
      currentAnagraficaIdent.identifiedByUser = value.userId;
      if (
        currentAnagraficaIdent.identifiedByUser === currentAnagrafica.createdBy
      ) {
        setIsUpdateEnable(true);
      } else {
        setIsUpdateEnable(false);
      }
    } else if (
      id.indexOf("ddlModIdentDoc-option") !== -1 ||
      id === "ddlModIdentDoc"
    ) {
      passed = true;
      currentAnagraficaIdent.docTipoId = value.id;
      const docTipoKey = baseTipoDocList.filter(
        (item) => item.id === currentAnagraficaIdent.docTipoId
      )[0].key;
      setShowDocTipoDetails(docTipoKey !== "tipo_documento_3");
    } else if (id === "txtDocN") {
      currentAnagraficaIdent.docN = event.target.value;
    } else if (id === "txtDocRilDa") {
      currentAnagraficaIdent.docRilDa = event.target.value;
    } else if (id === "txtApNome") {
      currentAnagraficaIdent.apNome = event.target.value;
    } else if (id === "txtApCf") {
      currentAnagraficaIdent.apCf = event.target.value;
    }

    setCurrentAnagrafica(currentAnagrafica);
    if (passed) {
      setCurrentAnagraficaIdent((prevState) => ({
        ...prevState,
        docN: "",
        docRilDa: "",
        docRilData: "",
        docScad: "",
      }));
    } else {
      setCurrentAnagraficaIdent(currentAnagraficaIdent);
    }
    setCurrentAnagraficaFascicolo(currentAnagraficaFascicolo);
  };
  const handleBlurPIva = async (event, value) => {
    try {
      const { id } = event.target;
      const pIva = event.target.value;
      if (id === "txtPIva" && pIva !== "" && isPIvaValid(pIva) !== "") {
        enqueueSnackbar(isPIvaValid(pIva), { variant: "warning" });
      }
    } catch (e) {
      AddLog(e.message, e.stack, "HandleBlurPIva");
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  const handleSearchClick = async () => {
    try {
      setBackDropOpen(true);

      const cf = currentAnagrafica.cf;
      const pIva = currentAnagrafica.pIva;
      let dataNascita = null;
      let sesso = null;
      let codComune = null;
      let luogoNascita = "";
      let nascitaData = null;
      let genereId = null;

      if (cf) {
        let isStringSearchedFiscalCode = isAFiscalCode(cf);
        if (!isPG && isStringSearchedFiscalCode) {
          const cfDatas = getDataFromFiscalCode(cf);

          if (cfDatas !== "") {
            dataNascita = cfDatas.data.split("/");
            sesso = cfDatas.sesso;
            codComune = cfDatas.codComune;

            const comuneResponse = await apiBaseComuneByCodBelfiore.fetch({
              codBelfiore: codComune,
            });
            if (comuneResponse.data.errors !== undefined) {
            } else {
              comuneResponse.data.data.base_comune.forEach((item) => {
                luogoNascita = item.Nome;
              });
            }

            if (luogoNascita === "") {
              const paeseResponse = await apiBasePaeseByCodAt.fetch({
                codiceAt: codComune,
              });
              if (paeseResponse.data.errors !== undefined) {
              } else {
                paeseResponse.data.data.base_paese.forEach((item) => {
                  luogoNascita = item.Nome;
                });
              }
            }

            const nascitaDataD = new Date(
              parseInt(dataNascita[2]),
              parseInt(dataNascita[1]) - 1,
              parseInt(dataNascita[0]),
              1,
              0,
              0
            );
            nascitaData = new Date(
              Date.UTC(
                nascitaDataD.getFullYear(),
                nascitaDataD.getMonth(),
                nascitaDataD.getDate(),
                1,
                0,
                0
              )
            );
            genereId = baseGenereList.filter((x) => x.value === sesso)[0].id;
          } else if (cfDatas === "" && cf.length > 0) {
            enqueueSnackbar("Il codice fiscale potrebbe non essere corretto", {
              variant: "warning",
            });
          }
        }

        //Carico le anagrafiche da Workspace
        let anagWSResponse = [];
        try {
          anagWSResponse = await tsApiWorkspaceRead.fetch({
            headers: {
              Authorization: "Bearer " + accessToken,
              "X-App-Name": "TS420",
              "X-App-Version": "1.0.0",
              "X-Request-ID": uuidv4(),
              "X-Correlation-ID": uuidv4(),
              "Content-Type": "application/json",
              "X-Item-ID": tenantId,
              "X-User-ID": loggedUserId,
            },
            data: {
              ownerId: tenantId,
              status: "ACTIVE",
            },
          });
        } catch (e) {
          AddLog(
            e.message,
            e.stack,
            "HandleSearchClick/tsApiWorkspaceRead.fetch"
          );
        }

        let anagAv = currentAnagrafica;
        anagAv.nascitaData = nascitaData;
        anagAv.nascitaLuogo = luogoNascita;
        anagAv.genereId = genereId;

        let arrUsersToFilter = [];
        arrUsersToFilter.push(loggedUserId);

        let anagResponse = null;
        if (!isPG) {
          let anagByFiscalCode = await apiAnagByFiscalCode.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            cf: cf + "%",
          });
          anagResponse = await apiAnagraficheByCf.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            cf: "%%",
            nome: "%%",
            cognome: cf + "%",
          });
          anagResponse.data.data.anagrafica =
            anagResponse.data.data.anagrafica.concat(
              anagByFiscalCode.data.data.anagrafica
            );
        } else {
          anagResponse = await apiAnagraficheByCfPG.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            cf: "%" + cf + "%",
            pIva: "%" + pIva + "%",
            denominazione: "%%",
          });
        }

        let anags = [];
        anagResponse.data.data.anagrafica.forEach((anag) => {
          if (!anags.map((l) => l.Cf).includes(anag.Cf)) anags.push(anag);
        });

        if (anags !== null && anags.length === 1) {
          const anagrafica = anags[0];

          if (anagrafica.AddrPaeseId !== null && anagrafica.AddrPaeseId > 0) {
            const paese = basePaeseList.filter(
              (item) => item.id === anagrafica.AddrPaeseId
            )[0];
            if (paese.identificativo === "ITA") {
              const provincia = baseProvinciaList.filter(
                (item) => item.id === anagrafica.AddrProvinciaId
              )[0];
              loadBaseComune(provincia.sigla);
            }

            setIsAddrPaeseIta(paese.identificativo === "ITA");
          }

          anagAv = new anagraficaModel(
            anagrafica.Id,
            anagrafica.ParentId,
            anagrafica.IsPg,
            anagrafica.Cf,
            anagrafica.Nome,
            anagrafica.Cognome,
            anagrafica.GenereId !== 0 ? anagrafica.GenereId : anagAv.genereId,
            anagrafica.NascitaLuogo !== ""
              ? anagrafica.NascitaLuogo
              : anagAv.nascitaLuogo,
            anagrafica.NascitaData !== null
              ? anagrafica.NascitaData
              : anagAv.nascitaData,
            anagrafica.Professione,
            anagrafica.Denominazione,
            anagrafica.PIva,
            anagrafica.ReaComune,
            anagrafica.ReaN,
            anagrafica.AddrPaeseId,
            anagrafica.AddrProvinciaId,
            anagrafica.AddrProvincia,
            anagrafica.AddrComuneId,
            anagrafica.AddrComune,
            anagrafica.AddrIndirizzo,
            anagrafica.AddrN,
            anagrafica.AddrCap,
            anagrafica.Addr2PaeseId,
            anagrafica.Addr2ProvinciaId,
            anagrafica.Addr2Provincia,
            anagrafica.Addr2ComuneId,
            anagrafica.Addr2Comune,
            anagrafica.Addr2Indirizzo,
            anagrafica.Addr2N,
            anagrafica.Addr2Cap,
            anagrafica.IsPep,
            anagrafica.IsNotPepMoreThanYear,
            anagrafica.VersionN,
            anagrafica.VersionDate,
            false,
            [],
            [],
            anagrafica.PepCarica,
            anagrafica.PepRuolo,
            anagrafica.CreatedBy,
            anagrafica.ModifiedBy,
            anagrafica.anagrafica_fascicolos,
            anagrafica.WorkspaceId
          );

          // Recupero l'identificazione
          if (anagrafica.anagrafica_identificaziones.length > 0) {
            const identificazione = anagrafica.anagrafica_identificaziones[0];
            const anagIdent = new anagraficaIdentificazioneModel(
              identificazione.Id,
              identificazione.base_tipo_identificazione.Id,
              identificazione.DocTipoId,
              identificazione.DocN,
              identificazione.DocRilDa,
              identificazione.DocRilData,
              identificazione.DocScad,
              identificazione.ApNome,
              identificazione.ApCf,
              identificazione.IdentifiedByUser,
              identificazione.IdentifiedOn,
              identificazione.CreatedOn,
              identificazione.ModifiedOn,
              identificazione.CreatedBy,
              identificazione.ModifiedBy
            );

            const modalitaIdentKey = baseTipoIdentList.filter(
              (item) => item.id === anagIdent.modalitaIdentId
            )[0].key;
            setIsModIdentDiretta(modalitaIdentKey === "tipo_identificazione_1");
            setShowDoctipoAp(modalitaIdentKey === "tipo_identificazione_5");

            if (anagIdent.identifiedByUser === "") {
              anagIdent.identifiedByUser =
                sharedDatas.getPropertyByName("userId");
            }

            if (anagIdent.docTipoId > 0) {
              const docTipoKey = baseTipoDocList.filter(
                (item) => item.id === anagIdent.docTipoId
              )[0].key;
              setShowDocTipoDetails(docTipoKey !== "tipo_documento_3");
            }

            setIsCessato(loggedUserId !== anagIdent.identifiedByUser);
            setEnableFormControls(true)
            setCurrentAnagraficaIdent(anagIdent);
          }

          setAnagPep((prevState) => ({
            ...prevState,
            IsNotPep: !anagrafica.IsPep && !anagrafica.IsNotPepMoreThanYear,
            IsPep: anagrafica.IsPep,
            IsNotPepMoreThanYear: anagrafica.IsNotPepMoreThanYear,
          }));
        }

        // Se non ho trovato nulla internamente, cerco l'anagrafica su Workspace
        if (anagWSResponse && anagWSResponse.data.content.length > 0) {
          if (anagAv.id === 0 && anagWSResponse.data.content.length > 0) {
            var counter = 0;

            for (let i = 0; i < anagWSResponse.data.content.length; i++) {
              const anagItem = anagWSResponse.data.content[i];
              if (anagItem.personalData.taxIdentifier === cf) {
                anagAv = new anagraficaModel(
                  counter,
                  0,
                  anagItem.classifier === "COMPANY" ? true : false,
                  anagItem.personalData.taxIdentifier,
                  anagItem.personalData.name,
                  anagItem.personalData.surname,
                  genereId,
                  anagItem.personalData.birthplace,
                  anagItem.personalData.birthDate,
                  "",
                  anagItem.personalData.businessName,
                  anagItem.personalData.vatIdentifier,
                  "",
                  "",
                  0,
                  0,
                  "",
                  0,
                  "",
                  "",
                  "",
                  "",
                  0,
                  0,
                  "",
                  0,
                  "",
                  "",
                  "",
                  "",
                  false,
                  false,
                  1,
                  new Date(),
                  false,
                  [],
                  [],
                  "",
                  loggedUserId,
                  null,
                  [],
                  anagItem.id,
                  "",
                  "",
                  ""
                );
              }
            }
          }

          if (anagAv.denominazione === null || anagAv.denominazione === "") {
            if (!anagAv.isPg) {
              anagAv.denominazione = anagAv.nome + " " + anagAv.cognome;
            }
          }
        }

        if (anags.length > 1) {
          setShowResultListModal(true);
          setResultList(anags);
        } else {
          setCurrentAnagrafica((prevState) => ({
            ...prevState,
            id: anagAv.id,
            parentId:
              anagAv.id === 0 &&
              anagWSResponse.length > 0 &&
              anagWSResponse.data.content.length > 0
                ? 0
                : anagAv.parentId,
            isPg: anagAv.isPg,
            cf: anagAv.cf,
            nome: anagAv.nome,
            cognome: anagAv.cognome,
            genereId: anagAv.genereId,
            nascitaLuogo: anagAv.nascitaLuogo,
            nascitaData: anagAv.nascitaData,
            professione: anagAv.professione,
            denominazione: anagAv.denominazione,
            pIva: anagAv.pIva,
            reaComune: anagAv.reaComune,
            reaN: anagAv.reaN,
            addrPaeseId: anagAv.addrPaeseId,
            addrProvinciaId: anagAv.addrProvinciaId,
            addrProvincia: anagAv.addrProvincia,
            addrComuneId: anagAv.addrComuneId,
            addrComune: anagAv.addrComune,
            addrIndirizzo: anagAv.addrIndirizzo,
            addrN: anagAv.addrN,
            addrCap: anagAv.addrCap,
            addr2PaeseId: anagAv.addr2PaeseId,
            addr2ProvinciaId: anagAv.addr2ProvinciaId,
            addr2Provincia: anagAv.addr2Provincia,
            addr2ComuneId: anagAv.addr2ComuneId,
            addr2Comune: anagAv.addr2Comune,
            addr2Indirizzo: anagAv.addr2Indirizzo,
            addr2N: anagAv.addr2N,
            addr2Cap: anagAv.addr2Cap,
            isPep: anagAv.isPep,
            isNotPepMoreThanYear: anagAv.isNotPepMoreThanYear,
            versionN: anagAv.versionN,
            versionDate: anagAv.versionDate,
            isDeleted: anagAv.isDeleted,
            pepCarica: anagAv.pepCarica,
            pepRuolo: anagAv.pepRuolo,
          }));
          setShowAnagForm(true);
        }

        setBackDropOpen(false);
      } else {
        enqueueSnackbar("Il campo di ricerca non può essere vuoto", {
          variant: "warning",
        });
      }
    } catch (e) {
      AddLog(e.message, e.stack, "HandleSearchClick");
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  const handleChangeDate = (date) => {
    if (date !== null && isDateValid(date)) {
      const newDate = new Date(date);
      currentAnagrafica.nascitaData = newDate;
      setCurrentAnagrafica((prevState) => ({
        ...prevState,
        nascitaData: newDate,
      }));
    }
  };
  const handleChangeVersionDate = (date) => {
    if (date !== null && isDateValid(date)) {
      const newDate = new Date(date);
      currentAnagraficaNewVer.versionDate = newDate;
      setCurrentAnagraficaNewVer((prevState) => ({
        ...prevState,
        versionDate: newDate,
      }));
    }
  };
  const handleChangeIdentDate = (date) => {
    if (date !== null && isDateValid(date)) {
      const newDate = new Date(date);
      currentAnagraficaIdent.identifiedOn = newDate;
      setCurrentAnagraficaIdent((prevState) => ({
        ...prevState,
        identifiedOn: newDate,
      }));

      //Se cambio la data di identificazione, ed è la prima versione, setto la data di versione = alla data di identificazione
      if (currentAnagrafica.versionN === 1) {
        currentAnagrafica.versionDate = newDate;
        setCurrentAnagrafica(currentAnagrafica);
      }
    }
  };
  const handleChangeDocRilDate = (date) => {
    if (date !== null && isDateValid(date)) {
      const newDate = new Date(date);
      currentAnagraficaIdent.docRilData = newDate;
      setCurrentAnagraficaIdent((prevState) => ({
        ...prevState,
        docRilData: newDate,
      }));
    }
  };
  const handleChangeCessDate = (date) => {
    if (date !== null && isDateValid(date)) {
      let dt = date.setHours(12, 0, 0, 0);
      const newDate = new Date(dt).toISOString();
      currentAnagraficaFascicolo.dataFine = newDate;
      setCurrentAnagraficaFascicolo((prevState) => ({
        ...prevState,
        dataFine: newDate,
      }));
    } else if (date === null) {
      currentAnagraficaFascicolo.dataFine = null;
      setCurrentAnagraficaFascicolo((prevState) => ({
        ...prevState,
        dataFine: null,
      }));
    }
  };
  const handleBlurDate = (event) => {
    var selTipoIdent = baseTipoDocList.filter(
      (doc) => doc.id === currentAnagraficaIdent.docTipoId
    )[0];
    if (selTipoIdent.validityMonths) {
      var dateRil = new Date(currentAnagraficaIdent.docRilData);
      if (selTipoIdent.nextBirthDay) {
        var birthDay = new Date(currentAnagrafica.nascitaData);
        var rilDatePlus = new Date(
          dateRil.setFullYear(dateRil.getFullYear() + 9)
        );
        birthDay.setFullYear(rilDatePlus.getFullYear());
        if (birthDay < rilDatePlus) {
          birthDay.setFullYear(birthDay.getFullYear() - 8);
          dateRil = new Date(
            birthDay.setMonth(birthDay.getMonth() + selTipoIdent.validityMonths)
          );
        } else if (birthDay >= rilDatePlus) {
          birthDay.setFullYear(birthDay.getFullYear() - 9);
          dateRil = new Date(
            birthDay.setMonth(birthDay.getMonth() + selTipoIdent.validityMonths)
          );
        }
      } else {
        dateRil.setDate(dateRil.getDate() + selTipoIdent.validityOffsetDays);
        dateRil.setMonth(dateRil.getMonth() + selTipoIdent.validityMonths);
        currentAnagrafica.docScad = dateRil;
      }

      setCurrentAnagraficaIdent((prevState) => ({
        ...prevState,
        docScad: dateRil,
      }));
    }
  };
  const handleChangeDocScad = (date) => {
    if (date !== null && isDateValid(date)) {
      const newDate = new Date(date);
      currentAnagraficaIdent.docScad = newDate;
      setCurrentAnagraficaIdent((prevState) => ({
        ...prevState,
        docScad: newDate,
      }));
    }
  };
  const handleAddNewAnagrafica = async (type) => {
    const newItem = await emptyAnagraficaModel(basePaeseList);
    const newItemAngFascicolo = emptyAnagraficaFascicoloModel();
    setTipoAnag(type);
    setIsPG(false);
    setLastSelectedAnag(null);
    setIsCessato(false)

    switch (type) {
      case "cliente": {
        newItemAngFascicolo.tipoAnagId = baseTipoAnagList.filter(
          (item) => item.key === "tipo_anagrafica_1"
        )[0].id;
        break;
      }
      case "collegate": {
        newItemAngFascicolo.tipoAnagId = baseTipoAnagList.filter(
          (item) => item.key === "tipo_anagrafica_3"
        )[0].id;
        break;
      }
      case "titolare": {
        newItemAngFascicolo.tipoAnagId = baseTipoAnagList.filter(
          (item) => item.key === "tipo_anagrafica_5"
        )[0].id;
        break;
      }
      default: {
        break;
      }
    }

    setCurrentAnagrafica(newItem);
    setCurrentAnagraficaOriginal(newItem);
    setCurrentAnagraficaIdent(emptyAnagraficaIdentModel());
    setCurrentAnagraficaFascicolo(newItemAngFascicolo);
    setCurrentAnagraficaNewVer(newItem);
    setShowDoctipoAp(false);
    setShowDocTipoDetails(false);
    setIsSameAddress(false);
    setIsAddrPaeseIta(true);
    setIsAddr2PaeseIta(true);
    setIsModIdentDiretta(true);
    setAnagPep({ IsNotPep: true, IsPep: false, IsNotPepMoreThanYear: false });
    setShowTipoPartDesc(false);
    setIdToPerform(0);
    setEnableFormControls(true);
    setShowAnagForm(false);

    //Invio una notifica tramite pub/sub interno
    postal.publish({
      channel: "fascicolo",
      topic: "anagrafica.detail",
      data: {
        anagrafica: newItem,
      },
    });
  };
  const handleCancelClick = async () => {
    setIsDataLoaded(false);
    setShowAnagForm(false);
    setLastSelectedAnag(null);
    //setIsUpdateEnable(false);
  };
  const handleSelectedAnagClick = async (anagrafica) => {
    try {
      var historyItems = currentAnagraficheHistoryList
        .filter(
          (x) =>
            x.id === anagrafica.id ||
            x.id === anagrafica.parentId ||
            x.parentId === anagrafica.parentId
        )
        .sort((a, b) => b.versionN - a.versionN);
      if (anagrafica.parentId === 0) {
        historyItems = currentAnagraficheHistoryList
          .filter((x) => x.id === anagrafica.id)
          .sort((a, b) => b.versionN - a.versionN);
      }

      await selectAnagrafica(
        anagrafica,
        basePaeseList,
        baseProvinciaList,
        baseTipoAnagList,
        baseTipoIdentList,
        baseTipoDocList,
        baseTipoPartList,
        historyItems,
        false
      );
    } catch (e) {
      AddLog(e.message, e.stack, "HandleSelectedAnagClick");
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  const handleSelectedAnagVerClick = async (anagrafica) => {
    try {
      await selectAnagrafica(
        anagrafica,
        basePaeseList,
        baseProvinciaList,
        baseTipoAnagList,
        baseTipoIdentList,
        baseTipoDocList,
        baseTipoPartList,
        currentAnagraficheHistoryFilteredList,
        true
      );
    } catch (e) {
      AddLog(e.message, e.stack, "HandleSelectedAnagClick");
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  const handleDeleteAnagClick = (anagrafica) => {
    setIdToPerform(anagrafica.id);
    setTypeToPerform("A");
    setShowDeleteModal(true);
    setAnagFascToPerform(
      anagrafica.anagFascicoli.length > 0 ? anagrafica.anagFascicoli[0] : null
    );
  };
  const handleDeleteAnagVerClick = (anagrafica) => {
    setIdToPerform(anagrafica.id);
    setTypeToPerform("AV");
    setShowDeleteModal(true);
  };
  const handleSaveAnagClick = async () => {
    if (currentAnagraficaIdent.identifiedByUser !== loggedUserId) {
      setShowIdentModal(true);
      setSaveType("anagrafica");
    } else if (!isAFiscalCode(currentAnagrafica.cf)) {
      enqueueSnackbar("Il codice fiscale inserito non è valido", {
        variant: "error",
      });
      return;
    } else {
      await saveAnagrafica();
    }
  };
  const handleSaveAnagNewVersionClick = async () => {
    try {
      setBackDropOpen(true);
      var errors = await checkDataBeforeSaving();

      if (errors.length <= 0) {
        const newAnagVer = Object.assign({}, currentAnagrafica);
        newAnagVer.versionDate = currentAnagraficaIdent.identifiedOn;
        newAnagVer.versionN = newAnagVer.versionN + 1;
        setCurrentAnagraficaNewVer(newAnagVer);
        setIdToPerform(newAnagVer.id);
        setShowHistoryModal(true);
      } else {
        for (let i = 0; i < errors.length; i++) {
          enqueueSnackbar(errors[i], { variant: "error" });
        }
      }
    } catch (e) {
      AddLog(e.message, e.stack, "HandleSaveAnagNewVersionClick");
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  //#endregion

  //#region CRUD dell'anagrafica
  const emptyAnagraficaModel = async (paesi) => {
    const paeseId = paesi.filter((x) => x.identificativo === "ITA")[0].id;
    setIsAddrPaeseIta(true);

    const newItem = new anagraficaModel(
      0,
      0,
      false,
      "",
      "",
      "",
      0,
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      paeseId,
      undefined,
      "",
      undefined,
      "",
      "",
      "",
      "",
      undefined,
      undefined,
      "",
      undefined,
      "",
      "",
      "",
      "",
      false,
      false,
      1,
      new Date(),
      false,
      [],
      [],
      "",
      loggedUserId,
      "",
      [],
      "",
      "",
      "",
      "",
      ""
    );
    return newItem;
  };
  const emptyAnagraficaIdentModel = (tipoIdentList) => {
    let modalitaIdentId = 0;
    if (tipoIdentList === undefined) {
      modalitaIdentId = baseTipoIdentList.filter(
        (x) => x.key === "tipo_identificazione_1"
      )[0].id;
    } else {
      modalitaIdentId = tipoIdentList.filter(
        (x) => x.key === "tipo_identificazione_1"
      )[0].id;
    }

    setIsModIdentDiretta(true);

    const newItem = new anagraficaIdentificazioneModel(
      0,
      modalitaIdentId,
      0,
      "",
      "",
      null,
      null,
      "",
      "",
      sharedDatas.getPropertyByName("userId"),
      new Date(),
      null,
      null,
      loggedUserId
    );
    return newItem;
  };
  const emptyAnagraficaFascicoloModel = () => {
    const newItem = new anagraficaFascicoloModel(
      0,
      0,
      fascicolo.id,
      0,
      0,
      "",
      null,
      0,
      ""
    );
    return newItem;
  };
  const checkDataBeforeSaving = async () => {
    let errors = [];

    try {
      var canIProceed = false;
      if (currentAnagrafica.isPg) {
        canIProceed =
          currentAnagraficaFascicolo.tipoAnagId !== undefined &&
          currentAnagraficaFascicolo.tipoAnagId > 0 &&
          currentAnagrafica.cf !== undefined &&
          currentAnagrafica.cf !== "" &&
          currentAnagrafica.pIva !== undefined &&
          currentAnagrafica.pIva !== "" &&
          currentAnagrafica.denominazione !== undefined &&
          currentAnagrafica.denominazione !== "" &&
          currentAnagrafica.addrPaeseId !== undefined &&
          currentAnagrafica.addrPaeseId > 0 &&
          ((currentAnagrafica.addrProvinciaId !== undefined &&
            currentAnagrafica.addrProvinciaId > 0) ||
            (currentAnagrafica.addrProvincia !== undefined &&
              currentAnagrafica.addrProvincia !== "")) &&
          ((currentAnagrafica.addrComuneId !== undefined &&
            currentAnagrafica.addrComuneId > 0) ||
            (currentAnagrafica.addrComune !== undefined &&
              currentAnagrafica.addrComune !== "")) &&
          currentAnagrafica.addrIndirizzo !== undefined &&
          currentAnagrafica.addrIndirizzo !== "" &&
          currentAnagrafica.addrCap !== undefined &&
          currentAnagrafica.addrCap !== "" &&
          currentAnagraficaIdent.modalitaIdentId !== undefined &&
          currentAnagraficaIdent.modalitaIdentId > 0 &&
          currentAnagraficaIdent.docTipoId !== undefined &&
          currentAnagraficaIdent.docTipoId > 0;

        if (
          currentAnagraficaIdent.docTipoId !== undefined &&
          currentAnagraficaIdent.docTipoId > 0
        ) {
          if (
            baseTipoDocList.filter(
              (x) => x.id === currentAnagraficaIdent.docTipoId
            )[0].key === "tipo_documento_8"
          ) {
            //Data identificazione
            if (
              currentAnagraficaIdent.docRilData !== null &&
              currentAnagraficaIdent.docScad !== null
            ) {
              if (
                new Date(currentAnagraficaIdent.identifiedOn).setHours(
                  0,
                  0,
                  0,
                  0
                ) >=
                  new Date(currentAnagraficaIdent.docRilData).setHours(
                    0,
                    0,
                    0,
                    0
                  ) &&
                new Date(currentAnagraficaIdent.identifiedOn).setHours(
                  0,
                  0,
                  0,
                  0
                ) <=
                  new Date(currentAnagraficaIdent.docScad).setHours(0, 0, 0, 0)
              ) {
              } else {
                canIProceed = false;
                errors.push(
                  "La data di identificazione deve essere compresa tra la data di rilascio e la data di scadenza del documento"
                );
              }
            }

            //Data rilascio documento
            if (
              new Date(currentAnagraficaIdent.docRilData).setHours(0, 0, 0, 0) >
              new Date(currentAnagraficaIdent.identifiedOn).setHours(0, 0, 0, 0)
            ) {
              canIProceed = false;
              errors.push(
                "La data di rilascio deve essere superiore alla data di identificazione"
              );
            }

            //Data scadenza documento
            if (
              new Date(currentAnagraficaIdent.docScad).setHours(0, 0, 0, 0) <
              new Date(currentAnagraficaIdent.docRilData).setHours(0, 0, 0, 0)
            ) {
              canIProceed = false;
              errors.push(
                "La data di scadenza deve essere superiore alla data di rilascio"
              );
            }
          }
        }
      } else {
        canIProceed =
          currentAnagraficaFascicolo.tipoAnagId !== undefined &&
          currentAnagraficaFascicolo.tipoAnagId > 0 &&
          currentAnagrafica.cf !== undefined &&
          currentAnagrafica.cf !== "" &&
          currentAnagrafica.nome !== undefined &&
          currentAnagrafica.nome !== "" &&
          currentAnagrafica.cognome !== undefined &&
          currentAnagrafica.cognome !== "" &&
          currentAnagrafica.professione !== undefined &&
          currentAnagrafica.professione !== "" &&
          currentAnagrafica.nascitaLuogo !== undefined &&
          currentAnagrafica.nascitaLuogo !== "" &&
          currentAnagrafica.nascitaData !== undefined &&
          currentAnagrafica.nascitaData !== null &&
          currentAnagrafica.addrPaeseId !== undefined &&
          currentAnagrafica.addrPaeseId > 0 &&
          ((currentAnagrafica.addrProvinciaId !== undefined &&
            currentAnagrafica.addrProvinciaId > 0) ||
            (currentAnagrafica.addrProvincia !== undefined &&
              currentAnagrafica.addrProvincia !== "")) &&
          ((currentAnagrafica.addrComuneId !== undefined &&
            currentAnagrafica.addrComuneId > 0) ||
            (currentAnagrafica.addrComune !== undefined &&
              currentAnagrafica.addrComune !== "")) &&
          currentAnagrafica.addrIndirizzo !== undefined &&
          currentAnagrafica.addrIndirizzo !== "" &&
          currentAnagrafica.addrCap !== undefined &&
          currentAnagrafica.addrCap !== "" &&
          currentAnagraficaIdent.modalitaIdentId !== undefined &&
          currentAnagraficaIdent.modalitaIdentId > 0 &&
          currentAnagraficaIdent.docTipoId !== undefined &&
          currentAnagraficaIdent.docTipoId > 0;

        if (
          baseTipoAnagList.filter(
            (x) => x.id === currentAnagraficaFascicolo.tipoAnagId
          )[0].key === "tipo_anagrafica_5" &&
          currentAnagraficaFascicolo.tipoPartId <= 0
        ) {
          canIProceed = false;
        }

        //Data identificazione
        if (
          currentAnagraficaIdent.docRilData !== null &&
          currentAnagraficaIdent.docScad !== null
        ) {
          if (
            new Date(currentAnagraficaIdent.identifiedOn).setHours(
              0,
              0,
              0,
              0
            ) >=
              new Date(currentAnagraficaIdent.docRilData).setHours(
                0,
                0,
                0,
                0
              ) &&
            new Date(currentAnagraficaIdent.identifiedOn).setHours(
              0,
              0,
              0,
              0
            ) <= new Date(currentAnagraficaIdent.docScad).setHours(0, 0, 0, 0)
          ) {
          } else {
            canIProceed = false;
            errors.push(
              "La data di identificazione deve essere compresa tra la data di rilascio e la data di scadenza del documento"
            );
          }
        }

        //Data rilascio documento
        if (
          new Date(currentAnagraficaIdent.docRilData).setHours(0, 0, 0, 0) >
          new Date(currentAnagraficaIdent.identifiedOn).setHours(0, 0, 0, 0)
        ) {
          canIProceed = false;
          errors.push(
            "La data di rilascio deve essere superiore alla data di identificazione"
          );
        }

        //Data scadenza documento
        if (
          new Date(currentAnagraficaIdent.docScad).setHours(0, 0, 0, 0) <
          new Date(currentAnagraficaIdent.docRilData).setHours(0, 0, 0, 0)
        ) {
          canIProceed = false;
          errors.push(
            "La data di scadenza deve essere superiore alla data di rilascio"
          );
        }
      }

      if (!canIProceed && errors.length <= 0) {
        errors.push(t("error.missingFields"));
      }

      return errors;
    } catch (e) {
      AddLog(e.message, e.stack, "CheckDataBeforeSaving");
    }
  };
  const selectAnagrafica = async (
    anagrafica,
    paesi,
    province,
    tipoAnagrafiche,
    tipoIdentificazioni,
    tipoDocumenti,
    tipoPartecipazioni,
    anagHistory,
    isFromVersion
  ) => {
    try {
      setShowDoctipoAp(false);
      setIsModIdentDiretta(false);
      setShowDocTipoDetails(false);
      setShowAnagForm(true);
      setCurrentAnagraficaIdent(emptyAnagraficaIdentModel(tipoIdentificazioni));

      //Recupero l'identificazione effettuata dall'utente corrente
      if (anagrafica.identificazioni.length > 0) {
        const item = anagrafica.identificazioni[0];
        const anagIdentModel = new anagraficaIdentificazioneModel(
          item.id,
          item.modalitaIdentId,
          item.docTipoId,
          item.docN,
          item.docRilDa,
          item.docRilData,
          item.docScad,
          item.apNome,
          item.apCf,
          item.identifiedByUser,
          item.identifiedOn,
          item.createdOn,
          item.modifiedOn,
          item.createdBy,
          item.modifiedBy
        );
        setCurrentAnagraficaIdent(anagIdentModel);

        const modalitaIdentKey = tipoIdentificazioni.filter(
          (item2) => item2.id === item.modalitaIdentId
        )[0].key;
        setIsModIdentDiretta(modalitaIdentKey === "tipo_identificazione_1");
        setShowDoctipoAp(modalitaIdentKey === "tipo_identificazione_5");

        if (item.docTipoId > 0) {
          const docTipoKey = tipoDocumenti.filter(
            (item3) => item3.id === item.docTipoId
          )[0].key;
          setShowDocTipoDetails(docTipoKey !== "tipo_documento_3");
        }
      }

      if (anagrafica.addrPaeseId !== null && anagrafica.addrPaeseId !== 0) {
        const paese = paesi.filter(
          (item) => item.id === anagrafica.addrPaeseId
        )[0];
        if (paese.identificativo === "ITA") {
          if (
            anagrafica.addrProvinciaId !== null &&
            anagrafica.addrProvinciaId !== 0
          ) {
            const provincia = province.filter(
              (item) => item.id === anagrafica.addrProvinciaId
            )[0];
            await loadBaseComune(provincia.sigla);
          }
        }

        setIsAddrPaeseIta(paese.identificativo === "ITA");
      } else {
        anagrafica.addrPaeseId = paesi.filter(
          (x) => x.identificativo === "ITA"
        )[0].id;
        setIsAddrPaeseIta(true);
      }

      setCurrentAnagrafica(anagrafica);
      setCurrentAnagraficaOriginal(
        Object.assign(new anagraficaModel(), anagrafica)
      );

      let anagFascicolo = null;
      if (anagrafica.anagFascicoli.length > 1) {
        switch (tipoAnag) {
          case "cliente":
            anagFascicolo = anagrafica.anagFascicoli.filter(
              (x) => x.tipoAnagId === 1 || x.tipoAnagId === 2
            )[0];
            break;
          case "collegate":
            anagFascicolo = anagrafica.anagFascicoli.filter(
              (x) => x.tipoAnagId === 3 || x.tipoAnagId === 4
            )[0];
            break;
          case "titolare":
            anagFascicolo = anagrafica.anagFascicoli.filter(
              (x) => x.tipoAnagId === 5
            )[0];
            break;
          default:
            break;
        }
      } else {
        anagFascicolo = anagrafica.anagFascicoli[0];
      }
      setCurrentAnagraficaFascicolo(
        new anagraficaFascicoloModel(
          anagFascicolo.id,
          anagrafica.id,
          fascicolo.id,
          anagFascicolo.tipoAnagId,
          anagFascicolo.tipoPartId,
          anagFascicolo.tipoPartDesc,
          anagFascicolo.dataFine !== undefined ? anagFascicolo.dataFine : null,
          anagFascicolo.tipoRappId,
          anagFascicolo.tipoRappDesc
        )
      );
      if (anagrafica.addr2PaeseId !== null && anagrafica.addr2PaeseId !== 0) {
        const paese = paesi.filter(
          (item) => item.id === anagrafica.addr2PaeseId
        )[0];
        if (paese.identificativo === "ITA") {
          const provincia = province.filter(
            (item) => item.id === anagrafica.addr2ProvinciaId
          )[0];
          await loadBaseComune2(provincia.sigla);
        }

        setIsAddr2PaeseIta(paese.identificativo === "ITA");
      }

      let tipoAnagKey = tipoAnagrafiche.filter(
        (x) => x.id === anagrafica.anagFascicoli[0].tipoAnagId
      )[0].key;

      if (!isFromVersion) {
        if (
          tipoAnagKey === "tipo_anagrafica_1" ||
          tipoAnagKey === "tipo_anagrafica_2"
        ) {
          setTipoAnag("cliente");
        } else if (
          tipoAnagKey === "tipo_anagrafica_3" ||
          tipoAnagKey === "tipo_anagrafica_4"
        ) {
          setTipoAnag("collegate");
        } else if (tipoAnagKey === "tipo_anagrafica_5") {
          setTipoAnag("titolare");
        }
      }

      setIsPG(anagrafica.isPg);
      setAnagPep({
        IsNotPep: !anagrafica.isPep && !anagrafica.isNotPepMoreThanYear,
        IsPep: anagrafica.isPep && !anagrafica.isNotPepMoreThanYear,
        IsNotPepMoreThanYear: anagrafica.isNotPepMoreThanYear,
      });
      setIdToPerform(0);

      setShowTipoPartDesc(
        anagFascicolo.tipoPartId ===
          tipoPartecipazioni.filter(
            (x) => x.key === "tipo_partecipazione_10"
          )[0].id
      );

      //Setto lo storico
      if (anagHistory.length > 0) {
        let historyLastItem = anagHistory[0];

        if (historyLastItem !== null) {
          setEnableFormControls(
            anagrafica.versionN === historyLastItem.versionN
          );
        } else {
          setEnableFormControls(true);
        }

        setCurrentAnagraficheHistoryFilteredList(anagHistory);

        if (anagHistory.length > 0) {
          const newItemModel = Object.assign({}, anagHistory[0]);
          newItemModel.anagFascicoli = anagrafica.anagFascicoli;
          setLastSelectedAnag(newItemModel);
        } else {
          setLastSelectedAnag(anagrafica);
        }
      } else {
        setLastSelectedAnag(anagrafica);
      }

      //Invio una notifica tramite pub/sub interno
      postal.publish({
        channel: "fascicolo",
        topic: "anagrafica.detail",
        data: {
          anagrafica: anagrafica,
        },
      });

      //Chiudo il menù a tendina delle versioni
      handleClose();
      let date = new Date();
      date.setHours(0, 0, 0, 0);
      setIsCessato(
        anagFascicolo.dataFine !== null &&
          date >= new Date(anagFascicolo.dataFine).setHours(0, 0, 0, 0)
      );
    } catch (e) {
      AddLog(e.message, e.stack, "SelectAnagrafica");
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  const saveAnagrafica = async () => {
    try {
      setBackDropOpen(true);
      var errors = await checkDataBeforeSaving();
      if (errors.length <= 0) {
        //Se sto creando l'anagrafica
        if (currentAnagrafica.id === 0) {
          const dataIdent = new Date(currentAnagraficaIdent.identifiedOn);
          const dataIdentD = new Date(
            Date.UTC(
              dataIdent.getFullYear(),
              dataIdent.getMonth(),
              dataIdent.getDate(),
              1,
              0,
              0
            )
          );

          //Salvo le modifiche su Workspace compresi indirizzi
          let officeIds = ["", ""];
          var workspaceId = await savePersonalDataOnWorkspace(
            currentAnagrafica
          );
          var hasOnlyOneAddress =
            currentAnagrafica.addrIndirizzo === "" ||
            currentAnagrafica.addrIndirizzo === null ||
            currentAnagrafica.addr2Indirizzo === "" ||
            currentAnagrafica.addr2Indirizzo === null;
          var officeDatas = generateDatasForOffice(hasOnlyOneAddress);
          for (let i = 0; i < officeDatas.length; i++) {
            officeIds[i] = await saveOfficeOnWorkSpace(
              workspaceId,
              officeDatas[i]
            );
          }

          var amlId = await saveAmlDatasOnWorkSpace(workspaceId);

          const addAnagResponse = await apiAddAnagrafica.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            parentId: 0,
            isPg: currentAnagrafica.isPg,
            cf: currentAnagrafica.cf,
            nome: currentAnagrafica.nome,
            cognome: currentAnagrafica.cognome,
            genereId: currentAnagrafica.genereId,
            nascitaLuogo: currentAnagrafica.nascitaLuogo,
            nascitaData: currentAnagrafica.nascitaData,
            professione: currentAnagrafica.professione,
            denominazione: currentAnagrafica.denominazione,
            pIva: currentAnagrafica.pIva,
            reaComune: currentAnagrafica.reaComune,
            reaN: currentAnagrafica.reaN,
            addrPaeseId: currentAnagrafica.addrPaeseId,
            addrProvinciaId: currentAnagrafica.addrProvinciaId,
            addrProvincia: currentAnagrafica.addrProvincia,
            addrComuneId: currentAnagrafica.addrComuneId,
            addrComune: currentAnagrafica.addrComune,
            addrIndirizzo: currentAnagrafica.addrIndirizzo,
            addrN: currentAnagrafica.addrN,
            addrCap: currentAnagrafica.addrCap,
            addr2PaeseId: currentAnagrafica.addr2PaeseId,
            addr2ProvinciaId: currentAnagrafica.addr2ProvinciaId,
            addr2Provincia: currentAnagrafica.addr2Provincia,
            addr2ComuneId: currentAnagrafica.addr2ComuneId,
            addr2Comune: currentAnagrafica.addr2Comune,
            addr2Indirizzo: currentAnagrafica.addr2Indirizzo,
            addr2N: currentAnagrafica.addr2N,
            addr2Cap: currentAnagrafica.addr2Cap,
            isPep: currentAnagrafica.isPep,
            isNotPepMoreThanYear: currentAnagrafica.isNotPepMoreThanYear,
            versionN: currentAnagrafica.versionN,
            versionDate: dataIdentD,
            isDeleted: false,
            pepCarica: currentAnagrafica.pepCarica,
            workspaceId: workspaceId,
            AmlId: amlId === undefined ? null : amlId,
            Office1Id: officeIds[0] ? officeIds[0] : null,
            Office2Id: officeIds[1] ? officeIds[1] : null,
            pepRuolo: currentAnagrafica.pepRuolo,
          });
          if (addAnagResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result = addAnagResponse.data.data.insert_anagrafica_one;

            let anagraficaResultModel = new anagraficaModel(
              result.Id,
              result.ParentId,
              result.IsPg,
              result.Cf,
              result.Nome,
              result.Cognome,
              result.GenereId,
              result.NascitaLuogo,
              result.NascitaData,
              result.Professione,
              result.Denominazione,
              result.PIva,
              result.ReaComune,
              result.ReaN,
              result.AddrPaeseId,
              result.AddrProvinciaId,
              result.AddrProvincia,
              result.AddrComuneId,
              result.AddrComune,
              result.AddrIndirizzo,
              result.AddrN,
              result.AddrCap,
              result.Addr2PaeseId,
              result.Addr2ProvinciaId,
              result.Addr2Provincia,
              result.Addr2ComuneId,
              result.Addr2Comune,
              result.Addr2Indirizzo,
              result.Addr2N,
              result.Addr2Cap,
              result.IsPep,
              result.IsNotPepMoreThanYear,
              result.VersionN,
              result.VersionDate,
              result.IsDeleted,
              [],
              [],
              result.PepCarica,
              result.PepRuolo,
              result.CreatedBy,
              result.ModifiedBy,
              result.anagrafica_fascicolos,
              result.WorkspaceId,
              result.AmlId,
              result.Office1Id,
              result.Office2Id
            );

            //Aggiorno il nome del fascicolo solo se l'anagrafica è di tipo Cliente
            const tipoAnagKey = baseTipoAnagList.filter(
              (bta) => bta.id === currentAnagraficaFascicolo.tipoAnagId
            )[0].key;
            if (
              tipoAnagKey === "tipo_anagrafica_1" ||
              tipoAnagKey === "tipo_anagrafica_2"
            ) {
              await apiUpdateFascicolo.fetch({
                id: fascicolo.id,
                name: anagraficaResultModel.denominazione,
                date: fascicolo.date,
                isPrivate: fascicolo.isPrivate,
              });
            }

            //Aggiungo l'identificazione
            const dataDocRilIl = new Date(currentAnagraficaIdent.docRilData);
            const dataDocRilIlD = new Date(
              Date.UTC(
                dataDocRilIl.getFullYear(),
                dataDocRilIl.getMonth(),
                dataDocRilIl.getDate(),
                1,
                0,
                0
              )
            );

            const dataDocScad = new Date(currentAnagraficaIdent.docScad);
            const dataDocScadD = new Date(
              Date.UTC(
                dataDocScad.getFullYear(),
                dataDocScad.getMonth(),
                dataDocScad.getDate(),
                1,
                0,
                0
              )
            );

            const addAnagIdentResponse = await apiAddAnagraficaIdent.fetch({
              anagraficaId: result.Id,
              modalitaIdentId: currentAnagraficaIdent.modalitaIdentId,
              docTipoId: currentAnagraficaIdent.docTipoId,
              docN: currentAnagraficaIdent.docN,
              docRilDa: currentAnagraficaIdent.docRilDa,
              docRilData: dataDocRilIlD,
              docScad: dataDocScadD,
              apNome: currentAnagraficaIdent.apNome,
              apCf: currentAnagraficaIdent.apCf,
              identifiedByUser: currentAnagraficaIdent.identifiedByUser,
              identifiedOn: dataIdentD,
            });

            const arrItemsIdent = [];
            if (addAnagIdentResponse.data.errors !== undefined) {
            } else {
              const itemI =
                addAnagIdentResponse.data.data
                  .insert_anagrafica_identificazione_one;
              const anagIdentModel = new anagraficaIdentificazioneModel(
                itemI.Id,
                itemI.base_tipo_identificazione.Id,
                itemI.DocTipoId,
                itemI.DocN,
                itemI.DocRilDa,
                itemI.DocRilData,
                itemI.DocScad,
                itemI.ApNome,
                itemI.ApCf,
                itemI.IdentifiedByUser,
                itemI.IdentifiedOn,
                itemI.CreatedOn,
                itemI.ModifiedOn,
                itemI.CreatedBy,
                itemI.ModifiedBy
              );
              arrItemsIdent.push(anagIdentModel);
            }

            anagraficaResultModel.identificazioni = arrItemsIdent;

            //Aggiungo i risultati delle banche dati se ce ne sono
            var itemsToPerform = anagraficheBancaDatiList.filter(
              (x) => x.anagraficaId === 0
            );

            if (itemsToPerform.length > 0) {
              const bancheDatiItems = Object.assign(
                [],
                anagraficheBancaDatiList
              );
              const newBancheDatiItems = bancheDatiItems.filter(
                (item) => item.anagraficaId !== 0
              );

              for (var i = 0; i < itemsToPerform.length; i++) {
                const item = itemsToPerform[i];
                await apiAddAnagraficaBancaDati.fetch({
                  anagraficaId: result.Id,
                  repository: item.repository,
                  infos: JSON.stringify(item.infos),
                });

                if (addAnagResponse.data.errors !== undefined) {
                } else {
                  //Sostituisco l'id anagrafica da 0 all'id anagrafica appena creato
                  item.anagraficaId = anagraficaResultModel.id;
                  newBancheDatiItems.push(item);
                }
              }

              setAnagraficheBancaDatiList(newBancheDatiItems);
            }

            //Aggiungo o aggiorno la relazione tra anagrafica e fascicolo
            let arrItemsFasc = Object.assign(
              [],
              currentAnagrafica.anagFascicoli
            );
            if (currentAnagraficaFascicolo.id === 0) {
              const addAnagFascicoloResponse =
                await apiAddAnagraficaFascicolo.fetch({
                  anagraficaId: result.Id,
                  fascicoloId: fascicolo.id,
                  tipoAnagId: currentAnagraficaFascicolo.tipoAnagId,
                  tipoPartId: currentAnagraficaFascicolo.tipoPartId,
                  tipoPartDesc: currentAnagraficaFascicolo.tipoPartDesc,
                  dataFine: currentAnagraficaFascicolo.dataFine !== undefined ? currentAnagraficaFascicolo.dataFine : null,
                  tipoRappId: currentAnagraficaFascicolo.tipoRappId,
                  tipoRappDesc: currentAnagraficaFascicolo.tipoRappDesc,
                });
              if (addAnagFascicoloResponse.data.errors !== undefined) {
              } else {
                const result =
                  addAnagFascicoloResponse.data.data
                    .insert_anagrafica_fascicolo_one;
                arrItemsFasc.push(
                  new anagraficaFascicoloModel(
                    result.Id,
                    result.AnagraficaId,
                    fascicolo.id,
                    result.TipoAnagId,
                    result.TipoPartId,
                    result.TipoPartDesc,
                    result.DataFine,
                    result.TipoRappId,
                    result.TipoRappDesc
                  )
                );
              }
            } else {
              const updateAnagFascicoloResponse =
                await apiUpdateAnagraficaFascicolo.fetch({
                  id: currentAnagraficaFascicolo.id,
                  anagraficaId: result.Id,
                  fascicoloId: fascicolo.id,
                  tipoAnagId: currentAnagraficaFascicolo.tipoAnagId,
                  tipoPartId: currentAnagraficaFascicolo.tipoPartId,
                  tipoPartDesc: currentAnagraficaFascicolo.tipoPartDesc,
                  dataFine: currentAnagraficaFascicolo.dataFine,
                  tipoRappId: currentAnagraficaFascicolo.tipoRappId,
                  tipoRappDesc: currentAnagraficaFascicolo.tipoRappDesc,
                });
              if (updateAnagFascicoloResponse.data.errors !== undefined) {
              } else {
                const result =
                  updateAnagFascicoloResponse.data.data
                    .update_anagrafica_fascicolo.returning[0];

                const currentItemIndex = arrItemsFasc.findIndex(
                  (x) => x.id === result.Id
                );
                let updatedItem = Object.assign(
                  {},
                  arrItemsFasc[currentItemIndex]
                );
                updatedItem = new anagraficaFascicoloModel(
                  result.Id,
                  result.AnagraficaId,
                  fascicolo.id,
                  result.TipoAnagId,
                  result.TipoPartId,
                  result.TipoPartDesc,
                  result.DataFine,
                  result.TipoRappId,
                  result.TipoRappDesc
                );
                const newArr = arrItemsFasc.slice();
                newArr[currentItemIndex] = updatedItem;
                arrItemsFasc = newArr;
              }
            }
            anagraficaResultModel.anagFascicoli = arrItemsFasc;

            //Aggiungo la Audit per tracciare l'azione
            const newValues = anagraficaResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaAnagrafica,
              operation: "Create",
              entityType: "anagrafica",
              entityId: result.Id.toString(),
              oldValues: "",
              newValues: JSON.stringify(newValues),
            });

            //Aggiungo l'anagrafica all'elenco
            //Clienti
            const clienti = Object.assign([], currentAnagraficheClientiList);
            const collegate = Object.assign(
              [],
              currentAnagraficheCollegateList
            );
            const titolari = Object.assign([], currentAnagraficheTitEffList);
            if (
              tipoAnagKey === "tipo_anagrafica_1" ||
              tipoAnagKey === "tipo_anagrafica_2"
            ) {
              clienti.push(anagraficaResultModel);
              setCurrentAnagraficheClientiList(clienti);
            }

            //Legali rappresentanti e Procuratori delegati
            else if (
              tipoAnagKey === "tipo_anagrafica_3" ||
              tipoAnagKey === "tipo_anagrafica_4"
            ) {
              collegate.push(anagraficaResultModel);
              setCurrentAnagraficheCollegateList(collegate);
            }

            //Titolari effettivi
            else if (tipoAnagKey === "tipo_anagrafica_5") {
              titolari.push(anagraficaResultModel);
              setCurrentAnagraficheTitEffList(titolari);
            }
            setLastSelectedAnag(anagraficaResultModel);
            setIsDataLoaded(false);

            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        } else {
          //Salvo le modifiche su Workspace e relativi indirizzi con i metodi patch
          let anagrafica = null;
          let anagResponse = null;

          if (currentAnagrafica.isPg) {
            anagResponse = await apiAnagraficheByCfPG.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              cf: "%" + currentAnagrafica.cf + "%",
              pIva: "%" + currentAnagrafica.pIva + "%",
              denominazione: "%" + currentAnagrafica.denominazione + "%",
            });
          } else {
            anagResponse = await apiAnagraficheByCf.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              cf: "%" + currentAnagrafica.cf + "%",
              nome: "%%",
              cognome: "%%",
            });
          }
          if (
            anagResponse !== null &&
            anagResponse.data.data.anagrafica.length > 0
          ) {
            anagrafica = anagResponse.data.data.anagrafica[0];
          }

          let officeIds = [
            anagrafica.Office1Id !== null ? anagrafica.Office1Id : "",
            anagrafica.Office2Id !== null ? anagrafica.Office2Id : "",
          ];
          let amlId = null;
          let workspaceId = null;
          if (anagrafica !== null) {
            if (anagrafica.WorkspaceId) {
              let responseId = await updatePersonalDataOnWorkspace(
                anagrafica.WorkspaceId
              );
              workspaceId = responseId ? responseId : anagrafica.WorkspaceId;
            } else {
              let responseId = await savePersonalDataOnWorkspace(
                currentAnagrafica
              );
              workspaceId = responseId ? responseId : anagrafica.WorkspaceId;
            }

            hasOnlyOneAddress =
              currentAnagrafica.addrIndirizzo === "" ||
              currentAnagrafica.addrIndirizzo === null ||
              currentAnagrafica.addr2Indirizzo === "" ||
              currentAnagrafica.addr2Indirizzo === null;
            officeDatas = generateDatasForOffice(hasOnlyOneAddress);
            if (anagrafica.Office1Id) {
              officeIds[0] = await updateOfficesOnWorkSpace(
                workspaceId,
                anagrafica.Office1Id,
                true
              );
            } else {
              officeIds[0] = await saveOfficeOnWorkSpace(
                workspaceId,
                officeDatas[0]
              );
            }

            if (!hasOnlyOneAddress) {
              if (anagrafica.Office2Id) {
                officeIds[1] = await updateOfficesOnWorkSpace(
                  workspaceId,
                  anagrafica.Office2Id,
                  false
                );
              } else {
                officeIds[1] = await saveOfficeOnWorkSpace(
                  workspaceId,
                  officeDatas[1]
                );
              }
            }
            if (anagrafica.AmlId) {
              amlId = await updateAmlDatasOnWorkSpace(
                workspaceId,
                anagrafica.AmlId
              );
            } else {
              amlId = await saveAmlDatasOnWorkSpace(workspaceId);
            }
          }

          const updateAnag = await apiUpdateAnagrafica.fetch({
            id: currentAnagrafica.id,
            tenantId: tenantId,
            aziendaId: aziendaId,
            parentId: currentAnagrafica.parentId,
            isPg: currentAnagrafica.isPg,
            cf: currentAnagrafica.cf,
            nome: currentAnagrafica.nome,
            cognome: currentAnagrafica.cognome,
            genereId: currentAnagrafica.genereId,
            nascitaLuogo: currentAnagrafica.nascitaLuogo,
            nascitaData: currentAnagrafica.nascitaData,
            professione: currentAnagrafica.professione,
            denominazione: currentAnagrafica.denominazione,
            pIva: currentAnagrafica.pIva,
            reaComune: currentAnagrafica.reaComune,
            reaN: currentAnagrafica.reaN,
            addrPaeseId: currentAnagrafica.addrPaeseId,
            addrProvinciaId: currentAnagrafica.addrProvinciaId,
            addrProvincia: currentAnagrafica.addrProvincia,
            addrComuneId: currentAnagrafica.addrComuneId,
            addrComune: currentAnagrafica.addrComune,
            addrIndirizzo: currentAnagrafica.addrIndirizzo,
            addrN: currentAnagrafica.addrN,
            addrCap: currentAnagrafica.addrCap,
            addr2PaeseId: currentAnagrafica.addr2PaeseId,
            addr2ProvinciaId: currentAnagrafica.addr2ProvinciaId,
            addr2Provincia: currentAnagrafica.addr2Provincia,
            addr2ComuneId: currentAnagrafica.addr2ComuneId,
            addr2Comune: currentAnagrafica.addr2Comune,
            addr2Indirizzo: currentAnagrafica.addr2Indirizzo,
            addr2N: currentAnagrafica.addr2N,
            addr2Cap: currentAnagrafica.addr2Cap,
            isPep: currentAnagrafica.isPep,
            isNotPepMoreThanYear: currentAnagrafica.isNotPepMoreThanYear,
            versionN: currentAnagrafica.versionN,
            versionDate: currentAnagrafica.versionDate,
            isDeleted: false,
            pepCarica: currentAnagrafica.pepCarica,
            pepRuolo: currentAnagrafica.pepRuolo,
            workspaceId: workspaceId ? workspaceId : anagrafica.WorkspaceId,
            AmlId: amlId,
            Office1Id: officeIds[0] ? officeIds[0] : null,
            Office2Id: officeIds[1] ? officeIds[1] : null,
          });

          if (updateAnag.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result = updateAnag.data.data.update_anagrafica.returning[0];

            let anagraficaResultModel = new anagraficaModel(
              result.Id,
              result.ParentId,
              result.IsPg,
              result.Cf,
              result.Nome,
              result.Cognome,
              result.GenereId,
              result.NascitaLuogo,
              result.NascitaData,
              result.Professione,
              result.Denominazione,
              result.PIva,
              result.ReaComune,
              result.ReaN,
              result.AddrPaeseId,
              result.AddrProvinciaId,
              result.AddrProvincia,
              result.AddrComuneId,
              result.AddrComune,
              result.AddrIndirizzo,
              result.AddrN,
              result.AddrCap,
              result.Addr2PaeseId,
              result.Addr2ProvinciaId,
              result.Addr2Provincia,
              result.Addr2ComuneId,
              result.Addr2Comune,
              result.Addr2Indirizzo,
              result.Addr2N,
              result.Addr2Cap,
              result.IsPep,
              result.IsNotPepMoreThanYear,
              result.VersionN,
              result.VersionDate,
              result.IsDeleted,
              [],
              [],
              result.PepCarica,
              result.PepRuolo,
              result.CreatedBy,
              result.ModifiedBy,
              result.anagrafica_fascicolos,
              result.WorkspaceId,
              result.AmlId,
              result.Office1Id,
              result.Office2Id
            );

            //Aggiorno la data del fascicolo solo se l'anagrafica è di tipo Cliente
            const tipoAnagKey = baseTipoAnagList.filter(
              (bta) => bta.id === currentAnagraficaFascicolo.tipoAnagId
            )[0].key;
            if (
              tipoAnagKey === "tipo_anagrafica_1" ||
              tipoAnagKey === "tipo_anagrafica_2"
            ) {
              //Aggiorno la data del fascicolo solo se la versione è la numero 1
              let dataFascicolo = fascicolo.date;
              if (result.VersionN === 1) {
                const dataIdent = new Date(currentAnagraficaIdent.identifiedOn);
                const dataIdentD = new Date(
                  Date.UTC(
                    dataIdent.getFullYear(),
                    dataIdent.getMonth(),
                    dataIdent.getDate(),
                    1,
                    0,
                    0
                  )
                );
                dataFascicolo = dataIdentD;
              }

              await apiUpdateFascicolo.fetch({
                id: fascicolo.id,
                name: anagraficaResultModel.denominazione,
                date: dataFascicolo,
                isPrivate: fascicolo.isPrivate,
              });
            }

            //Aggiungo la Audit per tracciare l'azione
            const newValues = anagraficaResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaAnagrafica,
              operation: "Update",
              entityType: "anagrafica",
              entityId: result.Id.toString(),
              oldValues: JSON.stringify(currentAnagraficaOriginal),
              newValues: JSON.stringify(newValues),
            });

            //Aggiungo o aggiorno l'identificazione
            if (
              currentAnagraficaIdent.id > 0 &&
              currentAnagraficaIdent.identifiedByUser === loggedUserId
            ) {
              const dataIdent = new Date(currentAnagraficaIdent.identifiedOn);
              const dataIdentD = new Date(
                Date.UTC(
                  dataIdent.getFullYear(),
                  dataIdent.getMonth(),
                  dataIdent.getDate(),
                  1,
                  0,
                  0
                )
              );

              let dataDocRilIlD = null;
              if (currentAnagraficaIdent.docRilData !== null) {
                const dataDocRilIl = new Date(
                  currentAnagraficaIdent.docRilData
                );
                dataDocRilIlD = new Date(
                  Date.UTC(
                    dataDocRilIl.getFullYear(),
                    dataDocRilIl.getMonth(),
                    dataDocRilIl.getDate(),
                    1,
                    0,
                    0
                  )
                );
              }

              let dataDocScadD = null;
              if (currentAnagraficaIdent.docScad !== null) {
                const dataDocScad = new Date(currentAnagraficaIdent.docScad);
                dataDocScadD = new Date(
                  Date.UTC(
                    dataDocScad.getFullYear(),
                    dataDocScad.getMonth(),
                    dataDocScad.getDate(),
                    1,
                    0,
                    0
                  )
                );
              }

              const updateAnagIdent = await apiUpdateAnagraficaIdent.fetch({
                id: currentAnagraficaIdent.id,
                anagraficaId: result.Id,
                modalitaIdentId: currentAnagraficaIdent.modalitaIdentId,
                docTipoId: currentAnagraficaIdent.docTipoId,
                docN: currentAnagraficaIdent.docN,
                docRilDa: currentAnagraficaIdent.docRilDa,
                docRilData: dataDocRilIlD,
                docScad: dataDocScadD,
                apNome: currentAnagraficaIdent.apNome,
                apCf: currentAnagraficaIdent.apCf,
                identifiedByUser: currentAnagraficaIdent.identifiedByUser,
                identifiedOn: dataIdentD,
              });

              const arrItemsIdent = [];
              if (updateAnagIdent.data.errors !== undefined) {
              } else {
                const itemI =
                  updateAnagIdent.data.data.update_anagrafica_identificazione
                    .returning[0];
                const anagIdentModel = new anagraficaIdentificazioneModel(
                  itemI.Id,
                  itemI.base_tipo_identificazione.Id,
                  itemI.DocTipoId,
                  itemI.DocN,
                  itemI.DocRilDa,
                  itemI.DocRilData,
                  itemI.DocScad,
                  itemI.ApNome,
                  itemI.ApCf,
                  itemI.IdentifiedByUser,
                  itemI.IdentifiedOn,
                  itemI.CreatedOn,
                  itemI.ModifiedOn,
                  itemI.CreatedBy,
                  itemI.ModifiedBy
                );
                arrItemsIdent.push(anagIdentModel);
              }
              anagraficaResultModel.identificazioni = arrItemsIdent;

              //Aggiorno l'elenco dei Clienti
              if (
                tipoAnagKey === "tipo_anagrafica_1" ||
                tipoAnagKey === "tipo_anagrafica_2"
              ) {
                const clientiList = Object.assign(
                  [],
                  currentAnagraficheClientiList
                );
                clientiList.pop((x) => x.cf === anagraficaResultModel.cf);
                clientiList.push(anagraficaResultModel);
                setCurrentAnagraficheClientiList(clientiList);
              }

              //Aggiorno l'elenco delle anagrafiche collegate
              else if (
                tipoAnagKey === "tipo_anagrafica_3" ||
                tipoAnagKey === "tipo_anagrafica_4"
              ) {
                const collegatiList = Object.assign(
                  [],
                  currentAnagraficheCollegateList
                );
                collegatiList.pop((x) => x.cf === anagraficaResultModel.cf);
                collegatiList.push(anagraficaResultModel);
                setCurrentAnagraficheCollegateList(collegatiList);
              }

              //Aggiorno l'elenco dei titolari effettivi
              else if (tipoAnagKey === "tipo_anagrafica_5") {
                const titEffList = Object.assign(
                  [],
                  currentAnagraficheTitEffList
                );
                titEffList.pop((x) => x.cf === anagraficaResultModel.cf);
                titEffList.push(anagraficaResultModel);
                setCurrentAnagraficheTitEffList(titEffList);
              }

              setCurrentAnagrafica(anagraficaResultModel);
            } else {
              const dataIdent = new Date(currentAnagraficaIdent.identifiedOn);
              const dataIdentD = new Date(
                Date.UTC(
                  dataIdent.getFullYear(),
                  dataIdent.getMonth(),
                  dataIdent.getDate(),
                  1,
                  0,
                  0
                )
              );

              let dataDocRilIlD = null;
              if (currentAnagraficaIdent.docRilData !== null) {
                const dataDocRilIl = new Date(
                  currentAnagraficaIdent.docRilData
                );
                dataDocRilIlD = new Date(
                  Date.UTC(
                    dataDocRilIl.getFullYear(),
                    dataDocRilIl.getMonth(),
                    dataDocRilIl.getDate(),
                    1,
                    0,
                    0
                  )
                );
              }

              let dataDocScadD = null;
              if (currentAnagraficaIdent.docScad !== null) {
                const dataDocScad = new Date(currentAnagraficaIdent.docScad);
                dataDocScadD = new Date(
                  Date.UTC(
                    dataDocScad.getFullYear(),
                    dataDocScad.getMonth(),
                    dataDocScad.getDate(),
                    1,
                    0,
                    0
                  )
                );
              }

              const addAnagIdentResponse = await apiAddAnagraficaIdent.fetch({
                anagraficaId: result.Id,
                modalitaIdentId: currentAnagraficaIdent.modalitaIdentId,
                docTipoId: currentAnagraficaIdent.docTipoId,
                docN: currentAnagraficaIdent.docN,
                docRilDa: currentAnagraficaIdent.docRilDa,
                docRilData: dataDocRilIlD,
                docScad: dataDocScadD,
                apNome: currentAnagraficaIdent.apNome,
                apCf: currentAnagraficaIdent.apCf,
                identifiedByUser: currentAnagraficaIdent.identifiedByUser,
                identifiedOn: dataIdentD,
              });

              const arrItemsIdent = [];
              if (addAnagIdentResponse.data.errors !== undefined) {
              } else {
                const itemI =
                  addAnagIdentResponse.data.data
                    .insert_anagrafica_identificazione_one;
                const anagIdentModel = new anagraficaIdentificazioneModel(
                  itemI.Id,
                  itemI.base_tipo_identificazione.Id,
                  itemI.DocTipoId,
                  itemI.DocN,
                  itemI.DocRilDa,
                  itemI.DocRilData,
                  itemI.DocScad,
                  itemI.ApNome,
                  itemI.ApCf,
                  itemI.IdentifiedByUser,
                  itemI.IdentifiedOn,
                  itemI.CreatedOn,
                  itemI.ModifiedOn,
                  itemI.CreatedBy,
                  itemI.ModifiedBy
                );
                arrItemsIdent.push(anagIdentModel);
              }
              anagraficaResultModel.identificazioni = arrItemsIdent;

              //Aggiorno l'elenco dei Clienti
              if (
                tipoAnagKey === "tipo_anagrafica_1" ||
                tipoAnagKey === "tipo_anagrafica_2"
              ) {
                const clientiList = Object.assign(
                  [],
                  currentAnagraficheClientiList
                );
                clientiList.pop((x) => x.cf === anagraficaResultModel.cf);
                clientiList.push(anagraficaResultModel);
                setCurrentAnagraficheClientiList(clientiList);
              }

              //Aggiorno l'elenco delle anagrafiche collegate
              else if (
                tipoAnagKey === "tipo_anagrafica_3" ||
                tipoAnagKey === "tipo_anagrafica_4"
              ) {
                const collegatiList = Object.assign(
                  [],
                  currentAnagraficheCollegateList
                );
                collegatiList.pop((x) => x.cf === anagraficaResultModel.cf);
                collegatiList.push(anagraficaResultModel);
                setCurrentAnagraficheCollegateList(collegatiList);
              }

              //Aggiorno l'elenco dei titolari effettivi
              else if (tipoAnagKey === "tipo_anagrafica_5") {
                const titEffList = Object.assign(
                  [],
                  currentAnagraficheTitEffList
                );
                titEffList.pop((x) => x.cf === anagraficaResultModel.cf);
                titEffList.push(anagraficaResultModel);
                setCurrentAnagraficheTitEffList(titEffList);
              }

              setCurrentAnagrafica(anagraficaResultModel);
            }

            //Aggiungo o aggiorno la relazione tra anagrafica e fascicolo
            let arrItemsFasc = Object.assign(
              [],
              currentAnagrafica.anagFascicoli
            );

            if (currentAnagraficaFascicolo.id === 0) {
              const addAnagFascicoloResponse =
                await apiAddAnagraficaFascicolo.fetch({
                  anagraficaId: result.Id,
                  fascicoloId: fascicolo.id,
                  tipoAnagId: currentAnagraficaFascicolo.tipoAnagId,
                  tipoPartId: currentAnagraficaFascicolo.tipoPartId,
                  tipoPartDesc: currentAnagraficaFascicolo.tipoPartDesc,
                  dataFine: currentAnagraficaFascicolo.dataFine,
                  tipoRappId: currentAnagraficaFascicolo.tipoRappId,
                  tipoRappDesc: currentAnagraficaFascicolo.tipoRappDesc,
                });
              if (addAnagFascicoloResponse.data.errors !== undefined) {
              } else {
                const result =
                  addAnagFascicoloResponse.data.data
                    .insert_anagrafica_fascicolo_one;
                arrItemsFasc.push(
                  new anagraficaFascicoloModel(
                    result.Id,
                    result.AnagraficaId,
                    fascicolo.id,
                    result.TipoAnagId,
                    result.TipoPartId,
                    result.TipoPartDesc,
                    result.DataFine,
                    result.TipoRappId,
                    result.TipoRappDesc
                  )
                );
              }

              if (currentAnagrafica.parentId !== 0) {
                const addAnagFascicoloResponse =
                  await apiAddAnagraficaFascicolo.fetch({
                    anagraficaId: currentAnagrafica.parentId,
                    fascicoloId: fascicolo.id,
                    tipoAnagId: currentAnagraficaFascicolo.tipoAnagId,
                    tipoPartId: currentAnagraficaFascicolo.tipoPartId,
                    tipoPartDesc: currentAnagraficaFascicolo.tipoPartDesc,
                    dataFine: currentAnagraficaFascicolo.dataFine,
                    tipoRappId: currentAnagraficaFascicolo.tipoRappId,
                    tipoRappDesc: currentAnagraficaFascicolo.tipoRappDesc,
                  });
                if (addAnagFascicoloResponse.data.errors !== undefined) {
                } else {
                  const result =
                    addAnagFascicoloResponse.data.data
                      .insert_anagrafica_fascicolo_one;
                  arrItemsFasc.push(
                    new anagraficaFascicoloModel(
                      result.Id,
                      result.AnagraficaId,
                      fascicolo.id,
                      result.TipoAnagId,
                      result.TipoPartId,
                      result.TipoPartDesc,
                      result.DataFine,
                      result.TipoRappId,
                      result.TipoRappDesc
                    )
                  );
                }
              }
            } else {
              const updateAnagFascicoloResponse =
                await apiUpdateAnagraficaFascicolo.fetch({
                  id: currentAnagraficaFascicolo.id,
                  anagraficaId: result.Id,
                  fascicoloId: fascicolo.id,
                  tipoAnagId: currentAnagraficaFascicolo.tipoAnagId,
                  tipoPartId: currentAnagraficaFascicolo.tipoPartId,
                  tipoPartDesc: currentAnagraficaFascicolo.tipoPartDesc,
                  dataFine: currentAnagraficaFascicolo.dataFine,
                  tipoRappId: currentAnagraficaFascicolo.tipoRappId,
                  tipoRappDesc: currentAnagraficaFascicolo.tipoRappDesc,
                });

              if (updateAnagFascicoloResponse.data.errors !== undefined) {
              } else {
                const result =
                  updateAnagFascicoloResponse.data.data
                    .update_anagrafica_fascicolo.returning[0];
                const currentItemIndex = arrItemsFasc.findIndex(
                  (x) => x.id === result.Id
                );
                let updatedItem = Object.assign(
                  {},
                  arrItemsFasc[currentItemIndex]
                );
                updatedItem = new anagraficaFascicoloModel(
                  result.Id,
                  result.AnagraficaId,
                  fascicolo.id,
                  result.TipoAnagId,
                  result.TipoPartId,
                  result.TipoPartDesc,
                  result.DataFine,
                  result.TipoRappId,
                  result.TipoRappDesc
                );
                let newArr = arrItemsFasc.slice();
                newArr[currentItemIndex] = updatedItem;
                arrItemsFasc = newArr;
              }
            }
            anagraficaResultModel.anagFascicoli = arrItemsFasc;
            setLastSelectedAnag(anagraficaResultModel);
            setIsDataLoaded(false);
            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        }
      } else {
        for (let i = 0; i < errors.length; i++) {
          enqueueSnackbar(errors[i], { variant: "error" });
        }
      }
    } catch (e) {
      AddLog(e.message, e.stack, "SaveAnagrafica");
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      reloadStatusFascicolo();
    }
  };
  const saveAnagraficaVersione = async () => {
    try {
      setBackDropOpen(true);
      var errors = await checkDataBeforeSaving();

      if (errors.length <= 0) {
        if (idToPerform > 0) {
          const parentId =
            currentAnagraficaNewVer.parentId > 0
              ? currentAnagraficaNewVer.parentId
              : currentAnagraficaNewVer.id;

          const dataVersione = new Date(currentAnagraficaNewVer.versionDate);
          const dataVersioneD = new Date(
            Date.UTC(
              dataVersione.getFullYear(),
              dataVersione.getMonth(),
              dataVersione.getDate(),
              1,
              0,
              0
            )
          );

          //Salvo le modifiche su Workspace
          let anagrafica = null;
          let anagResponse = null;
          if (currentAnagraficaNewVer.isPg) {
            anagResponse = await apiAnagraficheByCfPG.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              cf: `%${currentAnagraficaNewVer.cf}%`,
              pIva: `%${currentAnagraficaNewVer.pIva}%`,
              denominazione: `%${currentAnagraficaNewVer.denominazione}%`,
            });
          } else {
            anagResponse = await apiAnagraficheByCf.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              cf: "%" + currentAnagraficaNewVer.cf + "%",
              nome: "%%",
              cognome: "%%",
            });
          }
          if (
            anagResponse !== null &&
            anagResponse.data.data.anagrafica.length > 0
          ) {
            anagrafica = anagResponse.data.data.anagrafica[0];
          }

          let officeIds = ["", ""];
          let amlId = null;
          let workspaceId = null;
          if (anagrafica !== null) {
            if (anagrafica.WorkspaceId) {
              let responseId = await updatePersonalDataOnWorkspace(
                anagrafica.WorkspaceId
              );
              workspaceId = responseId ? responseId : anagrafica.WorkspaceId;
            } else {
              let responseId = await savePersonalDataOnWorkspace(
                currentAnagrafica
              );
              workspaceId = responseId ? responseId : anagrafica.WorkspaceId;
            }

            var hasOnlyOneAddress =
              currentAnagrafica.addrIndirizzo === "" ||
              currentAnagrafica.addrIndirizzo === null ||
              currentAnagrafica.addr2Indirizzo === "" ||
              currentAnagrafica.addr2Indirizzo === null;
            var officeDatas = generateDatasForOffice(hasOnlyOneAddress);
            if (anagrafica.Office1Id) {
              officeIds[0] = await updateOfficesOnWorkSpace(
                workspaceId,
                anagrafica.Office1Id,
                true
              );
            } else {
              officeIds[0] = await saveOfficeOnWorkSpace(
                workspaceId,
                officeDatas[0]
              );
            }

            if (!hasOnlyOneAddress) {
              if (anagrafica.Office2Id) {
                officeIds[1] = await updateOfficesOnWorkSpace(
                  workspaceId,
                  anagrafica.Office2Id,
                  false
                );
              } else {
                officeIds[1] = await saveOfficeOnWorkSpace(
                  workspaceId,
                  officeDatas[1]
                );
              }
            }

            if (anagrafica.AmlId) {
              amlId = await updateAmlDatasOnWorkSpace(
                workspaceId,
                anagrafica.AmlId
              );
            } else {
              amlId = await saveAmlDatasOnWorkSpace(workspaceId);
            }
          }

          const addAnagResponse = await apiAddAnagrafica.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            parentId: parentId,
            isPg: currentAnagraficaNewVer.isPg,
            cf: currentAnagraficaNewVer.cf,
            nome: currentAnagraficaNewVer.nome,
            cognome: currentAnagraficaNewVer.cognome,
            genereId: currentAnagraficaNewVer.genereId,
            nascitaLuogo: currentAnagraficaNewVer.nascitaLuogo,
            nascitaData: currentAnagraficaNewVer.nascitaData,
            professione: currentAnagraficaNewVer.professione,
            denominazione: currentAnagraficaNewVer.denominazione,
            pIva: currentAnagraficaNewVer.pIva,
            reaComune: currentAnagraficaNewVer.reaComune,
            reaN: currentAnagraficaNewVer.reaN,
            addrPaeseId: currentAnagraficaNewVer.addrPaeseId,
            addrProvinciaId: currentAnagraficaNewVer.addrProvinciaId,
            addrProvincia: currentAnagraficaNewVer.addrProvincia,
            addrComuneId: currentAnagraficaNewVer.addrComuneId,
            addrComune: currentAnagraficaNewVer.addrComune,
            addrIndirizzo: currentAnagraficaNewVer.addrIndirizzo,
            addrN: currentAnagraficaNewVer.addrN,
            addrCap: currentAnagraficaNewVer.addrCap,
            addr2PaeseId: currentAnagraficaNewVer.addr2PaeseId,
            addr2ProvinciaId: currentAnagraficaNewVer.addr2ProvinciaId,
            addr2Provincia: currentAnagraficaNewVer.addr2Provincia,
            addr2ComuneId: currentAnagraficaNewVer.addr2ComuneId,
            addr2Comune: currentAnagraficaNewVer.addr2Comune,
            addr2Indirizzo: currentAnagraficaNewVer.addr2Indirizzo,
            addr2N: currentAnagraficaNewVer.addr2N,
            addr2Cap: currentAnagraficaNewVer.addr2Cap,
            isPep: currentAnagraficaNewVer.isPep,
            isNotPepMoreThanYear: currentAnagraficaNewVer.isNotPepMoreThanYear,
            versionN: currentAnagraficaNewVer.versionN,
            versionDate: dataVersioneD,
            isDeleted: false,
            pepCarica: currentAnagraficaNewVer.pepCarica,
            workspaceId: workspaceId,
            AmlId: amlId === undefined ? null : amlId,
            Office1Id: officeIds[0] ? officeIds[0] : null,
            Office2Id: officeIds[1] ? officeIds[1] : null,
            pepRuolo: currentAnagraficaNewVer.pepRuolo,
          });
          if (addAnagResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result = addAnagResponse.data.data.insert_anagrafica_one;

            let anagraficaResultModel = new anagraficaModel(
              result.Id,
              result.ParentId,
              result.IsPg,
              result.Cf,
              result.Nome,
              result.Cognome,
              result.GenereId,
              result.NascitaLuogo,
              result.NascitaData,
              result.Professione,
              result.Denominazione,
              result.PIva,
              result.ReaComune,
              result.ReaN,
              result.AddrPaeseId,
              result.AddrProvinciaId,
              result.AddrProvincia,
              result.AddrComuneId,
              result.AddrComune,
              result.AddrIndirizzo,
              result.AddrN,
              result.AddrCap,
              result.Addr2PaeseId,
              result.Addr2ProvinciaId,
              result.Addr2Provincia,
              result.Addr2ComuneId,
              result.Addr2Comune,
              result.Addr2Indirizzo,
              result.Addr2N,
              result.Addr2Cap,
              result.IsPep,
              result.IsNotPepMoreThanYear,
              result.VersionN,
              result.VersionDate,
              result.IsDeleted,
              [],
              [],
              result.PepCarica,
              result.PepRuolo,
              result.CreatedBy,
              result.ModifiedBy,
              result.anagrafica_fascicolos,
              result.WorkspaceId
            );

            //Aggiorno il nome del fascicolo solo se l'anagrafica è di tipo Cliente
            const tipoAnagKey = baseTipoAnagList.filter(
              (bta) => bta.id === currentAnagraficaFascicolo.tipoAnagId
            )[0].key;
            if (
              tipoAnagKey === "tipo_anagrafica_1" ||
              tipoAnagKey === "tipo_anagrafica_2"
            ) {
              await apiUpdateFascicolo.fetch({
                id: fascicolo.id,
                name: anagraficaResultModel.denominazione,
                date: fascicolo.date,
                isPrivate: fascicolo.isPrivate,
              });
            }

            // Aggiungo le relazioni tra anagrafica e fascicolo
            const anagFascArr = [];
            const anagRelFascResponse = await apiAnagraficaRelFascicolo.fetch({
              anagraficaId: currentAnagrafica.id,
            });
            if (anagRelFascResponse.data.errors !== undefined) {
            } else {
              const allAnagFascicolo =
                anagRelFascResponse.data.data.anagrafica_fascicolo;
              for (let i = 0; i < allAnagFascicolo.length; i++) {
                const anag = allAnagFascicolo[i];
                const addRelFascResponse =
                  await apiAddAnagraficaFascicolo.fetch({
                    anagraficaId: anagraficaResultModel.id,
                    fascicoloId: anag.FascicoloId,
                    tipoAnagId: anag.TipoAnagId,
                    tipoPartId: anag.TipoPartId,
                    tipoPartDesc: anag.TipoPartDesc,
                    dataFine:
                      anag.TipoAnagId === currentAnagraficaFascicolo.tipoAnagId
                        ? currentAnagraficaFascicolo.dataFine
                        : null,
                    tipoRappId: currentAnagraficaFascicolo.tipoRappId,
                    tipoRappDesc: currentAnagraficaFascicolo.tipoRappDesc,
                  });

                if (addRelFascResponse.data.errors !== undefined) {
                } else {
                  const result3 =
                    addRelFascResponse.data.data
                      .insert_anagrafica_fascicolo_one;
                  if (result3.FascicoloId === fascicolo.id) {
                    anagFascArr.push(
                      new anagraficaFascicoloModel(
                        result3.Id,
                        result3.AnagraficaId,
                        result3.FascicoloId,
                        result3.TipoAnagId,
                        result3.TipoPartId,
                        result3.TipoPartDesc,
                        result3.DataFine,
                        result3.TipoRappId,
                        result3.TipoRappDesc
                      )
                    );
                  }
                }
              }
            }

            anagraficaResultModel.anagFascicoli = anagFascArr;

            //Aggiungo la Audit per tracciare l'azione
            const newValues = anagraficaResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaAnagrafica,
              operation: "Create",
              entityType: "anagrafica",
              entityId: result.Id.toString(),
              oldValues: "",
              newValues: JSON.stringify(newValues),
            });

            //Aggiungo l'identificazione
            const dataIdent = new Date(currentAnagraficaIdent.identifiedOn);
            const dataIdentD = new Date(
              Date.UTC(
                dataIdent.getFullYear(),
                dataIdent.getMonth(),
                dataIdent.getDate(),
                1,
                0,
                0
              )
            );

            const dataDocRilIl = new Date(currentAnagraficaIdent.docRilData);
            const dataDocRilIlD = new Date(
              Date.UTC(
                dataDocRilIl.getFullYear(),
                dataDocRilIl.getMonth(),
                dataDocRilIl.getDate(),
                1,
                0,
                0
              )
            );

            const dataDocScad = new Date(currentAnagraficaIdent.docScad);
            const dataDocScadD = new Date(
              Date.UTC(
                dataDocScad.getFullYear(),
                dataDocScad.getMonth(),
                dataDocScad.getDate(),
                1,
                0,
                0
              )
            );

            const addAnagIdentResponse = await apiAddAnagraficaIdent.fetch({
              anagraficaId: result.Id,
              modalitaIdentId: currentAnagraficaIdent.modalitaIdentId,
              docTipoId: currentAnagraficaIdent.docTipoId,
              docN: currentAnagraficaIdent.docN,
              docRilDa: currentAnagraficaIdent.docRilDa,
              docRilData: dataDocRilIlD,
              docScad: dataDocScadD,
              apNome: currentAnagraficaIdent.apNome,
              apCf: currentAnagraficaIdent.apCf,
              identifiedByUser: currentAnagraficaIdent.identifiedByUser,
              identifiedOn: dataIdentD,
            });

            const arrItemsIdent = [];
            if (addAnagIdentResponse.data.errors !== undefined) {
            } else {
              const itemI =
                addAnagIdentResponse.data.data
                  .insert_anagrafica_identificazione_one;
              const anagIdentModel = new anagraficaIdentificazioneModel(
                itemI.Id,
                itemI.base_tipo_identificazione.Id,
                itemI.DocTipoId,
                itemI.DocN,
                itemI.DocRilDa,
                itemI.DocRilData,
                itemI.DocScad,
                itemI.ApNome,
                itemI.ApCf,
                itemI.IdentifiedByUser,
                itemI.IdentifiedOn,
                itemI.CreatedOn,
                itemI.ModifiedOn,
                itemI.CreatedBy,
                itemI.ModifiedBy
              );
              arrItemsIdent.push(anagIdentModel);
            }
            anagraficaResultModel.identificazioni = arrItemsIdent;

            //Aggiungo la versione corrente (ovvero quella precedente) all'elenco dello storico
            //e setto la nuova versione come quella attuale

            //Ordino le anagrafiche per versionDate e riordino i record in tabella
            const anagResponse = await apiAnagraficaByFascicolo.fetch({
              fascicoloId: fascicolo.id,
            });
            if (anagResponse.data.errors !== undefined) {
            } else {
              let anagVersArr = [];
              for (
                let i = 0;
                i < anagResponse.data.data.anagrafica.length;
                i++
              ) {
                const item = anagResponse.data.data.anagrafica[i];
                if (item.Id === parentId || item.ParentId === parentId) {
                  anagVersArr.push({
                    id: item.Id,
                    versionN: item.VersionN,
                    versionDate: item.VersionDate,
                  });
                }
              }

              //Ordino l'array per data discendente
              const anagVersArrSorted = anagVersArr.sort(
                (a, b) =>
                  new Date(b.versionDate).setHours(0, 0, 0, 0) -
                  new Date(a.versionDate).setHours(0, 0, 0, 0)
              );

              //Riordino l'array per data e reimposto il numero di versione
              const newAnagVersArr = [];
              let anagVersArrCount = anagVersArrSorted.length;
              for (let i = 0; i < anagVersArrSorted.length; i++) {
                const item = anagVersArrSorted[i];
                newAnagVersArr.push({
                  id: item.id,
                  versionN: anagVersArrCount,
                  versionDate: item.versionDate,
                });
                anagVersArrCount = anagVersArrCount - 1;
              }
            }

            setCurrentAnagraficaNewVer(
              await emptyAnagraficaModel(basePaeseList)
            );
            setLastSelectedAnag(null);
            setIsDataLoaded(false);
            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        }
      } else {
        for (let i = 0; i < errors.length; i++) {
          enqueueSnackbar(errors[i], { variant: "error" });
        }
      }
    } catch (e) {
      AddLog(e.message, e.stack, "HandleModalHistoryOkClick");
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setIdToPerform(0);
      setShowHistoryModal(false);
      setBackDropOpen(false);
      reloadStatusFascicolo();
    }
  };
  //#endregion

  //#region Funzioni workspace
  const savePersonalDataOnWorkspace = async (anagrafica) => {
    try {
      var data = null;
      if (anagrafica.isPg) {
        var personalData = null
        //L'anagrafica è una ditta individuale
        if(isAFiscalCode(anagrafica.cf)){          
          personalData = {
            taxIdentifier: anagrafica.cf,
            vatIdentifier: anagrafica.pIva,
            businessName: anagrafica.denominazione,
            countryCode: "IT",
            name: anagrafica.nome,
            surname: anagrafica.cognome,
            birthDate:
                anagrafica.nascitaData !== null
                  ? anagrafica.nascitaData
                  : "1900-01-01T01:00:00",
              birthplace:
                anagrafica.nascitaLuogo !== "" ? anagrafica.nascitaLuogo : "N.D.",
              gender: anagrafica.genereId === 1 ? "M" : "F",
          }
        } else { //L'anagrafica è una ditta normale
          personalData = {
            taxIdentifier: anagrafica.cf,
            vatIdentifier: anagrafica.pIva,
            businessName: anagrafica.denominazione,
            countryCode: "IT",
          }
        }

        data = {
          ownerId: tenantId,
          classifier: "COMPANY",
          personalData: personalData,
          baseCurrency: "EUR",
        };
      } else {
        data = {
          ownerId: tenantId,
          classifier: "PERSON",
          personalData: {
            taxIdentifier: anagrafica.cf,
            countryCode: "IT",
            name: anagrafica.nome,
            surname: anagrafica.cognome,
            birthDate:
              anagrafica.nascitaData !== null
                ? anagrafica.nascitaData
                : "1900-01-01T01:00:00",
            birthplace:
              anagrafica.nascitaLuogo !== "" ? anagrafica.nascitaLuogo : "N.D.",
            gender: anagrafica.genereId === 1 ? "M" : "F",
          },
          baseCurrency: "EUR",
        };
      }

      const wsResult = await tsApiWorkspace.fetch({
        headers: {
          Authorization: "Bearer " + accessToken,
          "X-App-Name": "TS420",
          "X-App-Version": "1.0.0",
          "X-Request-ID": uuidv4(),
          "X-Correlation-ID": uuidv4(),
          "Content-Type": "application/json",
          "X-Item-ID": tenantId,
          "X-User-ID": loggedUserId,
        },
        data,
      });
      let wsId = wsResult.data !== null ? wsResult.data.id : "";
      return wsId;
    } catch (e) {
      AddLog(e.message, e.stack, "SavePersonalDataOnWorkspace");
    }
  };
  const saveOfficeOnWorkSpace = async (workspaceId, officeData) => {
    //Posto i dati su workspace e salvo gli id che tornano
    try {
      var resp = await tsApiWorkspace.fetch({
        url: `aml:workspace-write:api://${workspaceId}/office`,
        headers: {
          Authorization: "Bearer " + accessToken,
          "X-App-Name": "TS420",
          "X-App-Version": "1.0.0",
          "X-Request-ID": uuidv4(),
          "X-Correlation-ID": uuidv4(),
          "Content-Type": "application/json",
          "X-Item-ID": tenantId,
          "X-User-ID": loggedUserId,
          "X-Workspace-ID": workspaceId,
        },
        data: officeData,
      });

      var officeId = resp.data !== null ? resp.data.id : "";
      return officeId;
    } catch (e) {
      AddLog(e.message, e.stack, "SaveOfficesOnWorkSpace");
    }
  };
  const saveAmlDatasOnWorkSpace = async (workspaceId) => {
    var toPostData = null;
    toPostData = {
      verifiedBy: getIdentUser(currentAnagraficaIdent.identifiedByUser),
      verifiedWith: getDocumentTypeById(currentAnagraficaIdent.docTipoId),
      occupationalActivity: currentAnagrafica.professione,
      politicallyExposedPerson: currentAnagrafica.isPep,
      politicalOfficeHeld: !currentAnagrafica.isPep
        ? null
        : getPoliticalRole(currentAnagrafica.pepRuolo),
    };

    try {
      var resp = await tsApiWorkspace.fetch({
        url: `aml:workspace-write:api://${workspaceId}/antiMoneyLaundering`,
        headers: {
          Authorization: "Bearer " + accessToken,
          "X-App-Name": "TS420",
          "X-App-Version": "1.0.0",
          "X-Request-ID": uuidv4(),
          "X-Correlation-ID": uuidv4(),
          "Content-Type": "application/json",
          "X-Item-ID": tenantId,
          "X-User-ID": loggedUserId,
          "X-Workspace-ID": workspaceId,
        },
        data: toPostData,
      });
      let amlId = resp.data !== null ? resp.data.id : "";
      return amlId;
    } catch (e) {
      AddLog(e.message, e.stack, "SaveAmlDatasOnWorkSpace");
    }
  };
  const updatePersonalDataOnWorkspace = async (workspaceId) => {
    try {
      if (workspaceId != null) {
        let personalData = null
        if(currentAnagrafica.isPg){
          return
        } else {
          personalData = {
            ownerId: tenantId,
            personalData: {              
              countryCode: currentAnagrafica.paese,
              name: currentAnagrafica.nome,
              surname: currentAnagrafica.cognome,
              birthDate: currentAnagrafica.nascitaData,
              birthplace: currentAnagrafica.nascitaLuogo,
              gender: currentAnagrafica.genereId === 1 ? "M" : "F",
            },
          };
        }       

        var workspaceUpdateResponse = await tsApiWorkspaceUpdate.fetch({
          url: `aml:workspace-write:api://${workspaceId}`,
          method: "patch",
          headers: {
            Authorization: "Bearer " + accessToken,
            "X-App-Name": "TS420",
            "X-App-Version": "1.0.0",
            "X-Request-ID": uuidv4(),
            "X-Correlation-ID": uuidv4(),
            "X-Workspace-ID": workspaceId,
            "Content-Type": "application/json",
            "X-Item-ID": tenantId,
            "X-User-ID": loggedUserId,
          },
          data: personalData,
        });
        let workSpaceUpdatedId =
          workspaceUpdateResponse.data !== null
            ? workspaceUpdateResponse.data.id
            : "";
        return workSpaceUpdatedId;
      }
    } catch (e) {
      AddLog(e.message, e.stack, "UpdatePersonalDataOnWorkspace");
    }
  };
  const updateOfficesOnWorkSpace = async (workspaceId, officeId, isPrimary) => {
    try {
      if (officeId !== null) {
        let officeData = null;
        if (isPrimary) {
          let isItalianAddress = currentAnagrafica.addrPaeseId === 89;
          officeData = {
            description: "Indirizzo Principale",
            officeType: "MAIN",
            streetNumber: currentAnagrafica.addrN,
            streetName: currentAnagrafica.addrIndirizzo,
            province: isItalianAddress
              ? getProvincia(currentAnagrafica.addrProvinciaId)
              : currentAnagrafica.addrProvincia,
            city: isItalianAddress
              ? getComune(currentAnagrafica.addrComuneId)
              : currentAnagrafica.addrComune,
            district: "",
            zipCode: currentAnagrafica.addrCap,
            region: "",
            country: getNazione(currentAnagrafica.addrPaeseId),
            status: "ACTIVE",
            officeClassifier: [],
            registeredResidenceType: "REGISTERED_RESIDENCE",
          };
        } else {
          let isItalianAddress = currentAnagrafica.addr2PaeseId === 89;
          officeData = {
            description: "Indirizzo Secondario",
            officeType: "SECONDARY",
            streetNumber: currentAnagrafica.addr2N,
            streetName: currentAnagrafica.addr2Indirizzo,
            province: isItalianAddress
              ? getProvincia(currentAnagrafica.addr2ProvinciaId)
              : currentAnagrafica.addr2Provincia,
            city: isItalianAddress
              ? getComune(currentAnagrafica.addr2ComuneId)
              : currentAnagrafica.addr2Comune,
            district: "",
            zipCode: currentAnagrafica.addr2Cap,
            region: "",
            country: getNazione(currentAnagrafica.addr2PaeseId),
            status: "ACTIVE",
            officeClassifier: [],
            registeredResidenceType: "DOMICILE",
          };
        }
        var officeUpdateResponse = await tsApiWorkspaceUpdate.fetch({
          url: `aml:workspace-write:api://${workspaceId}/office/${officeId}`,
          method: "PATCH",
          headers: {
            Authorization: "Bearer " + accessToken,
            "X-App-Name": "TS420",
            "X-App-Version": "1.0.0",
            "X-Request-ID": uuidv4(),
            "X-Correlation-ID": uuidv4(),
            "X-Workspace-ID": workspaceId,
            "Content-Type": "application/json",
            "X-Item-ID": tenantId,
            "X-User-ID": loggedUserId,
          },
          data: officeData,
        });
        let officeupdatedId =
          officeUpdateResponse.data !== null
            ? officeUpdateResponse.data.id
            : "";
        return officeupdatedId;
      }
    } catch (e) {
      AddLog(e.message, e.stack, "UpdateOfficesOnWorkSapce");
    }
  };
  const updateAmlDatasOnWorkSpace = async (workspaceId, newAmlId) => {
    var toPostData = {
      verifiedBy: getIdentUser(currentAnagraficaIdent.identifiedByUser),
      verifiedWith: getDocumentTypeById(currentAnagraficaIdent.docTipoId),
      occupationalActivity: currentAnagrafica.professione,
      politicallyExposedPerson: currentAnagrafica.isPep,
      politicalOfficeHeld: !currentAnagrafica.isPep
        ? null
        : getPoliticalRole(currentAnagrafica.pepRuolo),
    };
    try {
      var resp = await tsApiWorkspaceUpdate.fetch({
        url: `aml:workspace-write:api://${workspaceId}/antiMoneyLaundering/${newAmlId}`,
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + accessToken,
          "X-App-Name": "TS420",
          "X-App-Version": "1.0.0",
          "X-Request-ID": uuidv4(),
          "X-Correlation-ID": uuidv4(),
          "Content-Type": "application/json",
          "X-Item-ID": tenantId,
          "X-User-ID": loggedUserId,
          "X-Workspace-ID": workspaceId,
        },
        data: toPostData,
      });
      let amlId = resp.data !== null ? resp.data.id : "";
      return amlId;
    } catch (e) {
      AddLog(e.message, e.stack, "UpdateAmlDatasOnWorkSpace");
    }
  };
  //#endregion

  //#region Utils
  const generateDatasForOffice = (hasOnlyOneAddress) => {
    let officeDatas = [];
    try {
      if (hasOnlyOneAddress) {
        let isItalianAddress = currentAnagrafica.addrPaeseId === 89;
        var officeData = {
          description: "Indirizzo Principale",
          officeType: "MAIN",
          streetNumber: currentAnagrafica.addrN,
          streetName: currentAnagrafica.addrIndirizzo,
          province: isItalianAddress
            ? getProvincia(currentAnagrafica.addrProvinciaId)
            : currentAnagrafica.addrProvincia,
          city: isItalianAddress
            ? getComune(currentAnagrafica.addrComuneId)
            : currentAnagrafica.addrComune,
          district: "",
          zipCode: currentAnagrafica.addrCap,
          region: "",
          country: getNazione(currentAnagrafica.addrPaeseId),
          officeClassifier: [],
          registeredResidenceType: "REGISTERED_RESIDENCE_AND_DOMICILE",
        };
        officeDatas.push(officeData);
      } else {
        let isPrimaryItalian = currentAnagrafica.addrPaeseId === 89;
        let isSecondaryItalian = currentAnagrafica.addr2PaeseId === 89;

        officeData = {
          description: "Indirizzo Principale",
          officeType: "MAIN",
          streetNumber: currentAnagrafica.addrN,
          streetName: currentAnagrafica.addrIndirizzo,
          province: isPrimaryItalian
            ? getProvincia(currentAnagrafica.addrProvinciaId)
            : currentAnagrafica.addrProvincia,
          city: isPrimaryItalian
            ? getComune(currentAnagrafica.addrComuneId)
            : currentAnagrafica.addrComune,
          district: "",
          zipCode: currentAnagrafica.addrCap,
          region: "",
          country: getNazione(currentAnagrafica.addrPaeseId),
          officeClassifier: [],
          registeredResidenceType: "REGISTERED_RESIDENCE",
        };
        var officeData2 = {
          description: "Indirizzo Secondario",
          officeType: "SECONDARY",
          streetNumber: currentAnagrafica.addr2N,
          streetName: currentAnagrafica.addr2Indirizzo,
          province: isSecondaryItalian
            ? getProvincia(currentAnagrafica.addr2ProvinciaId)
            : currentAnagrafica.addr2Provincia,
          city: isSecondaryItalian
            ? getComune(currentAnagrafica.addr2ComuneId)
            : currentAnagrafica.addr2Comune,
          district: "",
          zipCode: currentAnagrafica.addr2Cap,
          region: "",
          country: getNazione(currentAnagrafica.addr2PaeseId),
          officeClassifier: [],
          registeredResidenceType: "DOMICILE",
        };
        officeDatas.push(officeData, officeData2);
      }
      return officeDatas;
    } catch (e) {
      AddLog(e.message, e.stack, "GenerateDatasForOffice");
    }
  };
  const checkCfIsCorrect = async () => {
    var response = await apiBaseAll.fetch({ tenantId: tenantId });
    var allComuni = response.data.data.base_comune;
    var allPaesi = response.data.data.base_paese;
    var generatdCf = isFiscalCodeValid(
      currentAnagrafica.nome,
      currentAnagrafica.cognome,
      currentAnagrafica.genereId,
      currentAnagrafica.nascitaData,
      currentAnagrafica.nascitaLuogo,
      allComuni,
      allPaesi
    );

    var isCorrect = generatdCf === currentAnagrafica.cf;
    return isCorrect;
  };
  const getProvincia = (provinciaId) => {
    let selProv = baseProvinciaList.filter(
      (prov) => prov.id === provinciaId
    )[0];
    return selProv !== "" && selProv.nome !== "" ? selProv.nome : "";
  };
  const getComune = (comuneId) => {
    let selComune = baseComuneList.filter(
      (comune) => comune.id === comuneId
    )[0];

    if (selComune === "" || selComune === undefined || selComune === null) {
      selComune = baseComuneList2.filter((comune) => comune.id === comuneId)[0];
    }

    return selComune !== "" && selComune.nome !== "" ? selComune.nome : "";
  };
  const getNazione = (nazioneId) => {
    let selNazione = basePaeseList.filter((naz) => naz.id === nazioneId)[0];
    return selNazione !== "" && selNazione.nome !== "" ? selNazione.nome : "";
  };
  const getDocumentTypeById = (tipoDoc) => {
    switch (tipoDoc) {
      case 1:
        return "IDENTITY_CARD";
      case 2:
        return "PASSPORT";
      case 4:
        return "RESIDENCY_PERMIT";
      case 5:
        return "DRIVERS_LICENSE";
      case 6:
        return "FIREARM_LICENSE";
      default:
        return "OTHER";
    }
  };
  const getIdentUser = (identUserId) => {
    let selIdentUser = allUtentiList.filter(
      (user) => user.userId === identUserId
    )[0];
    return selIdentUser.completeName;
  };
  const getPoliticalRole = (politicalRole) => {
    switch (politicalRole) {
      case "PRESIDENTE DELLA REPUBBLICA":
        return "PRESIDENT_OF_THE_REPUBLIC";
      case "PRIMO MINISTRO":
        return "PRIME_MINISTER";
      case "MINISTRO":
        return "MINISTER";
      case "VICE MINISTRO":
        return "DEPUTY_MINISTER";
      case "SOTTOSEGRETARIO":
        return "UNDERSECRETARY";
      case "PRESIDENTE DELLA REGIONE":
        return "PRESIDENT_OF_THE_REGION";
      case "CONSIGLIERE REGIONALE":
        return "REGIONAL_COUNTY_COUNCILLOR";
      case "PRESIDENTE DELLA PROVINCIA":
        return "MAYOR_OF_A_PROVINCE";
      case "SINDACO":
        return "MAYOR_OF_A_MUNICIPALITY";
      case "DEPUTATO":
        return "DEPUTY";
      case "SENATORE":
        return "SENATOR";
      case "PARLAMENTARE":
        return "PARLIAMENTARIAN";
      case "PARLAMENTARE EUROPEO":
        return "EUROPEAN_PARLIAMENTARIAN";
      case "UFFICIO DEGLI ESTERI":
        return "SIMILAR_OFFICES_FOREIGN_COUNTRIES";
      default:
        return "MAYOR_OF_A_MUNICIPALITY";
    }
  };

  //#endregion

  //#region Modals handlers
  //Modal User Identificazione
  const [saveType, setSaveType] = useState("anagrafica");
  const handleModalIdentClose = async () => {
    setShowIdentModal(false);
    setShowHistoryModal(false);
  };
  const handleModalIdentOkClick = async () => {
    if (saveType === "anagrafica") {
      setShowIdentModal(false);
      await saveAnagrafica();
    } else if (saveType === "version") {
      setShowIdentModal(false);
      setShowHistoryModal(false);
      await saveAnagraficaVersione();
    }
  };

  //Modal History
  const handleModalHistoryClose = async () => {
    setCurrentAnagraficaNewVer(await emptyAnagraficaModel(basePaeseList));
    setIdToPerform(0);
    setShowHistoryModal(false);
  };
  const handleModalHistoryOkClick = async () => {
    if (currentAnagraficaIdent.identifiedByUser !== loggedUserId) {
      setShowIdentModal(true);
      setSaveType("version");
    } else {
      await saveAnagraficaVersione();
    }
  };

  //Modal Delete
  const handleModalDeleteClose = () => {
    setIdToPerform(0);
    setTypeToPerform("A");
    setShowDeleteModal(false);
    setAnagFascToPerform(null);
  };
  const handleModalDeleteOkClick = async () => {
    try {
      if (idToPerform > 0) {
        setBackDropOpen(true);

        if (typeToPerform === "A") {
          await apiDeleteAnagraficaFascicolo.fetch({
            anagraficaId: anagFascToPerform.anagraficaId,
            fascicoloId: fascicolo.id,
            tipoAnagId: anagFascToPerform.tipoAnagId,
          });

          if (currentAnagrafica.parentId !== 0) {
            await apiDeleteAnagraficaFascicolo.fetch({
              anagraficaId: currentAnagrafica.parentId,
              fascicoloId: fascicolo.id,
              tipoAnagId: anagFascToPerform.tipoAnagId,
            });
          }
          setLastSelectedAnag(null);
          setIsDataLoaded(false);
          setShowAnagForm(false);
          enqueueSnackbar(t("message.success"), { variant: "success" });
        } else if (typeToPerform === "AV") {
          const currentAnagFascArr = currentAnagraficheList.filter(
            (x) => x.id === idToPerform
          )[0].anagFascicoli;

          const response = await apiDeleteAnagraficaVersion.fetch({
            id: idToPerform,
            versionN: currentAnagrafica.versionN,
          });
          if (response.data.errors !== undefined) {
          } else {
            //Seleziono l'anagrafica con la versione precedente a quella che ho appena eliminato
            if (currentAnagraficheHistoryFilteredList.length > 0) {
              const anagVersions = currentAnagraficheHistoryFilteredList.filter(
                (x) => x.id !== idToPerform
              );
              if (anagVersions.length > 0) {
                const previousAnag = anagVersions[0];
                const previousAnagFascArr = previousAnag.anagFascicoli;

                //Prima rimuovo le associazioni ai fascicoli precedenti
                for (let i = 0; i < previousAnagFascArr.length; i++) {
                  const anagFascItem = previousAnagFascArr[i];
                  await apiDeleteAnagraficaFascicolo.fetch({
                    anagraficaId: previousAnag.id,
                    fascicoloId: fascicolo.id,
                    tipoAnagId: anagFascItem.tipoAnagId,
                  });
                }

                //Dopodichè aggiungo quelle correnti
                for (let i = 0; i < currentAnagFascArr.length; i++) {
                  const anagFascItem = currentAnagFascArr[i];
                  await apiAddAnagraficaFascicolo.fetch({
                    anagraficaId: previousAnag.id,
                    fascicoloId: fascicolo.id,
                    tipoAnagId: anagFascItem.tipoAnagId,
                    tipoPartId: anagFascItem.tipoPartId,
                    tipoPartDesc: anagFascItem.tipoPartDesc,
                    tipoRappId: currentAnagraficaFascicolo.tipoRappId,
                    tipoRappDesc: currentAnagraficaFascicolo.tipoRappDesc,
                  });
                }

                setLastSelectedAnag(anagVersions[0]);
              }
            }
          }

          setIsDataLoaded(false);
          enqueueSnackbar(t("message.success"), { variant: "success" });
        } else if (typeToPerform === "F") {
          //Cancello il fascicolo che non contiene nessuna anagrafica
          await apiDeleteFascicolo.fetch({
            id: idToPerform,
            tenantId: tenantId,
            aziendaId: aziendaId,
          });
          navigate("/fascicolo");
        }
      }
    } catch (e) {
      AddLog(e.message, e.stack, "HandleModalDeleteOkClick");
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setIdToPerform(0);
      setTypeToPerform("A");
      setShowDeleteModal(false);
      setBackDropOpen(false);
      reloadStatusFascicolo();
    }
  };

  //Modal confirm save standard
  const checkSaveConfirmNeed = async () => {
    try {
      if (!currentAnagrafica.isPg) {
        if (await checkCfIsCorrect()) {
          await handleSaveAnagClick();
        } else {
          setIdToPerform(currentAnagrafica.id > 0 ? currentAnagrafica.id : 1);
          setTypeToPerform("A");
          setShowSaveConfirmModal(true);
          setAnagFascToPerform(
            currentAnagrafica.anagFascicoli.length > 0
              ? currentAnagrafica.anagFascicoli[0]
              : null
          );
        }
      } else {
        await handleSaveAnagClick();
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };
  const handleModalConfirmSaveClose = () => {
    setIdToPerform(0);
    setTypeToPerform("A");
    setShowSaveConfirmModal(false);
    setAnagFascToPerform(null);
  };
  const handleModalConfirmSaveOkClick = async () => {
    try {
      if (idToPerform > 0) {
        setBackDropOpen(true);
        setShowSaveConfirmModal(false);
        handleSaveAnagClick();
      }
    } catch (e) {
    } finally {
      setIdToPerform(0);
      setTypeToPerform("A");
      setBackDropOpen(false);

      reloadStatusFascicolo();
    }
  };

  //Modal confirm save version
  const checkSaveConfirmNeedVer = async () => {
    try {
      if (!currentAnagrafica.isPg) {
        if (await checkCfIsCorrect()) {
          await handleSaveAnagNewVersionClick();
        } else {
          setIdToPerform(currentAnagrafica.id);
          setTypeToPerform("A");
          setShowSaveConfirmModalVer(true);
          setAnagFascToPerform(
            currentAnagrafica.anagFascicoli.length > 0
              ? currentAnagrafica.anagFascicoli[0]
              : null
          );
        }
      } else {
        await handleSaveAnagNewVersionClick();
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };
  const handleModalConfirmSaveVerClose = () => {
    setIdToPerform(0);
    setTypeToPerform("A");
    setShowSaveConfirmModalVer(false);
    setShowHistoryModal(false);
    setAnagFascToPerform(null);
  };
  const handleModalConfirmSaveOkClickVer = async () => {
    try {
      if (idToPerform > 0) {
        setBackDropOpen(true);
        setShowSaveConfirmModalVer(false);
        handleSaveAnagNewVersionClick();
      }
    } catch (e) {
    } finally {
      setIdToPerform(0);
      setTypeToPerform("A");
      setBackDropOpen(false);
      reloadStatusFascicolo();
    }
  };
  const handleModalResultClickClose = () => {
    setShowResultListModal(false);
    setResultList([]);
  };
  const handleModalResultOkClick = async (evt, selectedAnag) => {
    try {
      const anagResp = await apiAnagraficheById.fetch({
        tenantId: tenantId,
        aziendaId: aziendaId,
        id: selectedAnag,
      });

      if (anagResp.data.data.anagrafica.length === 1) {
        let anagrafica = anagResp.data.data.anagrafica[0];
        let anag = new anagraficaModel(
          anagrafica.Id,
          anagrafica.ParentId,
          anagrafica.IsPg,
          anagrafica.Cf,
          anagrafica.Nome,
          anagrafica.Cognome,
          anagrafica.GenereId,
          anagrafica.NascitaLuogo,
          anagrafica.NascitaData,
          anagrafica.Professione,
          anagrafica.Denominazione,
          anagrafica.PIva,
          anagrafica.ReaComune,
          anagrafica.ReaN,
          anagrafica.AddrPaeseId,
          anagrafica.AddrProvinciaId,
          anagrafica.AddrProvincia,
          anagrafica.AddrComuneId,
          anagrafica.AddrComune,
          anagrafica.AddrIndirizzo,
          anagrafica.AddrN,
          anagrafica.AddrCap,
          anagrafica.Addr2PaeseId,
          anagrafica.Addr2ProvinciaId,
          anagrafica.Addr2Provincia,
          anagrafica.Addr2ComuneId,
          anagrafica.Addr2Comune,
          anagrafica.Addr2Indirizzo,
          anagrafica.Addr2N,
          anagrafica.Addr2Cap,
          anagrafica.IsPep,
          anagrafica.IsNotPepMoreThanYear,
          anagrafica.VersionN,
          anagrafica.VersionDate,
          false,
          [],
          [],
          anagrafica.PepCarica,
          anagrafica.PepRuolo,
          anagrafica.CreatedBy,
          anagrafica.ModifiedBy,
          anagrafica.anagrafica_fascicolos,
          anagrafica.WorkspaceId
        );

        let anagIdents = anagResp.data.data.anagrafica[0].anagrafica_identificaziones;
        if(anagIdents.lenght > 0){

          let anagIdent = anagIdents[0]
          let anagIdentModel = new anagraficaIdentificazioneModel(
            anagIdent.Id,
            anagIdent.base_tipo_identificazione.Id,
            anagIdent.DocTipoId,
            anagIdent.DocN,
            anagIdent.DocRilDa,
            anagIdent.DocRilData,
            anagIdent.DocScad,
            anagIdent.ApNome,
            anagIdent.ApCf,
            anagIdent.IdentifiedByUser,
            anagIdent.IdentifiedOn,
            anagIdent.CreatedOn,
            anagIdent.ModifiedOn,
            anagIdent.CreatedBy,
            anagIdent.ModifiedBy
          );
  
          const modalitaIdentKey = baseTipoIdentList.filter(
            (item) => item.id === anagIdentModel.modalitaIdentId
          )[0].key;
          setIsModIdentDiretta(modalitaIdentKey === "tipo_identificazione_1");
          setShowDoctipoAp(modalitaIdentKey === "tipo_identificazione_5");
  
          if (anagIdentModel.identifiedByUser === "") {
            anagIdent.identifiedByUser = sharedDatas.getPropertyByName("userId");
          }
  
          if (anagIdentModel.docTipoId > 0) {
            const docTipoKey = baseTipoDocList.filter(
              (item) => item.id === anagIdentModel.docTipoId
            )[0].key;
            setShowDocTipoDetails(docTipoKey !== "tipo_documento_3");
          }
  
          setCurrentAnagraficaIdent(anagIdentModel);
        }
        setCurrentAnagrafica((prevState) => ({
          ...prevState,
          id: anag.id,
          parentId: anag.parentId,
          isPg: anag.isPg,
          cf: anag.cf,
          nome: anag.nome,
          cognome: anag.cognome,
          genereId: anag.genereId,
          nascitaLuogo: anag.nascitaLuogo,
          nascitaData: anag.nascitaData,
          professione: anag.professione,
          denominazione: anag.denominazione,
          pIva: anag.pIva,
          reaComune: anag.reaComune,
          reaN: anag.reaN,
          addrPaeseId: anag.addrPaeseId,
          addrProvinciaId: anag.addrProvinciaId,
          addrProvincia: anag.addrProvincia,
          addrComuneId: anag.addrComuneId,
          addrComune: anag.addrComune,
          addrIndirizzo: anag.addrIndirizzo,
          addrN: anag.addrN,
          addrCap: anag.addrCap,
          addr2PaeseId: anag.addr2PaeseId,
          addr2ProvinciaId: anag.addr2ProvinciaId,
          addr2Provincia: anag.addr2Provincia,
          addr2ComuneId: anag.addr2ComuneId,
          addr2Comune: anag.addr2Comune,
          addr2Indirizzo: anag.addr2Indirizzo,
          addr2N: anag.addr2N,
          addr2Cap: anag.addr2Cap,
          isPep: anag.isPep,
          isNotPepMoreThanYear: anag.isNotPepMoreThanYear,
          versionN: anag.versionN,
          versionDate: anag.versionDate,
          isDeleted: anag.isDeleted,
          pepCarica: anag.pepCarica,
          pepRuolo: anag.pepRuolo,
        }));
        setShowAnagForm(true);
        setShowResultListModal(false);
        setResultList([]);
      }
    } catch (e) {
      AddLog(e.message, e.stack, "HandleModalResultOkClick");
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  //#endregion

  //#region Anchor El
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (id) => {
    setAnchorEl(null);
  };
  //#endregion

  //#region EuroConference
  const handleEuroConfClick = async (itemId) => {
    try {
      setBackDropOpen(true);

      const jsonToEnc = {
        id: itemId,
        search: "",
        userfullname: sharedDatas.getPropertyByName("userModel").username,
        context: "TS420",
        from: "TS Antiriciclaggio",
        userid: loggedUserId,
        teamsystemid: sharedDatas.getPropertyByName("userModel").email,
      };

      const cryptedContentResp = await tsApiAntiRic.fetch({
        url:
          "aml:ts-anti-ric:api://euroconf/getEncParam?valueToEnc=" +
          JSON.stringify(jsonToEnc),
      });

      window.open(
        "https://portale.ecevolution.it/ssoEvolution?encParam=" +
          cryptedContentResp.data,
        "_blank",
        "noopener,noreferrer"
      );
    } catch (e) {
      AddLog(e.message, e.stack, "HandleEuroConfClick");
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  //#endregion

  //#region Return HTML
  return (
    <Fragment>
      <VaporPage.Section>
        <Loading open={backDropOpen} />

        {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

        <Modal
          key="historyModal"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          open={showHistoryModal}
          onClose={handleModalHistoryClose}
          onBackdropClick={handleModalHistoryClose}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "400px",
              minHeight: "230px",
              bgcolor: "#FFFFFF",
              boxShadow: "0px 0px 8px 0px #CCCCCC",
            }}
          >
            <Stack
              direction="column"
              spacing={0}
              justifyContent="left"
              alignItems="left"
            >
              <ExtendedTypography
                variant="titleSmall"
                color="primary.main"
                sx={{ p: 2, pl: 3 }}
              >
                {t("anagrafica.anagraficaForm.history")}
              </ExtendedTypography>
              <Divider orientation="horizontal" flexItem />
            </Stack>
            <Stack
              direction="column"
              spacing={0}
              justifyContent="left"
              alignItems="left"
            >
              <Box sx={{ pl: 3, pr: 3, pb: 2, pt: 3 }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={it}
                >
                  <DatePicker
                    label="Indica una data per la storicizzazione"
                    closeOnSelect
                    format="dd/MM/yyyy"
                    mask="__/__/____"
                    maxDate={new Date()}
                    value={
                      isDateValid(currentAnagraficaNewVer.versionDate)
                        ? new Date(currentAnagraficaNewVer.versionDate)
                        : null
                    }
                    onChange={handleChangeVersionDate}
                    slotProps={{
                      textField: {
                        placeholder: "dd/MM/yyyy",
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                p: 1,
                mt: 1,
                bgcolor: "#F2F5F8",
                position: "fixed",
                bottom: 0,
                width: "100%",
              }}
              justifyContent="right"
              alignItems="right"
            >
              {/* <Button variant="outlined" size="small" onClick={handleModalHistoryKoClick}>{t("actions.negative")}</Button>
                            <Button variant="contained" size="small" onClick={handleModalHistoryOkClick}>{t("actions.positive")}</Button> */}
              <Button
                variant="outlined"
                size="small"
                onClick={handleModalHistoryClose}
              >
                {t("actions.annulla")}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleModalHistoryOkClick}
              >
                {t("actions.salva")}
              </Button>
            </Stack>
          </Box>
        </Modal>

        <Modal
          key="identModal"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          open={showIdentModal}
          onClose={handleModalIdentClose}
          onBackdropClick={handleModalIdentClose}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "400px",
              minHeight: "230px",
              bgcolor: "#FFFFFF",
              boxShadow: "0px 0px 8px 0px #CCCCCC",
            }}
          >
            <Stack
              direction="column"
              spacing={0}
              justifyContent="left"
              alignItems="left"
            >
              <ExtendedTypography
                variant="titleSmall"
                color="primary.main"
                sx={{ p: 2, pl: 3 }}
              >
                Attenzione
              </ExtendedTypography>
              <Divider orientation="horizontal" flexItem />
            </Stack>
            <Stack
              direction="column"
              spacing={0}
              justifyContent="left"
              alignItems="left"
            >
              <Box sx={{ pl: 3, pr: 3, pb: 2, pt: 3 }}>
                <ExtendedTypography variant="body" color="primary.main">
                  {t("anagrafica.anagraficaForm.ambiProf")}
                  <br />
                  {t("anagrafica.anagraficaForm.wntProc")}
                </ExtendedTypography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                p: 1,
                mt: 1,
                bgcolor: "#F2F5F8",
                position: "fixed",
                bottom: 0,
                width: "100%",
              }}
              justifyContent="right"
              alignItems="right"
            >
              <Button
                variant="outlined"
                size="small"
                onClick={handleModalIdentClose}
              >
                {t("actions.annulla")}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleModalIdentOkClick}
              >
                Conferma
              </Button>
            </Stack>
          </Box>
        </Modal>

        {showDeleteModal && idToPerform > 0 && typeToPerform !== "" ? (
          <ModalDelete
            show={showDeleteModal}
            onClose={handleModalDeleteClose}
            onOk={handleModalDeleteOkClick}
          ></ModalDelete>
        ) : null}

        {showSaveConfirmModal && idToPerform > 0 && typeToPerform !== "" ? (
          <ModalConfirmSave
            show={showSaveConfirmModal}
            onClose={handleModalConfirmSaveClose}
            onOk={handleModalConfirmSaveOkClick}
          ></ModalConfirmSave>
        ) : null}

        {showSaveConfirmModalVer && idToPerform > 0 && typeToPerform !== "" ? (
          <ModalConfirmSave
            show={showSaveConfirmModalVer}
            onClose={handleModalConfirmSaveVerClose}
            onOk={handleModalConfirmSaveOkClickVer}
          ></ModalConfirmSave>
        ) : null}

        {showResultListModal && resultList.length > 0 && (
          <ModalSearchResultList
            show={showResultListModal}
            onClose={handleModalResultClickClose}
            onOk={handleModalResultOkClick}
            anagToShowList={resultList}
          ></ModalSearchResultList>
        )}

        {isDataLoaded && userPermissions.canRead ? (
          <Fragment>
            <Stack
              direction="row"
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
              sx={{ mt: 2 }}
            >
              <Stack direction="column" spacing={1} sx={{ width: "25%" }}>
                <Grid container justifyContent="left" alignItems="center">
                  {isUserAllowedToPrivate ? (
                    <Fragment>
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="chkPrivate"
                                checked={fascicolo.isPrivate}
                                onChange={handleChangeFascicolo}
                              />
                            }
                            label={t("fascicolo.form.privato")}
                          />
                        </FormGroup>
                        <Divider variant="standard" sx={{ pt: 1 }} />
                      </Grid>
                    </Fragment>
                  ) : null}
                </Grid>

                <Stack direction="row" spacing={1}>
                  <ExtendedTypography variant="titleSmall" color="primary.main">
                    {t("anagrafica.anagraficaForm.cli")}
                  </ExtendedTypography>
                </Stack>
                <Divider variant="standard" />

                {currentAnagraficheClientiList.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table size="small" sx={{ mt: 1 }}>
                      <TableBody>
                        {currentAnagraficheClientiList.map((item) => (
                          <TableRow
                            key={uuidv4()}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            hover
                            selected={
                              lastSelectedAnag !== null &&
                              tipoAnag === "cliente" &&
                              (lastSelectedAnag.id === item.id ||
                                lastSelectedAnag.parentId === item.id)
                            }
                          >
                            <TableCell
                              key={uuidv4()}
                              component="th"
                              scope="row"
                            >
                              <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Stack direction="column">
                                  <Stack direction="row" spacing={1}>
                                    {item.isPg ? (
                                      <BusinessIcon />
                                    ) : (
                                      <PersonIcon />
                                    )}
                                    <ExtendedTypography
                                      variant="titleSmall"
                                      color="primary.main"
                                    >
                                      {item.isPg
                                        ? item.denominazione
                                        : item.nome + " " + item.cognome}
                                    </ExtendedTypography>
                                  </Stack>

                                  <ExtendedTypography
                                    variant="body"
                                    color="text.secondary"
                                  >
                                    C.F.: {item.cf}
                                    {item.isPg
                                      ? " | P. Iva: " + item.pIva
                                      : null}
                                  </ExtendedTypography>
                                  <ExtendedTypography
                                    variant="body"
                                    color="text.secondary"
                                  >
                                    {item.identificazioni.length > 0
                                      ? "Identificato in data: " +
                                        new Date(
                                          item.identificazioni[0].identifiedOn
                                        ).toLocaleDateString() +
                                        " da " +
                                        allUtentiList.filter(
                                          (x) =>
                                            x.userId ===
                                            item.identificazioni[0]
                                              .identifiedByUser
                                        )[0].completeName
                                      : `Non identificato \n Creato da ${
                                          allUtentiList.filter(
                                            (user) =>
                                              user.userId ===
                                              currentAnagrafica.createdBy
                                          )[0].completeName
                                        } il ${new Date(
                                          currentAnagrafica.versionDate
                                        ).toLocaleDateString()}`}
                                  </ExtendedTypography>
                                </Stack>
                                <Box sx={{ flexGrow: 1 }} />
                                <Stack direction="row">
                                  <IconButton
                                    edge="end"
                                    aria-label="select"
                                    color="primary"
                                    onClick={() =>
                                      handleSelectedAnagClick(item)
                                    }
                                    sx={{ mr: "1px !important" }}
                                  >
                                    <ModeEditIcon fontSize="small" />
                                  </IconButton>
                                </Stack>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <NoData size="subtitle" />
                )}

                <Divider variant="standard" />

                <Stack direction="row" spacing={1}>
                  <ExtendedTypography variant="titleSmall" color="primary.main">
                    {t("anagrafica.anagraficaForm.collAnag")}
                  </ExtendedTypography>
                  <Box flexGrow={1} />
                  {userPermissions.canCreate && (
                    <IconButton
                      color="primary"
                      size="small"
                      sx={{ mr: 2 }}
                      onClick={() => handleAddNewAnagrafica("collegate")}
                      disabled={currentAnagraficaIdent.id === 0}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </Stack>
                <Divider variant="standard" />

                {currentAnagraficheCollegateList.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table size="small" sx={{ mt: 1 }}>
                      <TableBody>
                        {currentAnagraficheCollegateList.map((item) => (
                          <TableRow
                            key={uuidv4()}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            hover
                            selected={
                              lastSelectedAnag !== null &&
                              tipoAnag === "collegate" &&
                              (lastSelectedAnag.id === item.id ||
                                lastSelectedAnag.parentId === item.id)
                            }
                            style={
                              item.anagFascicoli[0].dataFine !== null
                                ? { opacity: 0.5 }
                                : {}
                            }
                          >
                            <TableCell
                              key={uuidv4()}
                              component="th"
                              scope="row"
                            >
                              <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Stack direction="column">
                                  <Stack direction="row" spacing={1}>
                                    {item.isPg ? (
                                      <BusinessIcon />
                                    ) : (
                                      <PersonIcon />
                                    )}
                                    <ExtendedTypography
                                      variant="titleSmall"
                                      color="primary.main"
                                    >
                                      {item.isPg
                                        ? item.denominazione
                                        : item.nome + " " + item.cognome}
                                    </ExtendedTypography>
                                  </Stack>

                                  <ExtendedTypography
                                    variant="body"
                                    color="primary.main"
                                  >
                                    {baseTipoAnagList
                                      .filter((x) =>
                                        item.anagFascicoli.some(
                                          (y) => y.tipoAnagId === x.id
                                        )
                                      )
                                      .filter(
                                        (a) => a.key === "tipo_anagrafica_3"
                                      ).length > 0
                                      ? baseTipoAnagList
                                          .filter((x) =>
                                            item.anagFascicoli.some(
                                              (y) => y.tipoAnagId === x.id
                                            )
                                          )
                                          .filter(
                                            (a) => a.key === "tipo_anagrafica_3"
                                          )[0].value
                                      : null}
                                    {baseTipoAnagList
                                      .filter((x) =>
                                        item.anagFascicoli.some(
                                          (y) => y.tipoAnagId === x.id
                                        )
                                      )
                                      .filter(
                                        (a) => a.key === "tipo_anagrafica_4"
                                      ).length > 0
                                      ? baseTipoAnagList
                                          .filter((x) =>
                                            item.anagFascicoli.some(
                                              (y) => y.tipoAnagId === x.id
                                            )
                                          )
                                          .filter(
                                            (a) => a.key === "tipo_anagrafica_4"
                                          )[0].value
                                      : null}
                                  </ExtendedTypography>
                                  <ExtendedTypography
                                    variant="body"
                                    color="text.secondary"
                                  >
                                    C.F.: {item.cf}
                                    {item.isPg
                                      ? " | P. Iva: " + item.pIva
                                      : null}
                                  </ExtendedTypography>
                                  <ExtendedTypography
                                    variant="body"
                                    color="text.secondary"
                                  >
                                    {item.identificazioni.length > 0
                                      ? "Identificato in data: " +
                                        new Date(
                                          item.identificazioni[0].identifiedOn
                                        ).toLocaleDateString() +
                                        " da " +
                                        allUtentiList.filter(
                                          (x) =>
                                            x.userId ===
                                            item.identificazioni[0]
                                              .identifiedByUser
                                        )[0].completeName
                                      : "Non identificato"}
                                  </ExtendedTypography>
                                </Stack>
                                <Box sx={{ flexGrow: 1 }} />
                                <Stack direction="row">
                                  <IconButton
                                    edge="end"
                                    aria-label="select"
                                    color="primary"
                                    onClick={() =>
                                      handleSelectedAnagClick(item)
                                    }
                                    sx={{ mr: "1px !important" }}
                                  >
                                    <ModeEditIcon fontSize="small" />
                                  </IconButton>
                                  {userPermissions.canDelete ? (
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      color="error"
                                      onClick={() =>
                                        handleDeleteAnagClick(item)
                                      }
                                      sx={{ mr: "1px !important" }}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  ) : null}
                                </Stack>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <NoData size="subtitle" />
                )}

                <Divider variant="standard" />

                <Stack direction="row" spacing={1}>
                  <ExtendedTypography variant="titleSmall" color="primary.main">
                    {t("anagrafica.anagraficaForm.titEff")}
                  </ExtendedTypography>
                  <Box flexGrow={1} />
                  {userPermissions.canCreate && (
                    <IconButton
                      color="primary"
                      size="small"
                      sx={{ mr: 2 }}
                      onClick={() => handleAddNewAnagrafica("titolare")}
                      disabled={currentAnagraficaIdent.id === 0}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </Stack>
                <Divider variant="standard" />

                {currentAnagraficheTitEffList.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table size="small" sx={{ mt: 1 }}>
                      <TableBody>
                        {currentAnagraficheTitEffList.map((item) => (
                          <TableRow
                            key={uuidv4()}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            hover
                            selected={
                              lastSelectedAnag !== null &&
                              tipoAnag === "titolare" &&
                              (lastSelectedAnag.id === item.id ||
                                lastSelectedAnag.parentId === item.id)
                            }
                            style={
                              item.anagFascicoli[0].dataFine !== null
                                ? { opacity: 0.5 }
                                : {}
                            }
                          >
                            <TableCell
                              key={uuidv4()}
                              component="th"
                              scope="row"
                            >
                              <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Stack direction="column">
                                  <Stack direction="row" spacing={1}>
                                    {item.isPg ? (
                                      <BusinessIcon />
                                    ) : (
                                      <PersonIcon />
                                    )}
                                    <ExtendedTypography
                                      variant="titleSmall"
                                      color="primary.main"
                                    >
                                      {item.isPg
                                        ? item.denominazione
                                        : item.nome + " " + item.cognome}
                                    </ExtendedTypography>
                                  </Stack>

                                  <ExtendedTypography
                                    variant="body"
                                    color="text.secondary"
                                  >
                                    C.F.: {item.cf}
                                    {item.isPg
                                      ? " | P. Iva: " + item.pIva
                                      : null}
                                  </ExtendedTypography>
                                  <ExtendedTypography
                                    variant="body"
                                    color="text.secondary"
                                  >
                                    {item.identificazioni.length > 0
                                      ? "Identificato in data: " +
                                        new Date(
                                          item.identificazioni[0].identifiedOn
                                        ).toLocaleDateString() +
                                        " da " +
                                        allUtentiList.filter(
                                          (x) =>
                                            x.userId ===
                                            item.identificazioni[0]
                                              .identifiedByUser
                                        )[0].completeName
                                      : "Non identificato"}
                                  </ExtendedTypography>
                                </Stack>
                                <Box sx={{ flexGrow: 1 }} />
                                <Stack direction="row">
                                  <IconButton
                                    edge="end"
                                    aria-label="select"
                                    color="primary"
                                    onClick={() =>
                                      handleSelectedAnagClick(item)
                                    }
                                    sx={{ mr: "1px !important" }}
                                  >
                                    <ModeEditIcon fontSize="small" />
                                  </IconButton>
                                  {userPermissions.canDelete ? (
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      color="error"
                                      onClick={() =>
                                        handleDeleteAnagClick(item)
                                      }
                                      sx={{ mr: "1px !important" }}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  ) : null}
                                </Stack>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <NoData size="subtitle" />
                )}
              </Stack>
              <Stack direction="column" spacing={1} sx={{ width: "75%" }}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormGroup>
                    <div>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={isPG}
                            onChange={handleChange}
                            value="Perrsona Giuridica"
                            name="radio-buttons"
                            id="checkPg"
                            disabled={true}
                            inputProps={{ "aria-label": "Persona giuridica" }}
                          />
                        }
                        label="Persona giuridica o ditta individuale"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            checked={!isPG}
                            onChange={handleChange}
                            value="Persona Fisica"
                            name="radio-buttons"
                            id="checkPf"
                            disabled={true}
                            inputProps={{ "aria-label": "Persona fisica" }}
                          />
                        }
                        label="Persona fisica"
                      />
                    </div>
                  </FormGroup>

                  <Box flexGrow={1} />
                  {currentAnagrafica.id > 0 ? (
                    <Fragment>
                      <Stack direction="row" spacing={1}>
                        <Button
                          id="versioniMenuButton"
                          aria-controls={open ? "aziendeMenu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          variant="contained"
                          disableelevation="true"
                          onClick={handleClick}
                          endIcon={<KeyboardArrowDownIcon />}
                          size="small"
                          disabled={isCessato}
                        >
                          Versione n. {currentAnagrafica.versionN} dal{" "}
                          {new Date(
                            currentAnagrafica.versionDate
                          ).toLocaleDateString()}
                        </Button>
                        {currentAnagraficheHistoryFilteredList.length > 0 ? (
                          <Menu
                            id="versioniMenu"
                            aria-labelledby="versioniMenuButton"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            disabled={isCessato}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            {currentAnagraficheHistoryFilteredList.map(
                              (item, index) => (
                                <MenuItem
                                  key={uuidv4()}
                                  dense
                                  onClick={() =>
                                    handleSelectedAnagVerClick(item)
                                  }
                                  selected={
                                    (item.id === currentAnagrafica.id) ===
                                    item.id
                                  }
                                >
                                  <ExtendedTypography variant="body2">
                                    Versione n. {item.versionN} dal{" "}
                                    {new Date(
                                      item.versionDate
                                    ).toLocaleDateString()}
                                    {index > 0
                                      ? " al " +
                                        new Date(
                                          currentAnagraficheHistoryFilteredList[
                                            index - 1
                                          ].versionDate
                                        ).toLocaleDateString()
                                      : null}
                                  </ExtendedTypography>
                                </MenuItem>
                              )
                            )}
                          </Menu>
                        ) : null}

                        {currentAnagraficheHistoryFilteredList.length > 1 &&
                        currentAnagrafica.versionN ===
                          currentAnagraficheHistoryFilteredList[0].versionN &&
                        currentAnagrafica.identificazioni.filter(
                          (x) =>
                            x.identifiedByUser === loggedUserId ||
                            x.createdBy === loggedUserId
                        ).length > 0 &&
                        userPermissions.canDelete ? (
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              handleDeleteAnagVerClick(currentAnagrafica)
                            }
                            disabled={isCessato}
                            endIcon={<DeleteIcon />}
                            size="small"
                          >
                            Elimina
                          </Button>
                        ) : null}
                      </Stack>
                    </Fragment>
                  ) : null}
                </Stack>

                <Grid container>
                  <Grid item xs={5} sx={{ pr: 2 }}>
                    <TextField
                      id="txtCf"
                      key={uuidv4()}
                      label={
                        showAnagForm
                          ? "Codice Fiscale"
                          : "Ricerca anagrafica per Codice fiscale o Cognome"
                      }
                      defaultValue={currentAnagrafica.cf}
                      size="small"
                      fullWidth
                      required
                      disabled={currentAnagrafica.id > 0 || isCessato}
                      onChange={handleChange}
                      inputProps={{ autocomplete: "nope" }}
                    />
                  </Grid>
                  {currentAnagrafica.isPg ? (
                    <Grid item xs={5}>
                      <TextField
                        id="txtPIva"
                        key={uuidv4()}
                        label="P. Iva"
                        defaultValue={currentAnagrafica.pIva}
                        size="small"
                        fullWidth
                        required={isPG}
                        disabled={currentAnagrafica.id > 0 || isCessato}
                        onChange={handleChange}
                        onBlur={handleBlurPIva}
                        inputProps={{ autocomplete: "nope" }}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={2} sx={{ pt: 3.25, pl: 2 }}>
                    <Button
                      variant="contained"
                      size="small"
                      endIcon={<SearchIcon />}
                      onClick={handleSearchClick}
                      sx={{ width: "75%" }}
                      disabled={
                        (currentAnagrafica.id > 0 &&
                          currentAnagraficheHistoryList.filter(
                            (x) =>
                              (x.id === currentAnagrafica.id ||
                                x.parentId === currentAnagrafica.id) &&
                              x.versionN > 1
                          ).length > 0) ||
                        tipoAnag === "cliente" ||
                        isCessato
                      }
                    >
                      {t("fascicolo.fascicoloNew.newSearch1")}
                    </Button>
                  </Grid>
                </Grid>

                {showAnagForm ? (
                  <Fragment>
                    <Divider variant="standard" sx={{ pt: 1 }} />

                    {tipoAnag === "collegate" || tipoAnag === "titolare" ? (
                      <Fragment>
                        <Stack direction="row" spacing={2}>
                          <Autocomplete
                            id="ddlTipoAnag"
                            key={uuidv4()}
                            size="small"
                            options={baseTipoAnagList.filter(
                              (x) =>
                                x.key === "tipo_anagrafica_3" ||
                                x.key === "tipo_anagrafica_4"
                            )}
                            sx={{ width: "100%" }}
                            fullWidth
                            disabled={tipoAnag === "titolare" || isCessato}
                            onChange={handleChange}
                            isOptionEqualToValue={(option, value) =>
                              currentAnagraficaFascicolo.tipoAnagId ===
                              undefined
                                ? option.id === value.id
                                : option.id ===
                                  currentAnagraficaFascicolo.tipoAnagId
                            }
                            getOptionLabel={(option) => option.value}
                            defaultValue={
                              currentAnagraficaFascicolo.tipoAnagId !==
                              undefined
                                ? baseTipoAnagList.filter(
                                    (item) =>
                                      item.id ===
                                      currentAnagraficaFascicolo.tipoAnagId
                                  )[0]
                                : null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Tipologia Anagrafica"
                                sx={{ lineHeight: 1 }}
                              />
                            )}
                          />
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={it}
                          >
                            <DatePicker
                              label="Data cessazione ruolo"
                              closeOnSelect
                              format="dd/MM/yyyy"
                              mask="__/__/____"
                              maxDate={new Date()}
                              minDate={
                                isDateValid(currentAnagraficaIdent.identifiedOn)
                                  ? new Date(
                                      currentAnagraficaIdent.identifiedOn
                                    )
                                  : null
                              }
                              value={
                                isDateValid(currentAnagraficaFascicolo.dataFine)
                                  ? new Date(
                                      currentAnagraficaFascicolo.dataFine
                                    )
                                  : null
                              }
                              onChange={handleChangeCessDate}
                              slotProps={{
                                textField: {
                                  required: false,
                                  placeholder: "dd/MM/yyyy",
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Stack>

                        {tipoAnag === "collegate" && (
                          <Stack direction="row" spacing={2}>
                            <Autocomplete
                              id="ddlTipoRapp"
                              key={uuidv4()}
                              size="small"
                              options={baseTipoRappList}
                              sx={{ width: "50%" }}
                              fullWidth
                              onChange={handleChange}
                              isOptionEqualToValue={(option, value) =>
                                currentAnagraficaFascicolo.tipoRappId ===
                                undefined
                                  ? option.id === value.id
                                  : option.id ===
                                    currentAnagraficaFascicolo.tipoRappId
                              }
                              getOptionLabel={(option) => option.name}
                              defaultValue={
                                currentAnagraficaFascicolo.tipoRappId !==
                                undefined
                                  ? baseTipoRappList.filter(
                                      (item) =>
                                        item.id ===
                                        currentAnagraficaFascicolo.tipoRappId
                                    )[0]
                                  : null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Rapppresentato mediante"
                                  sx={{ lineHeight: 1 }}
                                />
                              )}
                              disabled={isCessato}
                            />

                            <TextField
                              id="txtTipoRappDesc"
                              key={uuidv4()}
                              label="Descrizione tipo rappresentanza"
                              size="small"
                              sx={{ width: "50%" }}
                              multiline
                              rows={1}
                              defaultValue={
                                currentAnagraficaFascicolo.tipoRappDesc
                              }
                              onChange={handleChange}
                              inputProps={{ autocomplete: "nope" }}
                              disabled={isCessato || !enableTipoRappDesc}
                            />
                          </Stack>
                        )}
                        <Divider variant="standard" sx={{ pt: 1 }} />
                      </Fragment>
                    ) : null}

                    <Grid
                      container
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Grid item xs={4}>
                        <ExtendedTypography
                          variant="titleSmall"
                          color="primary.main"
                        >
                          {t("anagrafica.anagraficaForm.ident")}
                        </ExtendedTypography>
                      </Grid>
                      <Grid item xs={8} sx={{ textAlign: "right" }}>
                        <Button
                          variant="text"
                          size="small"
                          startIcon={
                            <img
                              src="/img/EcLogo.png"
                              width={20}
                              alt="EcLogo"
                            />
                          }
                          sx={{ width: "fit-content !important" }}
                          onClick={() => handleEuroConfClick(17237)}
                        >
                          {t("anagrafica.anagraficaForm.adegVer")}
                        </Button>
                        {tipoAnag === "titolare" ? (
                          <Button
                            variant="text"
                            size="small"
                            startIcon={
                              <img
                                src="/img/EcLogo.png"
                                width={20}
                                alt="EcLogo"
                              />
                            }
                            sx={{ width: "fit-content !important" }}
                            onClick={() => handleEuroConfClick(17238)}
                          >
                            {t("anagrafica.anagraficaForm.titAnt")}
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>

                    <Autocomplete
                      id="ddlModIdent"
                      key={uuidv4()}
                      size="small"
                      options={baseTipoIdentList}
                      sx={{ width: "100%" }}
                      fullWidth
                      onChange={handleChange}
                      isOptionEqualToValue={(option, value) =>
                        currentAnagraficaIdent.modalitaIdentId === undefined
                          ? option.id === value.id
                          : option.id === currentAnagraficaIdent.modalitaIdentId
                      }
                      getOptionLabel={(option) => option.value}
                      defaultValue={
                        currentAnagraficaIdent.modalitaIdentId !== undefined
                          ? baseTipoIdentList.filter(
                              (item) =>
                                item.id ===
                                currentAnagraficaIdent.modalitaIdentId
                            )[0]
                          : null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Modalità identificazione"
                          sx={{ lineHeight: 1 }}
                        />
                      )}
                      disabled={isCessato}
                    />
                    <Fragment>
                      <Grid container>
                        <Grid item xs={4} sx={{ pr: 2 }}>
                          <Autocomplete
                            id="ddlIdentBy"
                            key={uuidv4()}
                            size="small"
                            options={utentiList}
                            sx={{ width: "100%" }}
                            fullWidth
                            onChange={handleChange}
                            disabled={isCessato}
                            isOptionEqualToValue={(option, value) =>
                              currentAnagraficaIdent.identifiedByUser ===
                              undefined
                                ? option.userId === value.userId
                                : option.userId ===
                                  currentAnagraficaIdent.identifiedByUser
                            }
                            getOptionLabel={(option) => option.completeName}
                            defaultValue={
                              currentAnagraficaIdent.identifiedByUser !==
                              undefined
                                ? utentiList.filter(
                                    (item) =>
                                      item.userId ===
                                      currentAnagraficaIdent.identifiedByUser
                                  )[0]
                                : utentiList.filter(
                                    (item) =>
                                      item.userId ===
                                      currentAnagrafica.createdBy
                                  )[0]
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Identificato da"
                                sx={{ lineHeight: 1 }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4} sx={{ pr: 2 }}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={it}
                          >
                            <DatePicker
                              label="Identificato in data"
                              closeOnSelect
                              format="dd/MM/yyyy"
                              mask="__/__/____"
                              maxDate={new Date()}
                              value={
                                isDateValid(currentAnagraficaIdent.identifiedOn)
                                  ? new Date(
                                      currentAnagraficaIdent.identifiedOn
                                    )
                                  : null
                              }
                              onChange={handleChangeIdentDate}
                              slotProps={{
                                textField: {
                                  required: true,
                                  placeholder: "dd/MM/yyyy",
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                              disabled={isCessato}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                          <Autocomplete
                            id="ddlModIdentDoc"
                            key={uuidv4()}
                            size="small"
                            fullWidth
                            options={
                              isPG
                                ? baseTipoDocList.filter(
                                    (x) =>
                                      x.personType === "PG" ||
                                      x.personType === null ||
                                      x.personType === ""
                                  )
                                : baseTipoDocList.filter(
                                    (x) =>
                                      x.personType === "PF" ||
                                      x.personType === null ||
                                      x.personType === ""
                                  )
                            }
                            sx={{ width: "100%" }}
                            onChange={handleChange}
                            isOptionEqualToValue={(option, value) =>
                              currentAnagraficaIdent.docTipoId === undefined
                                ? option.id === value.id
                                : option.id === currentAnagraficaIdent.docTipoId
                            }
                            getOptionLabel={(option) => option.value}
                            defaultValue={
                              currentAnagraficaIdent.docTipoId !== undefined
                                ? baseTipoDocList.filter(
                                    (item) =>
                                      item.id ===
                                      currentAnagraficaIdent.docTipoId
                                  )[0]
                                : null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Verificato tramite"
                                sx={{ lineHeight: 1 }}
                              />
                            )}
                            disabled={isCessato}
                          />
                        </Grid>
                      </Grid>
                      {showDocTipoDetails ? (
                        <Grid container>
                          <Grid item xs={2} sx={{ pr: 2 }}>
                            <TextField
                              id="txtDocN"
                              key={uuidv4()}
                              label="Numero"
                              size="small"
                              fullWidth
                              defaultValue={currentAnagraficaIdent.docN}
                              onChange={handleChange}
                              inputProps={{ autocomplete: "nope" }}
                              disabled={isCessato}
                            />
                          </Grid>
                          <Grid item xs={4} sx={{ pr: 2 }}>
                            <TextField
                              id="txtDocRilDa"
                              key={uuidv4()}
                              label="Rilasciato da"
                              size="small"
                              fullWidth
                              defaultValue={currentAnagraficaIdent.docRilDa}
                              onChange={handleChange}
                              inputProps={{ autocomplete: "nope" }}
                              disabled={isCessato}
                            />
                          </Grid>
                          <Grid item xs={3} sx={{ pr: 2 }}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={it}
                            >
                              <DatePicker
                                label="Rilasciato il"
                                closeOnSelect
                                format="dd/MM/yyyy"
                                mask="__/__/____"
                                maxDate={
                                  isDateValid(
                                    currentAnagraficaIdent.identifiedOn
                                  )
                                    ? new Date(
                                        currentAnagraficaIdent.identifiedOn
                                      )
                                    : null
                                }
                                value={
                                  isDateValid(currentAnagraficaIdent.docRilData)
                                    ? new Date(
                                        currentAnagraficaIdent.docRilData
                                      )
                                    : null
                                }
                                onChange={handleChangeDocRilDate}
                                slotProps={{
                                  textField: {
                                    required: true,
                                    placeholder: "dd/MM/yyyy",
                                    size: "small",
                                    fullWidth: true,
                                    onBlur: handleBlurDate,
                                  },
                                }}
                                disabled={isCessato}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={3}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={it}
                            >
                              <DatePicker
                                label="Scade il"
                                closeOnSelect
                                format="dd/MM/yyyy"
                                mask="__/__/____"
                                minDate={
                                  isDateValid(currentAnagraficaIdent.docRilData)
                                    ? new Date(
                                        currentAnagraficaIdent.docRilData
                                      )
                                    : null
                                }
                                value={
                                  isDateValid(currentAnagraficaIdent.docScad)
                                    ? new Date(currentAnagraficaIdent.docScad)
                                    : null
                                }
                                onChange={handleChangeDocScad}
                                slotProps={{
                                  textField: {
                                    required: true,
                                    placeholder: "dd/MM/yyyy",
                                    size: "small",
                                    fullWidth: true,
                                  },
                                }}
                                disabled={isCessato}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      ) : null}
                    </Fragment>
                    {/* ) : null} */}

                    {showDocTipoAp ? (
                      <Grid container>
                        <Grid item xs={8} sx={{ pr: 2 }}>
                          <TextField
                            id="txtApNome"
                            key={uuidv4()}
                            label="Denominazione altro professionista"
                            size="small"
                            fullWidth
                            defaultValue={currentAnagraficaIdent.apNome}
                            onChange={handleChange}
                            inputProps={{ autocomplete: "nope" }}
                            disabled={isCessato}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            id="txtApCf"
                            key={uuidv4()}
                            label={t("anagrafica.ImportDesktop.codFisc")}
                            size="small"
                            fullWidth
                            defaultValue={currentAnagraficaIdent.apCf}
                            onChange={handleChange}
                            inputProps={{ autocomplete: "nope" }}
                            disabled={isCessato}
                          />
                        </Grid>
                      </Grid>
                    ) : null}

                    <Divider variant="standard" sx={{ pt: 1 }} />
                    <ExtendedTypography
                      variant="titleSmall"
                      color="primary.main"
                    >
                      {t("anagrafica.anagraficaForm.gen")}
                    </ExtendedTypography>

                    {!isPG ? (
                      <Grid container>
                        <Grid item xs={5} sx={{ pr: 2 }}>
                          <TextField
                            id="txtNome"
                            key={uuidv4()}
                            label="Nome"
                            defaultValue={currentAnagrafica.nome}
                            size="small"
                            fullWidth
                            required
                            onChange={handleChange}
                            onBlur={handleBlurPIva}
                            inputProps={{ autocomplete: "nope" }}
                            disabled={isCessato}
                          />
                        </Grid>
                        <Grid item xs={5} sx={{ pr: 2 }}>
                          <TextField
                            id="txtCognome"
                            key={uuidv4()}
                            label="Cognome"
                            defaultValue={currentAnagrafica.cognome}
                            size="small"
                            fullWidth
                            required
                            onChange={handleChange}
                            onBlur={handleBlurPIva}
                            inputProps={{ autocomplete: "nope" }}
                            disabled={isCessato}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Autocomplete
                            id="ddlGenere"
                            key={uuidv4()}
                            size="small"
                            options={baseGenereList}
                            sx={{ width: "100%" }}
                            fullWidth
                            onChange={handleChange}
                            isOptionEqualToValue={(option, value) =>
                              currentAnagrafica.genereId === undefined
                                ? option.id === value.id
                                : option.id === currentAnagrafica.genereId
                            }
                            getOptionLabel={(option) => option.value}
                            defaultValue={
                              currentAnagrafica.genereId !== undefined
                                ? baseGenereList.filter(
                                    (item) =>
                                      item.id === currentAnagrafica.genereId
                                  )[0]
                                : null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Genere"
                                sx={{ lineHeight: 1 }}
                              />
                            )}
                            disabled={isCessato}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            id="txtDenominazione"
                            key={uuidv4()}
                            label="Denominazione"
                            defaultValue={currentAnagrafica.denominazione}
                            size="small"
                            fullWidth
                            required={isPG}
                            onChange={handleChange}
                            inputProps={{ autocomplete: "nope" }}
                            disabled={isCessato}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {!isPG ? (
                      <Fragment>
                        <Stack direction="row" spacing={2}>
                          <TextField
                            id="txtProfessione"
                            key={uuidv4()}
                            label="Professione"
                            defaultValue={currentAnagrafica.professione}
                            size="small"
                            fullWidth
                            required
                            onChange={handleChange}
                            inputProps={{ autocomplete: "nope" }}
                            disabled={isCessato}
                          />
                          <TextField
                            id="txtNatoA"
                            key={uuidv4()}
                            label="Nato a"
                            defaultValue={currentAnagrafica.nascitaLuogo}
                            size="small"
                            fullWidth
                            required
                            onChange={handleChange}
                            onBlur={handleBlurPIva}
                            inputProps={{ autocomplete: "nope" }}
                            disabled={isCessato}
                          />
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={it}
                          >
                            <DatePicker
                              label="Nato il"
                              closeOnSelect
                              format="dd/MM/yyyy"
                              mask="__/__/____"
                              maxDate={new Date()}
                              value={
                                isDateValid(currentAnagrafica.nascitaData)
                                  ? new Date(currentAnagrafica.nascitaData)
                                  : null
                              }
                              onChange={handleChangeDate}
                              slotProps={{
                                textField: {
                                  required: true,
                                  placeholder: "dd/MM/yyyy",
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                              disabled={isCessato}
                            />
                          </LocalizationProvider>
                        </Stack>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Stack direction="row" spacing={2}>
                          <TextField
                            id="txtReaComune"
                            key={uuidv4()}
                            label="Iscritta al R.I. di"
                            defaultValue={currentAnagrafica.reaComune}
                            size="small"
                            fullWidth
                            onChange={handleChange}
                            inputProps={{ autocomplete: "nope" }}
                            disabled={isCessato}
                          />
                          <TextField
                            id="txtReaN"
                            key={uuidv4()}
                            label="REA N."
                            defaultValue={currentAnagrafica.reaN}
                            size="small"
                            fullWidth
                            onChange={handleChange}
                            inputProps={{ autocomplete: "nope" }}
                            disabled={isCessato}
                          />
                        </Stack>
                      </Fragment>
                    )}

                    <Divider variant="standard" sx={{ pt: 2 }} />
                    <ExtendedTypography
                      variant="titleSmall"
                      color="primary.main"
                    >
                      {!isPG
                        ? t("anagrafica.anagraficaForm.address")
                        : t("anagrafica.anagraficaForm.legSed")}
                    </ExtendedTypography>

                    <Grid container>
                      <Grid item xs={4} sx={{ pr: 2 }}>
                        <Autocomplete
                          id="ddlAddrPaese"
                          key={uuidv4()}
                          size="small"
                          fullWidth
                          options={basePaeseList}
                          sx={{ width: "100%" }}
                          isOptionEqualToValue={(option, value) =>
                            currentAnagrafica.addrPaeseId === undefined
                              ? option.id === value.id
                              : option.id === currentAnagrafica.addrPaeseId
                          }
                          getOptionLabel={(option) => option.nome}
                          defaultValue={
                            currentAnagrafica.addrPaeseId !== undefined
                              ? basePaeseList.filter(
                                  (item) =>
                                    item.id === currentAnagrafica.addrPaeseId
                                )[0]
                              : null
                          }
                          renderInput={(params) => (
                            <TextField {...params} required label="Nazione" />
                          )}
                          onChange={handleChange}
                          disabled={isCessato}
                        />
                      </Grid>
                      <Grid item xs={3} sx={{ pr: 2 }}>
                        {isAddrPaeseIta ? (
                          <Autocomplete
                            id="ddlAddrProvincia"
                            key={uuidv4()}
                            size="small"
                            fullWidth
                            options={baseProvinciaList}
                            sx={{ width: "100%" }}
                            isOptionEqualToValue={(option, value) =>
                              currentAnagrafica.addrProvinciaId === undefined
                                ? option.id === value.id
                                : option.id ===
                                  currentAnagrafica.addrProvinciaId
                            }
                            getOptionLabel={(option) => option.nome}
                            defaultValue={
                              currentAnagrafica.addrProvinciaId !== undefined
                                ? baseProvinciaList.filter(
                                    (item) =>
                                      item.id ===
                                      currentAnagrafica.addrProvinciaId
                                  )[0]
                                : null
                            }                            
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Provincia"
                              />
                            )}
                            onChange={handleChange}                            
                            disabled={isCessato}
                          />
                        ) : (
                          <TextField
                            id="txtAddrProvincia"
                            key={uuidv4()}
                            label="Provincia"
                            size="small"
                            defaultValue={currentAnagrafica.addrProvincia}
                            fullWidth
                            required
                            onChange={handleChange}
                            inputProps={{ autocomplete: "nope" }}
                            disabled={isCessato}
                          />
                        )}
                      </Grid>
                      <Grid item xs={5}>
                        {isAddrPaeseIta ? (
                          <Autocomplete
                            id="ddlAddrComune"
                            key={uuidv4()}
                            size="small"
                            fullWidth
                            options={baseComuneList}
                            sx={{ width: "100%" }}
                            isOptionEqualToValue={(option, value) =>
                              currentAnagrafica.addrComuneId === undefined
                                ? option.id === value.id
                                : option.id === currentAnagrafica.addrComuneId
                            }
                            getOptionLabel={(option) => option.nome}
                            defaultValue={
                              currentAnagrafica.addrComuneId !== undefined
                                ? baseComuneList.filter(
                                    (item) =>
                                      item.id === currentAnagrafica.addrComuneId
                                  )[0]
                                : null
                            }
                            renderInput={(params) => (
                              <TextField {...params} required label="Comune" />
                            )}
                            onChange={handleChange}
                            disabled={isCessato}
                          />
                        ) : (
                          <TextField
                            id="txtAddrComune"
                            key={uuidv4()}
                            label="Comune"
                            size="small"
                            defaultValue={currentAnagrafica.addrComune}
                            fullWidth
                            required
                            onChange={handleChange}
                            inputProps={{ autocomplete: "nope" }}
                            disabled={isCessato}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={8} sx={{ pr: 2 }}>
                        <TextField
                          id="txtAddrIndirizzo"
                          key={uuidv4()}
                          label="Indirizzo"
                          size="small"
                          defaultValue={currentAnagrafica.addrIndirizzo}
                          fullWidth
                          required
                          onChange={handleChange}
                          inputProps={{ autocomplete: "nope" }}
                          disabled={isCessato}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField
                          id="txtAddrN"
                          key={uuidv4()}
                          label="N."
                          size="small"
                          defaultValue={currentAnagrafica.addrN}
                          fullWidth
                          onChange={handleChange}
                          inputProps={{ autocomplete: "nope" }}
                          disabled={isCessato}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          id="txtAddrCap"
                          key={uuidv4()}
                          label="CAP"
                          size="small"
                          defaultValue={currentAnagrafica.addrCap}
                          fullWidth
                          required
                          onChange={handleChange}
                          inputProps={{ autocomplete: "nope" }}
                          disabled={isCessato}
                        />
                      </Grid>
                    </Grid>

                    <Divider variant="standard" sx={{ pt: 2 }} />

                    <ExtendedAccordion
                      defaultExpanded={currentAnagrafica.addr2PaeseId > 0}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <ExtendedTypography
                          variant="titleSmall"
                          color="primary.main"
                        >
                          {!isPG ? "Indirizzo di domicilio" : "Sede operativa"}
                        </ExtendedTypography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ pl: "15px !important", pr: "15px !important" }}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    id="chbSameAddress"
                                    checked={isSameAddress}
                                    onChange={handleChange}
                                    disabled={isCessato}
                                  />
                                }
                                label={
                                  !isPG
                                    ? "Copia da indirizzo di residenza"
                                    : "Copia da sede legale"
                                }
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item xs={4} sx={{ pr: 2 }}>
                            <Autocomplete
                              id="ddlAddr2Paese"
                              key={uuidv4()}
                              size="small"
                              fullWidth
                              options={basePaeseList}
                              sx={{ width: "100%" }}
                              isOptionEqualToValue={(option, value) =>
                                currentAnagrafica.addr2PaeseId === undefined
                                  ? option.id === value.id
                                  : option.id === currentAnagrafica.addr2PaeseId
                              }
                              getOptionLabel={(option) => option.nome}
                              defaultValue={
                                currentAnagrafica.addr2PaeseId !== undefined
                                  ? basePaeseList.filter(
                                      (item) =>
                                        item.id ===
                                        currentAnagrafica.addr2PaeseId
                                    )[0]
                                  : null
                              }
                              renderInput={(params) => (
                                <TextField {...params} label="Nazione" />
                              )}
                              onChange={handleChange}
                              disabled={isCessato}
                            />
                          </Grid>
                          <Grid item xs={3} sx={{ pr: 2 }}>
                            {isAddr2PaeseIta ? (
                              <Autocomplete
                                id="ddlAddr2Provincia"
                                key={uuidv4()}
                                size="small"
                                fullWidth
                                options={baseProvinciaList}
                                sx={{ width: "100%" }}
                                isOptionEqualToValue={(option, value) =>
                                  currentAnagrafica.addr2ProvinciaId ===
                                  undefined
                                    ? option.id === value.id
                                    : option.id ===
                                      currentAnagrafica.addr2ProvinciaId
                                }
                                getOptionLabel={(option) => option.nome}
                                defaultValue={
                                  currentAnagrafica.addr2ProvinciaId !==
                                  undefined
                                    ? baseProvinciaList.filter(
                                        (item) =>
                                          item.id ===
                                          currentAnagrafica.addr2ProvinciaId
                                      )[0]
                                    : null
                                }
                                renderInput={(params) => (
                                  <TextField {...params} label="Provincia" />
                                )}
                                onChange={handleChange}
                                disabled={isCessato}
                              />
                            ) : (
                              <TextField
                                id="txtAddr2Provincia"
                                key={uuidv4()}
                                label="Provincia"
                                size="small"
                                defaultValue={currentAnagrafica.addr2Provincia}
                                fullWidth
                                onChange={handleChange}
                                inputProps={{ autocomplete: "nope" }}
                                disabled={isCessato}
                              />
                            )}
                          </Grid>
                          <Grid item xs={5}>
                            {isAddr2PaeseIta ? (
                              <Autocomplete
                                id="ddlAddr2Comune"
                                key={uuidv4()}
                                size="small"
                                fullWidth
                                options={baseComuneList2}
                                sx={{ width: "100%" }}
                                isOptionEqualToValue={(option, value) =>
                                  currentAnagrafica.addr2ComuneId === undefined
                                    ? option.id === value.id
                                    : option.id ===
                                      currentAnagrafica.addr2ComuneId
                                }
                                getOptionLabel={(option) => option.nome}
                                defaultValue={
                                  currentAnagrafica.addr2ComuneId !== undefined
                                    ? baseComuneList2.filter(
                                        (item) =>
                                          item.id ===
                                          currentAnagrafica.addr2ComuneId
                                      )[0]
                                    : null
                                }
                                renderInput={(params) => (
                                  <TextField {...params} label="Comune" />
                                )}
                                onChange={handleChange}
                                disabled={isCessato}
                              />
                            ) : (
                              <TextField
                                id="txtAddr2Comune"
                                key={uuidv4()}
                                label="Comune"
                                size="small"
                                defaultValue={currentAnagrafica.addr2Comune}
                                fullWidth
                                onChange={handleChange}
                                inputProps={{ autocomplete: "nope" }}
                                disabled={isCessato}
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item xs={8} sx={{ pr: 2 }}>
                            <TextField
                              id="txtAddr2Indirizzo"
                              key={uuidv4()}
                              label="Indirizzo"
                              size="small"
                              defaultValue={currentAnagrafica.addr2Indirizzo}
                              fullWidth
                              onChange={handleChange}
                              inputProps={{ autocomplete: "nope" }}
                              disabled={isCessato}
                            />
                          </Grid>
                          <Grid item xs={2} sx={{ pr: 2 }}>
                            <TextField
                              id="txtAddr2N"
                              key={uuidv4()}
                              label="N."
                              size="small"
                              defaultValue={currentAnagrafica.addr2N}
                              fullWidth
                              onChange={handleChange}
                              inputProps={{ autocomplete: "nope" }}
                              disabled={isCessato}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              id="txtAddr2Cap"
                              key={uuidv4()}
                              label="CAP"
                              size="small"
                              defaultValue={currentAnagrafica.addr2Cap}
                              fullWidth
                              onChange={handleChange}
                              inputProps={{ autocomplete: "nope" }}
                              disabled={isCessato}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </ExtendedAccordion>

                    {!isPG ? (
                      <Fragment>
                        <Divider variant="standard" sx={{ pt: 2 }} />
                        <ExtendedTypography
                          variant="titleSmall"
                          color="primary.main"
                        >
                          {t("anagrafica.anagraficaForm.ppe")}
                        </ExtendedTypography>
                        <FormControl>
                          <RadioGroup
                            name="radioBtnIsPep"
                            onChange={handleChange}
                            disabled={isCessato}
                          >
                            <FormControlLabel
                              value="isNotPep"
                              checked={anagPep.IsNotPep}
                              control={<Radio />}
                              label="Non è una persona politicamente esposta"
                              disabled={isCessato}
                            />
                            <FormControlLabel
                              value="isPep"
                              checked={anagPep.IsPep}
                              control={<Radio />}
                              label="E' una persona politicamente esposta"
                              disabled={isCessato}
                            />
                            <FormControlLabel
                              value="isNotPepMoreThanYear"
                              checked={anagPep.IsNotPepMoreThanYear}
                              control={<Radio />}
                              label="Non è una persona politicamente esposta da più di un anno"
                              disabled={isCessato}
                            />
                          </RadioGroup>
                        </FormControl>

                        {anagPep.IsPep ? (
                          <Grid container xs={12} sx={{ mt: 2 }}>
                            <Grid item xs={4} sx={{ pr: 2 }}>
                              <Autocomplete
                                id="ddlPepCarica"
                                key={uuidv4()}
                                label="Ruolo politico ricoperto"
                                size="small"
                                options={basePoliticalRole}
                                onChange={handleChange}
                                fullWidth
                                sx={{ width: "100%" }}
                                defaultValue={
                                  basePoliticalRole.filter(
                                    (role) =>
                                      role === currentAnagrafica.pepRuolo
                                  )[0]
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label="Carica politica ricoperta"
                                  />
                                )}
                                disabled={isCessato}
                              />
                            </Grid>
                            <Grid item xs={8} sx={{ pl: 0 }}>
                              <TextField
                                id="txtPepCarica"
                                key={uuidv4()}
                                label="Descrizione carica politica"
                                size="small"
                                fullWidth
                                defaultValue={currentAnagrafica.pepCarica}
                                onChange={handleChange}
                                inputProps={{ autocomplete: "nope" }}
                                disabled={isCessato}
                              />
                            </Grid>
                          </Grid>
                        ) : null}

                        {tipoAnag === "titolare" ? (
                          <Fragment>
                            <Divider variant="standard" sx={{ pt: 2 }} />
                            <ExtendedTypography
                              variant="titleSmall"
                              color="primary.main"
                            >
                              {t("anagrafica.anagraficaForm.partTip")}
                            </ExtendedTypography>

                            <Autocomplete
                              id="ddlTipoPart"
                              key={uuidv4()}
                              size="small"
                              options={baseTipoPartList}
                              sx={{ width: "100%" }}
                              fullWidth
                              onChange={handleChange}
                              isOptionEqualToValue={(option, value) =>
                                currentAnagraficaFascicolo.tipoPartId ===
                                undefined
                                  ? option.id === value.id
                                  : option.id ===
                                    currentAnagraficaFascicolo.tipoPartId
                              }
                              getOptionLabel={(option) => option.descrizione}
                              groupBy={(option) => option.nome}
                              defaultValue={
                                currentAnagraficaFascicolo.tipoPartId !==
                                undefined
                                  ? baseTipoPartList.filter(
                                      (item) =>
                                        item.id ===
                                        currentAnagraficaFascicolo.tipoPartId
                                    )[0]
                                  : null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  label="Tipologia Partecipazione"
                                  sx={{ lineHeight: 1 }}
                                />
                              )}
                              // disabled={isCessato}
                            />

                            {showTipoPartDesc ? (
                              <TextField
                                id="txtTipoPartDesc"
                                key={uuidv4()}
                                label="Descrizione"
                                size="small"
                                fullWidth
                                multiline
                                rows={2}
                                defaultValue={
                                  currentAnagraficaFascicolo.tipoPartDesc
                                }
                                onChange={handleChange}
                                inputProps={{ autocomplete: "nope" }}
                                disabled={isCessato}
                              />
                            ) : null}
                          </Fragment>
                        ) : null}
                      </Fragment>
                    ) : null}
                  </Fragment>
                ) : null}
              </Stack>
            </Stack>
          </Fragment>
        ) : null}
      </VaporPage.Section>

      {isDataLoaded && userPermissions.canCreate ? (
        <VaporToolbar
          variant="regular"
          size="medium"
          withoutAppBar={false}
          contentRight={
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="secondary"
                size="medium"
                endIcon={<CancelIcon />}
                onClick={handleCancelClick}
              >
                {t("actions.annulla")}
              </Button>
              {enableFormControls && (
                <Button
                  variant="contained"
                  size="medium"
                  endIcon={<AddIcon />}
                  onClick={checkSaveConfirmNeedVer}
                  disabled={
                    currentAnagraficaIdent.id === 0 ||
                    currentAnagraficaFascicolo.id === 0 ||
                    currentAnagrafica.id === 0 ||
                    isCessato
                  }
                >
                  {t("anagrafica.anagraficaForm.saveNewVer")}
                </Button>
              )}

              {(currentAnagraficaIdent.identifiedByUser === loggedUserId ||
                isCessato) &&
                isUpdateEnable && (
                  <Button
                    variant="contained"
                    size="medium"
                    endIcon={<SaveIcon />}
                    onClick={checkSaveConfirmNeed}
                    disabled={!enableFormControls}
                  >
                    {currentAnagrafica.id === 0 ||
                    currentAnagraficaFascicolo.id === 0
                      ? t("actions.salva")
                      : t("actions.aggiorna")}
                  </Button>
                )}
            </Stack>
          }
        ></VaporToolbar>
      ) : null}
    </Fragment>
  );
  //#endregion
};
