import React from "react";
import { DataGridPro,GridToolbar,itIT } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { useTranslation } from '@onefront/react-sdk';

export const PrestazioniList = ({prestListFromParent,utentiFromParentList,onSelection}) => {

    const prestList = prestListFromParent
    const profList = utentiFromParentList
    const { t } = useTranslation();    
    const columns = [        
        { 
            field: 'descrizione', 
            headerName : 'Descrizione', 
            flex: 0.6,            
            valueFormatter : (params) => {
                if(params.value === "")
                    return "{Nessuna Descrizione}"
                return params.value
            }
        },
        { field:'prestazioneNome', headerName: 'Prestazione'},
        { 
            field:'dataStart',
            headerName: t("autovalutazione.form.data"),
            type: "date",    
            flex:0.2,       
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return new Date(params.value).toLocaleDateString();
            }
        },        
        { 
            field: 'professionisti', 
            headerName: 'Professionista', 
            flex:0.2,
            valueFormatter : (params) => {
                if(params.value == null){
                    return "";
                }
                let nameToReutrn = profList.filter(
                    (x) => x.userId === params.value[0].UserId
                )[0].completeName
                return nameToReutrn
            }
        }
    ]

    const handleRowSelection = (event) => {        
        onSelection(event)
    }

    const paginationModel = { page: 0, pageSize: 5 };
    return (
        <Paper sx={{height : 400 , width : '100%'}}>
            <DataGridPro
                rows={prestList}
                columns={columns}
                initialState={{pagination : paginationModel}}
                slots={{ toolbar: GridToolbar }}
                localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                }
                pageSizeOptions={[5,10]}
                checkboxSelection
                onRowSelectionModelChange={handleRowSelection}
                sx={{border: 0}}
                slotProps={{
                    toolbar: { printOptions: { disableToolbarButton: true } },
                }}
            />            
        </Paper>
    )
}